import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/bolowsroliin-erunhii-gazar.svg";
import svgPhone from "../../assets/images/icons/phone.svg";
import svgEmail from "../../assets/images/icons/email.svg";
import svgFB from "../../assets/images/icons/facebook.svg";
import svgAddress from "../../assets/images/icons/home.svg";

export default function Footer({navtype}) {
  const linkStyle='text-[#fff] no-underline mb-[12px] hover:underline hover:text-[#fff]'
  const color1 = navtype == 1 ? "bg-[#11256d]":"bg-primary2";
  const color2 = navtype == 1 ? "#0c1c55":"#1d1778";
  const submitIntro = ()=>{
    const element = document.getElementById('section-intro');
    if (element) {
    // 👇 Will scroll smoothly to the top of the next section
    element.scrollIntoView({ behavior: 'smooth' });
    }
  
}
  return (
    <footer className={`w-full bg-[#11256d] text-white text-[14px] ${color1}`}>
      <div className="container m-auto flex flex-col lg:flex lg:flex-row  py-12">
        <div className="w-[100%] lg:w-[50%]">
          <img className="h-[60px] 2xl:h-[80px]" src={logo} alt='company'/>
          <p className="mt-3">
            Сүхбаатар дүүрэг, Энх тайваны өргөн чөлөө, Арслантай Гүүр,
            <br />
            Ulaanbaatar, Mongolia, 14191
          </p>
        </div>
        <div className="w-[100%] lg:w-[20%]">
          <h5 className="font-bold mb-3">Холбоосууд</h5>
          <div className="flex flex-col">
            <Link to="#" className={linkStyle} onClick={submitIntro}>Танилцуулга</Link>
            <Link to="main/home" className={linkStyle}>Сургалтууд</Link>
            <Link to="/cooperation" className={linkStyle}>Хамтын ажиллагаа</Link>
            <Link to="/annoucement" className={linkStyle}>Зар мэдээ </Link>
          </div>
        </div>
        <div className="w-[100%] lg:w-[30%]">
          <h5 className="font-bold mb-3">Холбоо барих</h5>
          <div className="flex flex-col">
            <div className="mb-3 flex items-center">
              <img src={svgEmail} alt="svgEmail"/>
              <span className="ms-2">info@agency.edu.mn</span>
            </div>
            <div className="mb-3 flex items-center">
              <img src={svgPhone}  alt="svgPhone"/>
              <span className="ms-2">7014 0069</span>
            </div>
            <Link className="mb-3 flex items-center" to='https://www.facebook.com/education.agency.mongolia' target="_blank">
              <img src={svgFB}  alt="svgFB"/>
              <span className="ms-2">Facebook</span>
            </Link>
            <div className="mb-3 flex items-start">
              <img src={svgAddress}  alt="svgAddress"/>
              <div className="ms-2">
                <span>
                  Сүхбаатар дүүрэг, Энх тайваны өргөн чөлөө, Арслантай Гүүр,
                  Ulaanbaatar, Mongolia, 14191
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`w-full  py-2 bg-[${color2}]`}>
        © 2024 Боловсролын ерөнхий газар
      </div>
    </footer>
  );
}
