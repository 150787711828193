import React , { useEffect,  useState } from 'react'
import LessonCard from "../LessonCard/LessonCard";
import {
          Divider,
          FormControlLabel,
          Typography,
          Box,
          Grid
} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
const TabList = ({groups,callbackchild}) => {
          const [active,setActive] = useState(0);
          const [current,setCurrent] = useState(0);
          const tabListData =[
                    "Их үзэлттэй",
                    "Шинэ",
          ]
          const tabListStyle = { 
                    borderBottom: '1px solid #ddd',
                    marginBottom: '20px',
                    
                   }
          let tabItemStyle ={
                    fontSize: 20,
                    fontWeight: 'bold',        
                    padding : '15px 10px ',        
                    cursor:'pointer',
                    color: '#6a6f73'
          }       
          const prevSlide =()=>{
            //    setCurrent(current-1)       
            const scrolllesson = document.getElementById("top-lesson");
            scrolllesson.scrollLeft -=300;
             
             setTimeout(() => {
                  if(scrolllesson.scrollLeft == 0){
                    setCurrent(0)                
                }else{
                    setCurrent(2)      
                }
         }, 200);
          }
          const nextSlide =()=>{
            const scrolllesson = document.getElementById("top-lesson");
            scrolllesson.scrollLeft += 300;
             setTimeout(() => {
                  if(scrolllesson.offsetWidth+ scrolllesson.scrollLeft == scrolllesson.scrollWidth){
                    setCurrent(1)                
                }else{
                    setCurrent(2)      
                }
             }, 200);
          }
         
          
      
  return (
        <div>
          {groups && groups.length > 0 && (
             <div  className="py-4 mb-5">
               <Grid  container justifyContent="left"  sx={tabListStyle}>
                    {tabListData.map((name,index)=>(
                           <Grid item  sx={tabItemStyle} 
                             onClick={ ()=>{return setActive(index), callbackchild(index)}} key={index} 
                              className={`hover:text-[#1a3675] ${active == index && 'text-[#1a3675] border-b-2 border-[#1a3675]'}`}   
                            >
                              {name}
                            </Grid>
                    ))}
               </Grid>
               <div className='relative'>
                    <div id="top-lesson" className='w-full overflow-hidden flex gap-4 scroll-smooth'>
                            { groups.map((item,index)=>(
                                     <div className='flex'>
                                        <div className='block  w-[300px]'>
                                          <LessonCard key={index} data={item} banner={active==0?"TOP":"NEW"}/>
                                        </div>
                                      </div>
                                  ))
                            }
                    </div>
                    {(current == 1 || current == 2) &&<div className='absolute top-1/2 -translate-y-1/2 bg-[#1a3675] rounded-[50%] z-[10] cursor-pointer text-white flex justify-center items-center p-[6px] -left-[20px]' onClick={prevSlide}><ChevronLeftIcon/> </div>}
                    {(current == 0 || current == 2) && <div className='absolute top-1/2 -translate-y-1/2 bg-[#1a3675] rounded-[50%] z-[10] cursor-pointer text-white flex justify-center items-center p-[6px] -right-[20px]' onClick={nextSlide}><ChevronRightIcon/> </div>}
               </div>
             </div>
           )}
        </div>  
  )
}

export default TabList