import React from "react";
import lessonBg from "../../assets/images/lessonBg.png";
import { AiFillStar } from "react-icons/ai";
import clock from "../../assets/images/lesson-card/clock.png";
import lecture from "../../assets/images/lesson-card/lecture.png";
import shat from "../../assets/images/lesson-card/shat.png";
import profile from "../../assets/images/profile.png";
import { moneyFormat } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Eye } from "react-feather";
export default function LessonCard({ data, index, banner }) {
  let navigate = useNavigate();
  const bgColor = (color) => {
    switch (color) {
      case "NEW":
        return "#FF0000";
      case "TOP":
        return "#FFB800";
      case "NEWTOP":
        return "#BD00FF";
      default:
        return "red";
    }
  };
  console.log('datasss',data);
  return (
    <div
      className="w-full max-w-[310px] bg-[#fff] !shadow-xl rounded-[6px] max-h-[330px] mb-[16px] text-[12px] leading-[17px] text-[#000] hvr-buzz-out cursor-pointer"
      onClick={() => {
        navigate(`/main/courses/detail/${data.id}`, {
          state: {
            data: data,
          },
        });
      }}
    >
      {banner && (
        <div className={"w-auto absolute top-[20px] font-medium text-[14px] leading-[17px] text-[#fff] flex"}>
          <div className={`py-[4px] px-[16px] bg-[${bgColor(banner)}]`}>
            {banner === "NEW"
              ? "Шинэ"
              : banner === "TOP"
              ? "Хамгийн их үзсэн"
              : banner === "NEWTOP"
              ? "Хамгийн их үзэлттэй шинэ"
              : " "}
          </div>
          <div className="h-full">
            <div className={`w-[0px] h-[0px] border-t-[13px] border-solid border-[${bgColor(banner)}] border-r-[20px] border-r-transparent`}></div>
            <div className={`w-[0px] h-[0px] border-b-[13px] border-solid border-[${bgColor(banner)}] border-r-[20px] border-r-transparent`}></div>
          </div>
        </div>
      )}
      <div className="p-2">
        {/* <div
          style={{
            height: "105px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${data.img_url && data.img_url.length>0? data.img_url : lessonBg})`,
          }}
        ></div> */}
        <LazyLoadImage
          // effect="blur"
          className="h-[105px] object-cover"
          width="100%"
          src={data&&data.img_url && data.img_url.length>0? data.img_url : lessonBg} // use normal <img> attributes as props
          />
        <div className="px-2">
          <div className="text-left font-semibold h-[37px] text-[14px] overflow-y-hidden my-2">{data.name}</div>
          <div className="flex justify-between">
            <div className="flex ">
              <div className="flex justify-center items-center me-2">
                <Eye className="text-sm w-4 h-4 text-slate-500" />
              </div>
              <span className="text-textgray">( {data.view_cnt.toLocaleString()} )</span>
            </div>
            <div>
              <span>{data.total_std}</span>
              <span className="text-textgray ms-2">Сурагч</span>
            </div>
          </div>
          <div className="flex mt-4 justify-between">
            {/* <div className="flex justify-center items-center">
              <img src={clock} className="me-2" alt="clock"/>
              11ц 40мин
            </div> */}
            <div className="flex justify-center items-center">
              <img src={lecture} className="me-2" alt="lecture"/>
              {data.content_cnt} лекц
            </div>
            <div className="flex justify-center items-center">
              <img src={shat} className="me-2" alt="shat"/> анхан шат
            </div>
          </div>
          <div className="dropdown-divider my-4"></div>
          <div className="flex justify-between items-center w-100">
            <div className="flex justify-start items-center w-[70%]">
              <img src={profile} className="rounded-[50%] shadow-lg w-[32px] h-[32px]" alt="profile"/>
              <span className="ms-2 line-clamp-1">{data.teacher_descr ? data.teacher_descr : 'Н.Төгөлдөр'}</span>
            </div>
            <div className="font-bold text-[10px] leading-[19px]">
              {moneyFormat(data.price ? data.price : 0)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
