import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import "./App.scss";
import Home from "./pages/Home/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import { theme } from "./theme";
import Intro from "./pages/Intro/index";
import IndexSoftware from "./pages/software";
import CourseDetail from "./pages/CourseDetail/CourseDetail";
import CoursesSearch from "./pages/CoursesSearch";
import Main from "./pages/Main";
import NewsDetail from "./pages/NewsDetail/NewsDetail";
import ScrollToTop from "./ScrollToTop";
import CoursesLesson from "./pages/CoursesSearch/CoursesLesson/index";
import { ThemeProvider } from "@emotion/react";
import Annoucement from "./pages/Intro/Annoucement/Annoucement";
import Cooperation from "./pages/Intro/cooperation";
import Details from "./pages/Intro/Annoucement/Details";
import CertificatesDetail from "./pages/CertificatesDetail/CertificatesDetail";
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div>
        <ThemeProvider theme={theme}>
          <Router>
          <ScrollToTop/>
            <Routes>
              <Route path="/" element={<Intro />} />
              <Route path="/annoucement" element={<Annoucement />} />
              <Route path="/annoucement/detail/:id" element={<Details />} />
              <Route path="/cooperation" element={<Cooperation />} />
              {/* <Route path="/software" element={<IndexSoftware />} /> */}
              <Route path="/main" element={<Main />}>
                <Route path="home" element={<Home />} />
                <Route path="courses/detail/:id" element={<CourseDetail />} />
                <Route path="certificates/detail/:id" element={<CertificatesDetail />} />
                <Route path="courses/search" element={<CoursesSearch />}/>
                <Route path="courses/lessons/:id" element={<CoursesLesson />} />
                <Route path="news/:id" element={<NewsDetail />} />
              </Route>
            </Routes>
          </Router>
        </ThemeProvider>
        {/* <Home /> */}
        {/* <Intro /> */}
    </div>
  );
}

export default App;
