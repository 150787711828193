import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState , useRef } from "react";
import {  useParams } from "react-router-dom";
import profile from "../../assets/images/profile.png";
import api from "../../services/api";
import AllNews from "./AllNews/AllNews";
import { data } from "./tmp-data-news.js";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import staticImage from "../../assets/images/no-image.png";
import LatestNews from "../../components/LessNews/LatestNews";
import RelatedNews from "../../components/LessNews/RelatedNews";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
export default function NewsDetail() {
  const { id } = useParams();
  const [newsData, setNewsData] = useState();
  const [newsDetail, setNewsDetail] = useState(data);
  const [styleSlide, setStyleSlide] = useState({transform: 'translateX(0)'});
  const [slideCnt, setSlideCnt] = useState({ count: 0 });
  const ref = useRef(null);
  useEffect(() => {
    // console.log('width', ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);
  useEffect(() => {
    if (!newsData) {
      api.post("ttimeline/news/scopeguest", { scopeid: 3 }).then((res) => {
       
        setNewsData(res.data.message);
      });
    }
    api
      .get("/ttimeline/getguest/"+ id)
      .then((res) => {
        setNewsDetail(res.data.message);
      })
      .catch((error) => {
        // console.log(error.data);
      });
  }, []);
  const [open, setOpen] = React.useState(false);
  const closeFunc = () => {
    setOpen(false);
  };
  const openFunc = () => {
    setOpen(true);
  };
  const rightSlide = ()=>{
      // console.log('rr');
        setSlideCnt({ count: slideCnt.count + 300 })
        setStyleSlide({transform: `translateX(-${slideCnt.count}px)`})
  }
  const imageTypes = ["jpg","jpeg","png","GIF "];
  return (
    <>
    <div className="bg-[#f9fafb]">
        <div className="container py-10">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-y-10 md:gap-10">
                <div className="col-span-12 md:col-span-8 flex flex-col space-y-8">
                    <div className="rounded-[8px] shadow-lg bg-[#fff] overflow-hidden">
                        <div className="h-44 mb-2 md:h-72 overflow-hidden relative rounded-t-lg w-full">
                          <img src={newsDetail&&newsDetail.t_album  && newsDetail.t_album.t_media && newsDetail.t_album.t_media.length>0 
                   ? newsDetail.t_album.t_media[0].mediaurl :( newsDetail.mediaurl &&imageTypes.indexOf( newsDetail.mediaurl.split('.').pop()) > -1 ? newsDetail.mediaurl : staticImage)} alt="" className="w-full h-full absolute inset-0 object-cover"/>
                        </div>
                        <div className="px-7 py-4">
                              <h1 className="text-xl font-semibold mb-6 text-justify"> {JSON.parse(newsDetail.news_txt).title}</h1>
                              <div className="flex items-center space-x-3 my-4 pb-4 border-b border-gray-100">
                                <img src={profile} alt="" className="w-10 rounded-full" />
                                <div>
                                    <div className="text-base font-semibold">  {newsDetail.insertname}</div>
                                    <div className="text-xs">Нийтэлсэн { moment(newsDetail.inserttime).format("YYYY-MM-DD")}</div>
                                </div>
                              </div>
                              <div className="text-justify text-[15px]">
                                  <p>  {JSON.parse(newsDetail.news_txt).text}</p>
                              </div>
                        </div>
                    </div>
                    <div className="rounded-[8px] shadow-lg bg-[#fff] p-6">
                          <div className="block text-lg font-semibold">Ойролцоо мэдээ</div>    
                          <div className="px-1 py-3 relative">
                              <div className="overflow-x-auto no-scrollbar">
                                  <div className="flex flex-nowrap"    style={styleSlide}>
                                      {newsData&&newsData.length> 0 && newsData.slice(0,6).map((el, key) => ( 
                                        <RelatedNews key={key} data={el} ref={ref}/>
                                      ))}
                                    </div>
                              </div>     
                               {/* <div className="absolute left-[-20px] top-[30%] w-[45px] h-[45px] bg-white rounded-full shadow-xl flex justify-center items-center cursor-pointer hover:bg-gray-200"><ChevronLeftIcon/></div>
                                <div className="absolute right-[-20px] top-[30%] w-[45px] h-[45px] bg-white rounded-full shadow-xl flex justify-center items-center cursor-pointer hover:bg-gray-200 " onClick={rightSlide}><ChevronRightIcon/></div> */}
                          </div>
                    </div>
                    <div className="rounded-[8px] shadow-lg bg-[#fff] overflow-hidden p-6">
                          <div className="block text-lg font-semibold mb-4">Сэтгэгдэл (12)</div>      
                          <div className="space-y-5">
                            <div className="flex gap-x-4 relative ">
                                <img src={profile} alt="" className="rounded-full h-12 w-12" /> 
                                <a href="#" className="absolute right-4 top-0 rounded-full bg-gray-100 px-4 py-1.5 text-xs hover:!text-[#0056b3]">Хариу бичих</a>
                                <div>
                                    <h1 className="text-md font-medium">teacherss</h1>
                                    <div className="text-xs"> {moment("2023-11-10").format("MM-DD-YYYY")}</div>
                                    <p className="text-xs mt-2 text-justify">Ерөнхий боловсролын сургуулийн сурагчдын сурлагын хоцрогдлыг нөхөх, арилгах цогц төлөвлөгөөний “Нотолгоонд суурилсан сайн туршлага хуваалцаж, хамтран хөгжих” гэсэн зорилтыг хэрэгжүүлэхэд энэхүү туршилт чухал алхам болох ба зорилтот бүлгийн сурагчдад нэмэлт дугуйланг хичээллүүлэн хэрэгжүүлнэ.</p>
                                </div>
                            </div>
                          </div>
                          <div className="mt-8 flex justify-center">
                              <a href="#" className="bg-gray-50 border hover:bg-gray-100 px-4 py-1.5 rounded-full text-xs text-gray-600">Илүү сэтгэгдэл</a>
                          </div>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                   <div className="w-full   !text-[#333]">
                            <h2 className="text-lg font-semibold mb-3">
                               Сүүлд нийтэлсэн
                            </h2>
                            <ul className="grid grid-cols-2 md:grid-cols-1 gap-8 md:gap-0">
                              {newsData&&newsData.length> 0 && newsData.map((el, key) => (
                                <LatestNews data={el} key={key}/>
                                ))}
                            </ul>
                   </div>
                </div>
            </div>
        </div>
    </div>
    </>
    // <Box py={5} sx={{ backgroundColor: "#fdfdfd" }}>
    //   <div className="container">
    //     <Grid container spacing={3}>
    //       <Grid item lg={12} xs={12}>
    //         <Typography
    //           variant="h4"
    //           // color="text.secondary"
    //           sx={{ textAlign: "left", mb: 3 }}
    //         >
    //           {JSON.parse(newsDetail.news_txt).title}
    //         </Typography>
    //         <Grid container sx={{ display: "flex", mb: 4 }}>
    //           <Grid item lg={4} xs={12} sx={{ display: "flex" }}>
    //             <img src={profile} alt="profile" className="course-detail-profile" />
    //             <Box
    //               sx={{
    //                 textAlign: "left",
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 ml: 2,
    //               }}
    //             >
    //               <Typography
    //                 variant="caption"
    //                 sx={{ fontWeight: 500 }}
    //                 color="text.secondary"
    //               >
    //                 Нийтлэгч
    //               </Typography>
    //               <Typography
    //                 sx={{ fontWeight: 700, display: "flex" }}
    //                 variant="body"
    //               >
    //                 {newsDetail.insertname}
    //                 <Typography sx={{ ml: 1 }} color="text.secondary">
    //                   {moment(
    //                     moment(newsDetail.inserttime).format("YYYY-MM-DD-hh:mm:ss"),
    //                     "YYYY-MM-DD-hh:mm:ss"
    //                   ).fromNow()}
    //                 </Typography>
    //               </Typography>
    //             </Box>
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       <Grid item lg={8} xs={12}>
    //         <Box>
    //           <Box
    //             sx={{
    //               width: "100%",
    //               display: "flex",
    //               justifyContent: "flex-end",
    //               mb: 1,
    //             }}
    //           >
    //             <Typography
    //               sx={{ display: "flex", textAlign: "center" }}
    //               color="text.secondary"
    //             >
    //               үзсэн 1,500 <RemoveRedEyeIcon />
    //             </Typography>
    //           </Box>
    //           {
    //            <img
    //             width={"100%"}
    //             alt="mediaurl"
    //             // height={"400px"}
    //             src={newsDetail&&newsDetail.t_album  && newsDetail.t_album.t_media && newsDetail.t_album.t_media.length>0 
    //               ? newsDetail.t_album.t_media[0].mediaurl :( newsDetail.mediaurl &&imageTypes.indexOf( newsDetail.mediaurl.split('.').pop()) > -1 ? newsDetail.mediaurl : staticImage)}
    //           />
    //           }
    //         </Box>
    //         <Box mt={3}>
    //           <Typography variant="body">
    //             {JSON.parse(newsDetail.news_txt).text}
               
    //           </Typography>
    //         </Box>
    //         {/* <Box mt={3} display="flex" justifyContent={"flex-end"}>
    //           <Button
    //             variant="contained"
    //             color="info"
    //             size="small"
    //             startIcon={<ThumbUpIcon />}
    //           >
    //             Таалагдсан 700
    //           </Button>
    //           <Button
    //             size="small"
    //             color="info"
    //             variant="contained"
    //             sx={{ ml: 2 }}
    //             startIcon={<ShareIcon />}
    //           >
    //             Хуваалцах
    //           </Button>
    //         </Box> */}
    //       </Grid>
    //       <Grid item lg={4} xs={12}>
    //         <Typography variant="h6" sx={{ mb: 1 }}>
    //           Ойролцоо мэдээ
    //         </Typography>
    //         <Box
    //           sx={{
    //             background: "white",
    //             height: "560px",
    //             // position: "fixed",
    //             boxShadow:
    //               "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px ",
    //           }}
    //         >
    //           <div
    //             className="col-12 col-md-5 pt-4 pt-md-0 less-news-con"
    //             style={{ height: "100%", width: "100%" }}
    //           >
    //             {newsData&&newsData.length> 0 && newsData.map((el, key) => (
    //               <AllNews data={el} key={key} />
    //             ))}
    //           </div>
    //         </Box>
    //       </Grid>
    //     </Grid>
    //   </div>
    // </Box>
  );
}
