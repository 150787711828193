import React, { useEffect, useState } from "react";
import SVG from 'react-inlinesvg';
import {
  Button,
  Box,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from 'material-react-toastify';
import { styled } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import ReplayIcon from '@mui/icons-material/Replay';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import api from "../../../services/api";
import { Close } from "@mui/icons-material";
import CryptoJS from 'crypto-js';
import imgLogin from "../../../assets/images/img_login.jpg";
import imgLogin2 from "../../../assets/images/i7.png";
import logoMn from "../../../assets/images/mn-logo.svg";
import 'material-react-toastify/dist/ReactToastify.css';
import '../../../utils/animate_main.scss';
import ForgetPassword from "../../../components/ForgetPassword/ForgetPassword";
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    //   margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${imgLogin})`,
  width: "400px",
}));

export default function Login({ handleClose, open ,toLink , lessontype }) {
  const today =  new Date().getTime();

  const [showPassword, setShowPassword] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [animateClass ,setAnimateClass] = useState(false);
  const [isLoading ,setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    createAt: today ,
    email: "",
    password: "",
    accessTokenEsis:"36f3fc6ab2f2821c6bf9beabaea442d6",
    text: ""
    }
    );
  const [isForgetPassword, setIsForgetPassword] = useState(false);
    
  useEffect(() => {
    refreshCaptcha();
  }, []);


const refreshCaptcha = () => {
  api.get(`/captcha/create?date=`+today).then((res) => {
    if(res.status===200){
      setCaptcha(res.data);
      setUserData({...userData, createAt: today });
    }});
};
const closeForgetPassword = () =>{
     setIsForgetPassword(false) ;  
     setAnimateClass(true);

}
const openForgetPassword = () =>{
  setIsForgetPassword(true) ;  
}
const userLogin =(userData)=>{

  //  console.log(userData,"userData");
   setIsLoading(true);
  api.post( "/user/esislogin", userData, {
            withCredentials: true,
          })
          .then((resLogin) => {
            resLogin&& resLogin.data&&  resLogin.data.message && resLogin.data.message.message && resLogin.data.message.message =='Email is required' &&   toast.error('Мэйл хаягаа оруулна уу');
            resLogin&& resLogin.data&&  resLogin.data.message && resLogin.data.message =='Password is required' &&   toast.error('Нууц үгээ оруулна уу');
            
            const data = resLogin.data;
            setIsLoading(false);
          // console.log("resLogin",resLogin)  
            if (data.success) {
              localStorage.setItem('init_url',JSON.stringify({
                type: lessontype,
                link: toLink
              }));
              let bytes = CryptoJS.AES.encrypt(
                JSON.stringify(data),
                'b25982f6b1cae5ef77b957d3dca7da15',
              );
              localStorage.setItem(
                'dQuVu3EN&cdhgWsd',
                bytes,
              );
            
              if(data.role === 0 ){
                 window.location.href = process.env.REACT_APP_HOME_URL+"std";
              }else if(data.role === 1 ){
                 window.location.href = process.env.REACT_APP_HOME_URL+"teacher";
              }else{
                window.location.href = process.env.REACT_APP_HOME_URL+"admin";
             }
              // console.log(bytes,"bytes")

              // let hash =localStorage.getItem("dQuVu3EN&cdhgWsd");
              // let decode = CryptoJS.AES.decrypt(
              //   hash,
              //   'b25982f6b1cae5ef77b957d3dca7da15',
              // );
              // let RESULT = JSON.parse(decode.toString(CryptoJS.enc.Utf8));
              // console.log(RESULT,"RESULT");

              // console.log(process.env.REACT_APP_HOME_URL,"process.env.REACT_APP_HOME_URL");
              
             
            } else {
              // localStorage.clear();
              // return (window.location.href = process.env.REACT_APP_HOME_URL);
            }
          })
          .catch(function (error) {
            setIsLoading(false);
            error&& error.response&&  error.response.data && error.response.data.error &&   toast.error('Нийлбэрийг бодож хариуг зөв оруулна уу');
            error&& error.response&&  error.response.data && error.response.data.message && toast.error(error.response.data.message);
            error&& error.response&&  error.response.data && error.response.data.message && error.response.data.message.message && toast.error(error.response.data.message.message);
          });
}
const handleChange = e => {
  setUserData({...userData, [e.currentTarget.id]: e.currentTarget.value });
};
  // const handleRole = (e, val) => setUserData({...userData, role:val});
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 
  const closeButtonAction = ()=>{
    handleClose();
    setIsForgetPassword(false);
    setAnimateClass(false);
  }

  const animateClassName = animateClass ? 'left-to-right-animate' : '';
  return (
    <Dialog maxWidth="lg" open={open} >
       <ToastContainer position="top-center" />
        {/* <form onSubmit={handleSubmit}> */}
      <Box sx={{ display: "flex" }}>
        <Box sx={{ p: { xs: 2, sm: 4 }, pb: 4, maxWidth: "400px" }}>
       
          <IconButton
            sx={{
              position: "absolute",
              right: 16,
              top: 8,
              color: { xs: (theme) => theme.palette.grey[500], md: "#fff" },
              zIndex:3
            }}
            onClick={closeButtonAction}
          >
            <Close />
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "center",mb: { xs: 2, sm: 3 } }}>
            <img width={60} height={60} src={logoMn} alt="logoMn"/>
            <p sx={{ fontSize: "14px", fontWeight: "bold", ml: 1 }} className="font-bold ml-3">
                БАГШИЙН ХӨГЖИЛ
                </p>
          </Box>
          { !isForgetPassword ?
               <Grid container spacing={2}  className={animateClassName}>
                <Grid item xs={12}>
                  <div className="text-[14px]">
                    ESIS эрхээр нэвтрэх 
                  </div>
                  {/* <StyledToggleButtonGroup
                    size="small"
                    value={userData.role}
                    exclusive
                    onChange={handleRole}
                    aria-label="text alignment"
                    color="primary"
                    fullWidth
                  >
                    <ToggleButton value="student">ESIS эрхээр нэвтрэх</ToggleButton>
                    <ToggleButton value="teacher">Сургагч багш</ToggleButton>
                  </StyledToggleButtonGroup> */}
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Нэвтрэх нэр" fullWidth 
                  id="email"
                  onChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Нууц үг
                    </InputLabel>
                    <OutlinedInput
                      label="Нууц үг"
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange} 
                      id="password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <SVG src={captcha} />
                </Grid>
                <Grid item xs={6}>
                <IconButton
                  aria-label="refresh captcha"
                  onClick={refreshCaptcha}
                  edge="end"
                          >
                  <ReplayIcon />
                        
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Нийлбэрийг оруулна уу" fullWidth  id="text" onChange={handleChange}  />
                </Grid>
                <Grid item xs={12}>
                  <div className="text-right cursor-pointer	font-semibold" onClick={openForgetPassword}>
                    Нууц үг сэргээх
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" size="large"    
                   disabled={isLoading}
                  onClick={() => {
                    userLogin(userData);
                      }} 
                      fullWidth sx={{ mt: 2 }}>
                    { isLoading ? <div className="animate-spin w-[30px] h-[30px] border-b-transparent border-4 border-[#0060dc] border-solid rounded-full bg-transparent"></div> : <div>Нэвтрэх</div> }
                  </Button>
                </Grid>
               </Grid> :
               <ForgetPassword closeForgetPass={closeForgetPassword} captcha={captcha} refreshCaptcha ={refreshCaptcha} createdAt={userData.createAt}>
               </ForgetPassword>
          }
          
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "inline-block" },
            backgroundImage: `url(${imgLogin2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "451px",
            height: "612px",
            zIndex:2
          }}
        ></Box>
      </Box>
      {/* </form> */}
    </Dialog>
  );
}
