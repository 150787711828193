import * as React from "react";
import Box from "@mui/material/Box";
export default function CourseInfoAbout({ data }) {
  const styles = { py: 2, fontWeight: "700",textAlign:"left",color:"#1a3675"}
  const stylesDescr = {color: "black",textAlign:"justify"}
  return (
    // <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
    <div>
      <Box sx={styles}>Агуулга</Box>
      <Box sx={stylesDescr}> {data.aguulga}</Box>
      <Box sx={styles}>Зорилго</Box>
      <Box sx={stylesDescr}> {data.zorilgo}</Box>
      <Box sx={styles}>Хамрах хүрээ</Box>
      <Box sx={stylesDescr}> {data.results}</Box>
      <Box sx={styles}>Заах багш нар</Box>
      <Box sx={stylesDescr}> {data.teacher_descr}</Box>
    </div>
  );
}
