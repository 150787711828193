import React from "react";
// import styles from "./style.module.scss";

import logo from "../../assets/images/mn-logo.svg";
import { MdClear } from "react-icons/md";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
export default function SideBar({ isSideBar, close ,signupModalShow}) {
  const listStyle = "w-[150px] text-left h-[40px] flex items-center relative text-[#ddd] no-underline uppercase text-textgrey  text-[14px] "
  return (
    <>
      {isSideBar && <div className="fixed bg-[#0000009b] inset-0" onClick={close}></div>}
      <div className="w-[280px] fixed overflow-y-auto z-[1000] top-0 left-0 bottom-0 bg-[#fff] px-[50px] py-[16px] text-left flex flex-col justify-between"
        style={{marginLeft: isSideBar ? "0" : "-280px",transition: "all ease-in-out 0.4s",}}>
        <div>
          <div className="flex justify-end mr-[-16px]" onClick={close}>
            <MdClear size={24} className="text-textgrey" />
          </div>
          <div className="text-[24px] text-[#15387c] font-bold pb-[16px]">
            <div className="flex text-[12px]">
              <img width={60} height={60} src={logo} alt="logo"/>
              <div className="ml-[12px]"></div>
              <span className="text-primary flex flex-col justify-end">
                Боловсролын ерөнхий газар
              </span>
            </div>
            <div className="dropdown-divider"></div>
          </div>
          <div className="flex flex-col justify-center">
            <Link className={listStyle} onClick={close} to="/">
              Эхлэл
            </Link>
            <Link className={listStyle} onClick={close} to="/main/courses/search">
              ХИЧЭЭЛҮҮД
            </Link>
            {/* <div className={listStyle} onClick={close}>
              ХӨТӨЛБӨРҮҮД
            </div> */}
            {/* <div className={listStyle} onClick={close}>
              ҮНИЙН САНАЛ
            </div> */}
            {/* <div className={listStyle} onClick={close}>
              БАГШ
            </div> */}
          </div>
        </div>
        <Button  onClick={() => { signupModalShow(); close();}}  variant="contained">Бүртгүүлэх</Button>
      </div>
    </>
  );
}
