import React, { useEffect, useState } from "react";
import LessNews from "../../components/LessNews/LessNews";
import NewsCard from "../../components/NewsCard/NewsCard";
import api from "../../services/api";
import { GoBook } from "react-icons/go";
import { IoPeopleOutline } from "react-icons/io5";
import { MdOutlineEmojiPeople } from "react-icons/md";
import CountUp from "react-countup";
import Instructions from "./Instructions/Instructions";
import Lessons from "./Lessons/Lessons";
import LessonCategory from "./LessonCategory/LessonCategory";
import CommonQuestion from "./CommonQuestions/CommonQuestions";
import bgStat from "../../assets/images/stat-bg.png"
export default function Home() {
  const [newsData, setNewsData] = useState([]);
  const [isZoriltot, setIsZoriltot] = useState(false);
  const [newLessonData, setNewLessonData] = useState([]);
  const [topLessonData, setTopLessonData] = useState([]);
  const [mainCertificatesData, setMainCertificatesData] = useState([]);
  const [zoriltotCategoryData, setZoriltotCategoryData] = useState([]);
  const [songoltotCategoryData, setSongoltotCategoryData] = useState([]);
  const [statData, setStatData] = useState({
                                        "lesson_cnt": 100,
                                        "total_teachers": 1000,
                                        "active_teachers": 500
                                  });
  useEffect(() => {
    if (newsData.length === 0) {
      api.post("ttimeline/news/scopeguest", { scopeid: 3 }).then((res) => {
        setNewsData(res.data.message);
        // console.log('newsData',newsData);
      });
    }
    api.get("lesson/newlessonslast").then((res) => {
      setNewLessonData(res.data.message);
    });
    api.get("lesson/toplessons").then((res) => {
      setTopLessonData(res.data.message);
    });
    let requestData = {ifunc:'homepage_statistic', ip:"",  params:{"id":1}}
    api.post("home/certificates",requestData).then((res) => {
      // console.log('homepage_statistic',res.data);
        if(res.data.issuccess == 1 ){
          setStatData(res.data.messages);
        }
    });
    
    /* api.post("tree/filtertype", { typeid: 1 }).then((res) => {
      let filterData = [];
      let filterDataLength3 = [];
      if(res.data.message && res.data.message.length>0){
        for(let i =0;i < res.data.message.length;i++){
          if(res.data.message[i].topid === '610'){
            filterData.push(res.data.message[i]);
          }
      }
      
      }
      if(filterData&&filterData.length>0){
        filterDataLength3 = filterData.slice(0, 3);
      }
      // console.log('filterDataLength3',filterDataLength3);
      // console.log('filterData',filterData);
      setSongoltotCategoryData(filterDataLength3);
     
    }); */
    certificateGetApi('mainCertificates');
    certificateGetApi('zoriltotCertificates');
    certificateGetApi('songoltotCertificates');
  }, []);
  const certificateGetApi = (type) => {
      api.post("home/certificates",{
        ifunc:type,
        ip:"",
        params:{
            "id":1
        }
    }).then((res) => {
        if(type === 'mainCertificates'&&res.data.messages&&res.data.messages.length>0){
          setMainCertificatesData(res.data.messages);
        }else if(type === 'zoriltotCertificates'&&res.data.messages&&res.data.messages.length>0)
        {
          setIsZoriltot(true);
          setZoriltotCategoryData(res.data.messages);  
        }else if(type === 'songoltotCertificates'&&res.data.messages&&res.data.messages.length>0)
        {
          setSongoltotCategoryData(res.data.messages);  
        }
    });
  };
  let mainCertStyle = isZoriltot ? "section-gray" : "";
  const sectionTitle = "font-semibold text-[32px] leading-9 text-[#15387c] pt-[32px] text-center sm:text-left ";
  const section = "pt-[32px] pb-[50px] !min-w-full"
  return (
    <div className="mt-8">
     
      <div>
        <div className="container mx-auto">
          {newsData.length > 0 && (
            <div className="flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0">
              <div
                className="h-[540px] w-[100%] lg:w-[61%]"
                // data-aos="fade-right"
                // data-aos-duration="500"
                >
                <NewsCard data={newsData[1]} />
              </div>
              <div
                className="h-[540px] w-[100%] lg:w-[35%]   overflow-y-scroll"
                // data-aos="fade-left"
                // data-aos-duration="500"
                >
                {newsData.map((el, key) => (
                  <LessNews data={el} key={key} datafirst={newsData[1]}/>
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          className="my-8 relative h-[350px] sm:h-[250px] w-full bg-no-repeat bg-cover"
          style={{backgroundImage:`url(${bgStat})`}}
          data-aos-duration="50">
          <div className="bg-[#00000066] text-[#fff] font-bold absolute h-full w-full top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] p-4">
            <div className="container mx-auto  h-full">
              <div className="flex flex-col sm:flex-row items-center h-full">
                <div className="w-full">
                  <GoBook size={38} />
                  <div className="font-bold text-[30px] leading-8">
                    <CountUp start={0} separator="," end={statData.lesson_cnt} duration={3} />
                  </div>
                  <div className="font-medium text-[20px] leading-6">Цахим хичээлийн тоо</div>
                </div>
                <div className="w-full">
                  <IoPeopleOutline size={38} />
                  <div className="font-bold text-[30px] leading-8">
                    <CountUp start={0} separator="," end={statData.total_teachers} duration={3} />
                  </div>
                  <div className="font-medium text-[20px] leading-6">Суралцаж буй багш нар</div>
                </div>
                <div className="w-full">
                  <MdOutlineEmojiPeople size={38} />
                  <div className="font-bold text-[30px] leading-8">
                    <CountUp start={0} separator="," end={statData.active_teachers} duration={3} />
                  </div>
                  <div className="font-medium text-[20px] leading-6">Идэвхтэй хэрэглэгч багш нар</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
         <div className={mainCertStyle}>
            <div className=" container mx-auto">
              {/* surgalt */}
              <div className={sectionTitle} >Үндсэн сургалтууд</div>
              <div className={section}>
                <Instructions courseList={mainCertificatesData}/>
              </div>
             </div>
          </div>
      {/* {zoriltotCategoryData&&zoriltotCategoryData.length>0 &&
          <div className=" container mx-auto">
          <div className={sectionTitle} >Зорилтот сургалтууд</div>
          <div className={section}>
            <Instructions courseList={zoriltotCategoryData}/>
          </div>
         </div>
        } */}
        <div className="bg-bggray">
          <div className=" container m-auto">
            <div className={sectionTitle}>Шинэ нэмэгдсэн хичээлүүд</div>
            <div className={section}>
              <Lessons lessonData={newLessonData} banner="NEW" />
            </div>
          </div>
        </div>
        <div className="container m-auto">
          <div className={sectionTitle}>Хамгийн их үзэлттэй хичээлүүд</div>
          <div className={section}>
            <Lessons lessonData={topLessonData} banner="TOP" />
          </div>
        </div>
        {/* <div className="section-gray">
          <div className="container px-2">
            <div className={sectionTitle}>
              Хамгийн их үзэлттэй шинэ хичээлүүд
            </div>
            <div className="section row">
              <Lessons lessonData={topLessonData} banner="NEWTOP" />
            </div>
          </div>
        </div> */}
        <div className="container m-auto">
          <div className={sectionTitle}> Хичээлийн ангилал </div>
          <div className={section + ' mt-4'}>
            <LessonCategory categoryData={songoltotCategoryData}/>
          </div>
        </div>
        <div className="bg-bggray">
          <div className="container m-auto">
            <div className={sectionTitle +' px-3'}> Түгээмэл асуулт хариултууд </div>
            <div className={section}>
              <CommonQuestion />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
