import React, { useState ,useEffect} from 'react'
import HeaderIntro from "../../../layout/HeaderIntro/Header";
import {BsCheckCircle} from "react-icons/bs";
import onlineteaching from '../../../assets/images/slide02.jpg';
import Footer from '../../../layout/Footer/Footer';
import appstore from '../../../assets/images/appstore.png';
import { Link } from 'react-router-dom';
import {BiTachometer,BiLogoApple,BiLogoPlayStore} from "react-icons/bi";
import {FaArrowRight} from "react-icons/fa";

import imgzar1 from '../../../assets/images/zar2077.jpeg';
import imgzar2 from '../../../assets/images/zar2109.jpeg';

const accordionData = [ {
          id:1,
          title:
            "Багш платформд хэрхэн нэвтрэх вэ ?",
          text: "Багш нар esis.edu.mn платформд нэвтэрдэг багшийн код ба нууц үгээ ашиглан “Багш платформ”-д нэвтрэнэ",
        },
        {
          id:2,
          title: "Багш платформын хичээлүүд төлбөртэй юу?",
          text: "Хичээлүүд төлбөртэй бөгөөд Qpay, SocialPay болон Багш картаар тооцоо хийн худалдан авах боломжтой",
        },
        {
          id:3,
          title: "Сургалтанд хамрагдаад сертификат авах уу?",
          text: "Багш нар хичээлийг амжилттай үзэж судлан, шаардлагатай түвшинг давсан тохиолдолд Батламж авах эрхтэй болно",
        },
        {
          id:4,
          title: "Багш платформоор үзэж судалсан хичээлүүд ESIS-д бүртгэгдэх үү?",
          text: "Багш нар хичээлийг амжилттай үзэж судлан батламж авсан бол Боловсролын салбарын мэдээллийн систем буюу ESIS-д автомат бүртгэгдэнэ",
        },
        {
          id:5,
          title: "Сургалтын байгууллагууд хамтарч ажиллах боломжтой юу?",
          text: "Сургалтын байгууллагууд Багш платформд хичээлээ байршуулан хамтран ажиллах боломжтой",
        },
        {
          id:6,
          title: "Бусад LMS платформ дээр боловсруулсан контентоо ашиглаж болох уу?",
          text: "Багш платформ нь SCORM болон LTI стандартаар бусад LMS платформоос контент хүлээн авах боломжтой",
        },
      ]
const blogData = [
        {
            id:1,
            image:imgzar2,
            title:'Уулзалт зохион байгууллаа',
            desc:'Багшийн мэргэжлийн хөгжлийг дэмжих платформд суурилсан Сургалтын модуль боловсруулах багийн төлөөлөлтэй уулзалт зохион байгууллаа. Уулзалтанд онлайнаар болон танхимд нийт 566 баг оролцов.',
            category:'Advisory',
            date:'2023 оны 9 сарын 22',
            name:"Боловсролын Ерөнхий Газар",
        },
        {
            id:2,
            image:imgzar1,
            title:'203 баг бүртгүүлжээ...',
            desc:'БАГШИЙН МЭРГЭЖЛИЙН ХӨГЖЛИЙГ ДЭМЖИХ ПЛАТФОРМД СУУРИЛСАН СУРГАЛТЫН МОДУЛЬ БОЛОВСРУУЛАХ 203 БАГ БҮРТГҮҮЛСЭН БАЙНА...',
            category:'Урилга',
            date:'2023 оны 9 сарын 19',
            name:"Боловсролын Ерөнхий Газар",
        },
        {
            id:3,
            image:imgzar1,
            title:'СУРГАЛТЫН МОДУЛЬ БОЛОВСРУУЛАХ багийг урьж байна...',
            desc:'БАГШИЙН МЭРГЭЖЛИЙН ХӨГЖЛИЙГ ДЭМЖИХ ПЛАТФОРМД СУУРИЛСАН СУРГАЛТЫН МОДУЛЬ БОЛОВСРУУЛАХ БАГИЙГ УРЬЖ БАЙНА...',
            category:'Урилга',
            date:'2023 оны 9 сарын 13',
            name:"Боловсролын Ерөнхий Газар",
        }
    ]      
export default function Annoucement(){
          const [activeIndex, setActiveIndex] = useState(1);
          const toggleAccordion = (index) => {
                    if (activeIndex === index) {
                        setActiveIndex(0);
                    } else {
                        setActiveIndex(index);
                    }
                }
       return (
          <>
           <HeaderIntro/>
           <div className='mx-auto'>
           <section id="section-intro" className="relative md:py-24 py-16 overflow-hidden">
                <div className="container relative">
                     <div  className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                        <div className="lg:col-span-5">
                        <img src={onlineteaching} alt="" />
                        </div>
                        <div className="lg:col-span-7">
                        <div className="lg:ms-10">
                            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Сонгон шалгаруулах зар</h3>
                            <p className="text-slate-400 max-w-xl">Боловсролын ерөнхий газраас СӨБ, ЕБС-ийн багш, ажилтны мэргэжлийн хөгжлийг дэмжих цахим платформд суурилсан сургалтын модуль сонгон шалгаруулна. </p>

                            <ul className="list-none text-slate-400 mt-4 ">
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-primary2 text-base me-2" /> Модуль сургалтын удирдамж, шаардлага, сэдэв</li>
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-primary2 text-base me-2" /> Модуль сургалтын сэдэв сонгон, бүртгүүлэх </li>
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-primary2 text-base me-2" /> Сонгосон сэдвээр модуль сургалтын агуулгаа боловруулж платформд байршуулах</li>
                            </ul>

                            {/* <div className="mt-4">
                            <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-primary2 hover:text-indigo-900 after:bg-primary2 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                            </div> */}
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <div className="container relative mb-16">
                    {/* <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
                        <div className="lg:col-span-8 md:col-span-6 md:text-start text-center">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Зар мэдээ</h3>
                            <p className="text-slate-400 max-w-xl">Сонгон шалгаруулалтын нийт зар мэдээ мэдээллийн дэлгэрэнгүйг үзэх жагсаалт </p>
                        </div>
                    </div> */}

                    <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="grid grid-cols-1 gap-[30px]">
                                {blogData.map((item, index)=>{
                                    return(
                                    <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden" key={index}>
                                        <div className="lg:flex relative">
                                            <div className="relative md:shrink-0">
                                                <img className="h-full w-full object-cover lg:w-52 lg:h-56" src={item.image} alt="" />
                                            </div>
                                            <div className="p-6 flex flex-col lg:h-56 justify-center">
                                                <Link to={`/annoucement/detail/${item.id}`} className="title h5 text-lg font-medium hover:text-blue-700 duration-500 ease-in-out line-clamp-2">{item.title}</Link>
                                                <div className="my-auto">
                                                    <p className="text-slate-400 mt-3 line-clamp-2">{item.desc}</p>
                                                </div>
    
                                                <div className="mt-4">
                                                    <Link to={`/annoucement/detail/${item.id}`} className="relative inline-flex items-center font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-blue-700 after:bg-primary2 duration-500">Дэлгэрэнгүй <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="lg:col-span-4 md:col-span-6">
                            <div className="sticky top-28">
                                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">Сүүлд оруулсан</h5>
                                {blogData.slice(0,4).map((item,index)=>{
                                    return(
                                    <div className="flex items-center mt-8" key={index}>
                                        <img src={item.image} className="h-16 w-24 rounded-md shadow dark:shadow-gray-800" alt="" />

                                        <div className="ms-3">
                                            <Link className="font-semibold hover:text-blue-700 line-clamp-1">{item.title}</Link>
                                            <p className="text-sm text-slate-400">{item.date}</p>
                                        </div>
                                    </div>
                                    )
                                })}

                                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Ангилал</h5>
                                <ul className="list-none text-center mt-8 space-x-1">
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-primary2 dark:hover:bg-primary2 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Урилга</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-primary2 dark:hover:bg-primary2 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Арга хэмжээ</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-primary2 dark:hover:bg-primary2 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Сонгон шалгаруулалт</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-primary2 dark:hover:bg-primary2 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Мэдээ</Link></li>{/* 
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-primary2 dark:hover:bg-primary2 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Bride</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-primary2 dark:hover:bg-primary2 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Lifestyle</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-primary2 dark:hover:bg-primary2 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Travel</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-primary2 dark:hover:bg-primary2 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Beauty</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-primary2 dark:hover:bg-primary2 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Video</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-primary2 dark:hover:bg-primary2 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Audio</Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>    
                    <section className="container">
                              <div className="grid grid-cols-1 pb-8 text-center">
                              <h3 className="mt-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Түгээмэл асуултууд</h3>
                              </div>

                              <div id="accordion-collapse" data-accordion="collapse" className="grid md:grid-cols-2 grid-cols-1 md:gap-[30px]">
                              <div>
                              {accordionData.slice(0,3).map((item, index) => (
                                        <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                                  <button type="button" onClick={() => toggleAccordion(item.id)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === item.id ? 'bg-gray-50 dark:bg-slate-800 text-blue-900' : ''}`} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                  <span>{item.title}</span>
                                                  <svg data-accordion-icon className={`${activeIndex === item.id ? "rotate-180" : "rotate-270" } w-4 h-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                  </svg>
                                                  </button>
                                        </h2>
                                        {activeIndex === item.id && (
                                                  <div>
                                                  <div className="p-5">
                                                  <p className="text-slate-400 dark:text-gray-400">{item.text}</p>
                                                  </div>
                                                  </div>
                                        )}
                                        </div>

                              ))}
                              </div>

                              <div>
                              {accordionData.slice(3,6).map((item, index) => (
                                        <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                                  <button type="button" onClick={() => toggleAccordion(item.id)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === item.id ? 'bg-gray-50 dark:bg-slate-800 text-blue-900' : ''}`} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                  <span>{item.title}</span>
                                                  <svg data-accordion-icon className={`${activeIndex === item.id ? "rotate-180" : "rotate-270" } w-4 h-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                  </svg>
                                                  </button>
                                        </h2>
                                        {activeIndex === item.id && (
                                                  <div>
                                                  <div className="p-5">
                                                  <p className="text-slate-400 dark:text-gray-400">{item.text}</p>
                                                  </div>
                                                  </div>
                                        )}
                                        </div>

                              ))}
                              </div>

                              </div>
                    </section>
                    <section className="container relative md:my-24 my-16">
                              <div className="grid md:grid-cols-12 grid-cols-1 items-center">
                                        <div className="lg:col-span-5 md:col-span-6 p-8 rounded-md">
                                          <img src={appstore} className="mx-auto d-block" alt="" />
                                        </div>

                                        <div className="lg:col-span-7 md:col-span-6">
                                                  <span className="bg-primary2/5 text-primary2 text-xs font-bold px-2.5 py-0.5 rounded h-5">Мобайл апп</span>
                                                  <h4 className="md:text-3xl text-2xl lg:leading-normal leading-normal font-medium my-4">АПП ТАТАХ</h4>
                                                  <p className="text-slate-400 max-w-xl mb-0">Та мобайл апп татаж ашигласнаар хичээлүүдээ гар утсандаа татан авч, интернэт сүлжээгүй оффлайн горимд ч суралцах боломжтой</p>
                                                  <div className="my-5 w-full flex items-center justify-start space-x-2">
                                                            <Link  className="flex justify-center items-center space-x-1 bg-primary2 rounded-md h-[44px] w-[145px] hover:bg-indigo-900 text-white shadow-lg transition-all duration-300" to="https://apps.apple.com/us/app/%D0%B1%D0%B0%D0%B3%D1%88-%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC/id1623960946"><BiLogoApple/><span className="text-xs font-semibold">App Store</span></Link>

                                                            <Link className="flex justify-center items-center space-x-1 border border-primary2 rounded-md h-[43px] w-[145px] hover:bg-indigo-900 text-primary2 hover:text-white shadow-lg transition-all duration-300" to="https://play.google.com/store/apps/details?id=mn.starsoft.itpdapp"><BiLogoPlayStore/><span className="text-xs font-semibold">Google Play</span></Link>
                                                  </div>
                                        </div>
                              </div>
                    </section>     
           </div>
           <Footer navtype={2}/>
         </>
      )
}