import React, { useState } from "react";
import moment from "moment";
import bg from "../../assets/images/course_image.png";
import profile from "../../assets/images/profile.png";
import studentIcon from "../../assets/images/studentIcon.png";
import lessonCnt from "../../assets/images/icons8-one-page-22.png";
import hourglassIcon from "../../assets/images/timer.png";
import {BsArrowRight} from "react-icons/bs"
import InstructionSubGridCard from "./instructionSubGridCard";
import { useNavigate } from "react-router-dom";
export default function InstructionCard({course }) {
  var cardList =  [
    {
      "icon" : studentIcon , 
      "cnt" : course.total_std_count ,  
      "name" : "Бүртгүүлсэн"
    },
    {
      "icon" : lessonCnt , 
      "cnt" : course.planlessonscnt,  
      "name" : "Хичээлийн тоо"
    },
    {
      "icon" : hourglassIcon , 
      "cnt" :  moment(course.start_dat).format("YYYY-MM-DD") ,  
      "name" : "Эхлэх огноо"
    },
    {
      "icon" : hourglassIcon , 
      "cnt" : course.days ,  
      "name" : "Үргэлжлэх өдөр"
    }
  ]
  
  const navigate = useNavigate();
  const routeFunc = () => {
    navigate(`/main/certificates/detail/`+course.id);
  };
  return (
    <div className="w-full max-w-[330px]  text-[16px] cursor-pointer shadow-lg rounded-md" onClick={() => routeFunc()}>
      <img src={bg} className="rounded-t-md w-full aspect-[2/1]" alt="bg" />
      <div className="py-2 px-3">
          <div className="font-semibold  leading-[19px] text-left mt-1 line-clamp-1">{course.cert_name}</div>
          <div className="font-normal  leading-[15px] text-left text-slate-400 text-[13px] mt-1 line-clamp-1">{course.short_info}</div>
          <div className="grid grid-cols-2 text-slate-700 font-medium py-2 justify-items-center content-center gap-x-1">
            {
             cardList.map((data,index)=>
                (
                  <InstructionSubGridCard paramsData = {data} key= {index}/>
                )
              )
            }
         </div>
      </div>
    </div>
  );
}
