import React from 'react'
import slidepic from '../../assets/images/slide02.jpg';
import imageP from '../../assets/images/imageP.png';
import imgzar1 from '../../assets/images/zar2077.jpeg';
import imgzar2 from '../../assets/images/zar2109.jpeg';
import * as Icon from 'react-feather'
import { Link } from 'react-router-dom';
export default function SideBar(props) {
    const RecentPostData = [
        {
            Image: imgzar2,
            Class: 'flex items-center mt-8',
            Title: 'Уулзалт зохион байгууллаа',
            Date: '2023 оны 9 сарын 22'
        },
        {
            Image: imgzar1,
            Class: 'flex items-center mt-4',
            Title: '203 баг бүртгүүлжээ...',
            Date: '2023 оны 9 сарын 19'
        },
        {
            Image: imgzar1,
            Class: 'flex items-center mt-8',
            Title: 'СУРГАЛТЫН МОДУЛЬ БОЛОВСРУУЛАХ багийг урьж байна...',
            Date: '2023 оны 9 сарын 13'
        }
    ];
    const TagscloudItem = [
        {
            item: 'Урилга'
        },
        {
            item: 'Арга хэмжээ'
        },
        {
            item: 'Сонгон шалгаруулалт'
        },
        {
            item: 'Мэдээ'
        }
    ];
    return (
        <div className={props.className}>
            <div className="sticky top-20">
                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">Мэдээг бичсэн</h5>
                <div className="text-center mt-8">
                    <img src={props.client ? props.client : imageP} className="h-24 w-24 mx-auto rounded-full shadow mb-4" alt="" />

                    <Link className="text-lg font-semibold hover:text-blue-800 transition-all duration-500 ease-in-out">{props.name ? props.name : 'Cristina Romsey'}</Link>
                    <p className="text-slate-400">Боловсролын Ерөнхий Газар</p>
                </div>

                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Шинэ мэдээ</h5>


                {RecentPostData.map((data, index) => {
                    return(
                        <div className={data.Class} key={index}>
                            <img src={data.Image} className="h-16 rounded-md shadow dark:shadow-gray-800" alt="" />
    
                            <div className="ms-3">
                                <Link className="font-semibold hover:text-blue-800 line-clamp-2">{data.Title}</Link>
                                <p className="text-sm text-slate-400">{data.Date}</p>
                            </div>
                        </div>
                    )
                })}



                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Мэдээг хуваалцах</h5>
                <ul className="list-none text-center mt-8 space-x-1">
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-blue-800 hover:text-white hover:bg-blue-800"><Icon.Facebook className="h-4 w-4"></Icon.Facebook></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-blue-800 hover:text-white hover:bg-blue-800"><Icon.Instagram className="h-4 w-4"></Icon.Instagram></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-blue-800 hover:text-white hover:bg-blue-800"><Icon.Twitter className="h-4 w-4"></Icon.Twitter></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-blue-800 hover:text-white hover:bg-blue-800"><Icon.Linkedin className="h-4 w-4"></Icon.Linkedin></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-blue-800 hover:text-white hover:bg-blue-800"><Icon.GitHub className="h-4 w-4"></Icon.GitHub></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-blue-800 hover:text-white hover:bg-blue-800"><Icon.Youtube className="h-4 w-4"></Icon.Youtube></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-blue-800 hover:text-white hover:bg-blue-800"><Icon.Gitlab className="h-4 w-4"></Icon.Gitlab></Link></li>
                </ul>

                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Ангилал</h5>
                <ul className="list-none text-center mt-8">
                    {TagscloudItem.map((data,index) => {
                        return(
                            <li className="inline-block m-2" key={index}><Link to="#" className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-blue-800 dark:hover:bg-blue-800 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">{data.item}</Link></li>
                        )
                    }
                        
                    )}
                </ul>
            </div>
        </div>
    )
}
