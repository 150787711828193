import React, { useEffect, useState } from "react";
// import styles from "./style.module.scss";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
export default function CommonQuestion({ data }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <motion.div
      className={"hvr-forward flex flex-col transition-all w-full min-h-[75px] bg-[#fff] border border-[##E6E6E6] border-solid box-border rounded-lg font-medium text-[20px] leading-6 text-[#555E70] text-left items-center justify-center p-[8px] mb-[16px]  cursor-pointer"}
      onClick={() => setExpanded(!expanded)}
    >
      <motion.header
        initial={false}
        animate={{ backgroundColor: expanded ? "#FF0088" : "#0055FF" }}
      />
      <motion.div className="w-full h-full flex items-center">
        <motion.div className="h-full flex justify-center items-center mr-[8px]">
          <motion.div className="rounded-[50%] hover:transition-all hover:bg-[#555e7044]">
            {expanded ? <IoMdRemove size={24} /> : <IoMdAdd size={24} />}
          </motion.div>
        </motion.div>
        <div>{data.title}</div>
      </motion.div>
      <motion.header />
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="text-[14px] leading-5 py-[16px] px-[32px]">{data.text}</div>
          </motion.section>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
