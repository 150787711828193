import React from 'react'

export default function InstructionSubGridCard({paramsData}) {
  return (
       <div className="flex flex-col items-start text-[12px]  w-[120px] h-[64px]" >
          <div className="flex justify-start items-center">
              <img src={paramsData.icon} alt="studentIcon"/>
            <div className='text-[12px] ml-[10px] text-slate-500'>
                {paramsData.cnt}  
            </div>
          </div>
          <div className='text-[14px]'> 
                    {paramsData.name}
           </div>
      </div>
  )
}
