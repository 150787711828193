import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import staticImage from "../../assets/images/no-image.png";
import { useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
export default function LessNews({ data ,datafirst }) {
  let navigate = useNavigate();
  function handleClick() {
    navigate(`../news/${data.id}`, { replace: false });
  }
  const imageTypes = ["jpg","jpeg","png","GIF "];
  return (
    <>
      {data.id != datafirst.id &&  <div className="hvr-shrink cursor-pointer px-1 w-full" onClick={handleClick}>
      <div className="flex py-1 justify-center">
        <div className="flex justify-center items-center w-4/12">
        <LazyLoadImage
          className="w-[120px] h-[100px] rounded object-cover border border-slate-100"
            // effect="blur"
            src={data.t_album  && data.t_album.t_media && data.t_album.t_media.length>0 
              ? data.t_album.t_media[0].mediaurl : data.mediaurl &&imageTypes.indexOf( data.mediaurl.split('.').pop()) > -1 ? data.mediaurl : staticImage}
            />
        </div>
          {/* <img
            style={{ minWidth: 180, maxWidth: 180, height: 110 }}
            src={data.t_album  && data.t_album.t_media && data.t_album.t_media.length>0 
                  ? data.t_album.t_media[0].mediaurl : data.mediaurl ? data.mediaurl : staticImage}
            alt="mediaurl"
          /> */}
          <div className="flex justify-start flex-col pl-2 w-8/12">
            <span className="text-left not-italic font-medium text-[18px] leading-6 text-slate-400 line-clamp-1">
              {moment(
                moment(data.inserttime).format("YYYY-MM-DD-hh:mm:ss"),
                "YYYY-MM-DD-hh:mm:ss"
              ).fromNow()}
            </span>
            <div className="text-left not-italic font-semibold text-[14px] leading-4 text-[#15387c] line-clamp-4">
              {JSON.parse(data.news_txt).title}
            </div>
          </div>
      </div>
    </div> }
    </>
   
  );
}
