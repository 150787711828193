import React from "react";
import Биеийн_тамир from "../../../assets/LessonCategoryImages/Биеийн тамир.png";
import Биологи from "../../../assets/LessonCategoryImages/Биологи.png";
import Газар_зүй from "../../../assets/LessonCategoryImages/Газар зүй.png";
import Дуу_хөгжим from "../../../assets/LessonCategoryImages/Дуу хөгжим.png";
import Дүрслэх_урлаг from "../../../assets/LessonCategoryImages/Дүрслэх урлаг.png";
import Математик from "../../../assets/LessonCategoryImages/Математик.png";
import Монгол_хэл from "../../../assets/LessonCategoryImages/Монгол хэл.png";
import Мэдээлэл_зүй from "../../../assets/LessonCategoryImages/Мэдээлэл зүй.png";
import Түүх from "../../../assets/LessonCategoryImages/Түүх.png";
import Уран_зохиол from "../../../assets/LessonCategoryImages/Уран зохиол.png";
import Физик from "../../../assets/LessonCategoryImages/Физик.png";
import Эрүүл_мэнд from "../../../assets/LessonCategoryImages/Эрүүл мэнд.png";
import Хувь_хүний_хөгжил from "../../../assets/LessonCategoryImages/Хувь хүний хөгжил.png";
import training from "../../../assets/LessonCategoryImages/training.png";
import law from "../../../assets/LessonCategoryImages/law.png";
import languages from "../../../assets/LessonCategoryImages/languages.png";
import mandala from "../../../assets/LessonCategoryImages/mandala.png";
import social_science from "../../../assets/LessonCategoryImages/social-science.png";
import LessonCategoryCard from "../../../components/LessonCategoryCard/LessonCategoryCard";
import * as Icon from 'react-feather';
export default function LessonCategory({categoryData}) {

  const keyfeatureData = [
    {
        icon :Icon.Monitor,
        title:'Онлайн сургалт'
    },
    {
        icon :Icon.User,
        title:'Оффлайн сургалт'
    },
    {
        icon :Icon.Loader,
        title:'Холимог сургалт'
    },
    {
        icon :Icon.Users,
        title:'LMS платформ'
    },
    {
        icon :Icon.Box,
        title:'Meeting платформ'
    },
    {
        icon :Icon.Cloud,
        title:'Сошиал платформ'
    },
    {
        icon :Icon.Globe,
        title:'Үүлэн платформ'
    },
    {
        icon :Icon.BookOpen,
        title:'Микросервис архитектур'
    },
    {
        icon :Icon.Aperture,
        title:'Kubernetes'
    },
    {
        icon :Icon.Film,
        title:'SCORM контент'
    },
    {
        icon :Icon.Play,
        title:'H5P контент'
    },
    {
        icon :Icon.PlayCircle,
        title:'LTI контент'
    },
    {
        icon :Icon.Tool,
        title:'Слайд бүтээгч багаж'
    },
    {
        icon :Icon.BookOpen,
        title:'Цахим хичээл бүтээгч'
    },
    {
        icon :Icon.Smartphone,
        title:'Мобайл апп'
    }
]
  const data = [
    {
      name: "Мэдээлэл зүй",
      lessonNum: "17",
      image: Мэдээлэл_зүй,
    },
    {
      name: "Хувь_хүний_хөгжил",
      lessonNum: "17",
      image: Хувь_хүний_хөгжил,
    },
    {
      name: "Суралцахуй ба Багшлахуй",
      lessonNum: "17",
      image: training,
    },
    {
      name: "Хууль, эрх зүй",
      lessonNum: "17",
      image: law,
    },
    {
      name: "Математик",
      lessonNum: "17",
      image: Математик,
    },
    {
      name: "Байгалийн ухаан",
      lessonNum: "17",
      image: Биологи,
    },
    {
      name: "Гадаад хэл",
      lessonNum: "17",
      image: languages,
    },
   
    {
      name: "Нийгмийн ухаан",
      lessonNum: "17",
      image: social_science,
    },
    {
      name: "Урлаг, гоо зүй",
      lessonNum: "17",
      image: mandala,
    },
    {
      name: "Уран зохиол",
      lessonNum: "17",
      image: Уран_зохиол,
    },
    {
      name: "Физик",
      lessonNum: "17",
      image: Физик,
    },
    {
      name: "Эрүүл мэнд",
      lessonNum: "17",
      image: Эрүүл_мэнд,
    },
    {
      name: "Биеийн тамир",
      lessonNum: "17",
      image: Биеийн_тамир,
    },
    {
      name: "Газар зүй",
      lessonNum: "17",
      image: Газар_зүй,
    },
  ];
  let rows =[];
 
    for (let i = 0; i < categoryData.length; i++) {
        rows.push({...categoryData[i],image:keyfeatureData[i].icon});
   
  }
 
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  mt-4">
      {rows.map((el, key) => (
        <div className="px-2" key={key} >
          <LessonCategoryCard data={el} />
        </div>
      ))}
    </div>
  );
}
