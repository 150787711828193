import React from 'react'
import { Radar } from 'react-chartjs-2';
import {
        Chart as ChartJS,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        registerables
      } from 'chart.js';
      ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement,Title,Tooltip,Legend,...registerables);
export default function RadarChart({chart_data,type}) {

        let labels = ['Thing 1', 'Thing 2', 'Thing 3', 'Thing 4', 'Thing 5'];
        let data1 = [1,2,3,4,5];
        if (chart_data){
                //        console.log('chart_data',chart_data);
                       labels  = [];
                        data1 = [];
                       chart_data.forEach(element => {
                            labels.push(element.type_name);
                            data1.push(element.cnt_view);
                       });
                //        console.log('labels',labels);          
                  }          
 let data = {
          labels: labels,
          datasets: [
                    {
                    label: type == 1 ? 'Суралцаж буй багш нар (Агуулга үзэлтээр топ 5)' : 'Суралцаж буй багш нар (Судлагдахуунаар топ 5)',
                    data: data1,
                    backgroundColor:  type == 1 ? 'rgba(22, 86 ,146, 0.2)' : 'rgba(189 ,105, 120, 0.2)',
                    borderColor:  type == 1 ? 'rgb(22, 86 ,146)' : 'rgb(189 ,105, 120)',
                    borderWidth: 1,
                    },
          ],};
              
  return (
          <div>
                <Radar data={data} />
          </div>
  )
}
