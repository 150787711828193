import React from 'react'
import Report1 from '../../components/Intro/report/Report1'
import HeaderIntro from '../../layout/HeaderIntro/Header'

export default function Report() {
  return (
    <>
      <HeaderIntro/>
      <section className="relative md:py-24 py-16 overflow-hidden">
        <div className='container relative py-8'>
            <Report1/>
        </div>
      </section>
    </>
  )
}
