import React from "react";
import staticImage from "../../assets/images/no-image.png";
import moment from "moment";
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { useNavigate } from "react-router-dom";
export default function RelatedNews({data}) {
          const imageTypes = ["jpg","jpeg","png","GIF "];
          // const [newsId, setNewsId] = useState([]);
          let navigate = useNavigate();
          function handleClick() {

                    // setNewsId(data.id);
                    // console.log("bna "+data.id);
                    navigate(`../news/${data.id}`, { replace: true });
                    window.location.reload();
                   
                  }
return (
         
          <div className="flex flex-col pl-2" onClick={handleClick}>
                    <div className="md:w-[200px] w-[160px] md:h-[160px] h-[120px]  relative block cursor-pointer">
                        <img src={data.t_album && data.t_album.t_media && data.t_album.t_media.length>0? data.t_album.t_media[0].mediaurl : data.mediaurl &&imageTypes.indexOf( data.mediaurl.split('.').pop()) > -1? data.mediaurl : staticImage} alt="" className="w-full h-full absolute inset-0 object-cover rounded-lg shadow-lg" />
                    </div>
                    <div className="font-medium line-clamp-2 text-xs !text-[#333] mt-4">
                                {JSON.parse(data.news_txt).title}
                    </div>
                    <div className="flex items-center my-auto text-xs space-x-1.5 mt-2">
                              <div> {moment(data.inserttime).format("MM-DD-YYYY")}</div> 
                              <div className="pb-1"> . </div> 
                              <TextsmsOutlinedIcon className="!text-[15px]"></TextsmsOutlinedIcon> 
                              <div> {Math.round(Math.random()*10)}</div>
                 </div> 
        </div>
)
}