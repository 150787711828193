import React, { useEffect, useState } from "react";
import InstructionCard from "../../../components/InstructionCard/InstructionCard";
import LessonCard from "../../../components/LessonCard/LessonCard";

export default function Lessons({ lessonData, banner }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  mt-4 justify-items-center">
      {lessonData &&
        lessonData.length > 0 ?
        lessonData.slice(0,4).map((el, index) => (
          <div className="px-2 max-w-[332px]" key={index}>
            <LessonCard data={el} index={index + 1} banner={banner} />
          </div>
        )): <div> Үр дүн олдсонгүй</div>
      }
    </div>
  );
}
