import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function Main() {
  return (
    <div>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer navtype={1}/>
    </div>
  );
}

export default Main;
