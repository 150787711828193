import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import staticImage from "../../../assets/images/no-image.png";
export default function AllNews({ data }) {

  const [newsId, setNewsId] = useState([]);
  let navigate = useNavigate();
  function handleClick() {

    setNewsId(data.id);
    // console.log("bna "+data.id);
    navigate(`../news/${data.id}`, { replace: true });
    window.location.reload();
  }
  const imageTypes = ["jpg","jpeg","png","GIF "];
  // data-aos="fade-left" data-aos-duration="1000"
  return (
    <div  onClick={handleClick}>
      <div className="d-flex py-1 hvr-shrink">
        <img
          style={{ minWidth: 110,maxWidth:110, height: 80 }}
          alt="mediaurl"
         src={data.t_album && data.t_album.t_media && data.t_album.t_media.length>0? 
              data.t_album.t_media[0].mediaurl : data.mediaurl &&imageTypes.indexOf( data.mediaurl.split('.').pop()) > -1? data.mediaurl : staticImage}
        />
        <div className="d-flex justify-content-start flex-column px-2">
          <Typography color="text.secondary">
            {moment(
              moment(data.inserttime).format("YYYY-MM-DD-hh:mm:ss"),
              "YYYY-MM-DD-hh:mm:ss"
            ).fromNow()}
          </Typography>
          <div className="less-news-title" style={{ fontSize: "12px",width:"100%" }}>
            {JSON.parse(data.news_txt).title}
          </div>
        </div>
      </div>
      <div className="dropdown-divider"></div>
    </div>
  );
}
