import moment from "moment";
import React, { useRef } from "react";
import { MdAccessTimeFilled } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
export default function NewsCard({ data }) {
  let navigate = useNavigate();
  function handleClick() {
    navigate(`../news/${data.id}`, { replace: false });
  }
  const sliderImage = useRef();
  const settings = {
    dots: false,
    infinite: true,
    pauseOnHover: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="w-full h-full rounded" onClick={handleClick}>
      <div className={`flex flex-col justify-between h-full bg-no-repeat bg-cover cursor-pointer rounded bg-[url(${data.t_album ? data.t_album.t_media[0].mediaurl : data.mediaurl})]`} >
        <div className="pt-[28px] flex justify-end ">
          <div className="font-medium text-sm flex items-center bg-gray-100 text-blue-900 px-3 py-0.5 rounded mr-2">
            <MdAccessTimeFilled size={20} className="me-1" />
            {moment(moment(data.inserttime).format("YYYY-MM-DD-hh:mm:ss"),"YYYY-MM-DD-hh:mm:ss").fromNow()}
          </div>
        </div>
        <div className="p-[28px] text-left text-[#fff] bg-[#1e1e1e94] rounded">
          <div className="w-fit min-w-[150px] rounded-md px-2 py-1 text-center shadow-sm text-sm mb-3 bg-secondary">
            {"Боловсрол".toLocaleUpperCase()}
          </div>
          <div className="font-semibold not-italic text-[28px] leading-6 line-clamp-3"> 
            {JSON.parse(data.news_txt).title}
          </div>
          {/* <div className="not-italic font-normal text-[20px] leading-6 ">
            {_.truncate(JSON.parse(data.news_txt).text, {length: 50,omission: "...",})}
          </div> */}
        </div>
      </div>
    </div>
  );
}
