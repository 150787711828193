import React from "react";
import {useNavigate} from "react-router-dom"
export default function LessonCategoryCard({ data }) {
  const navigate = useNavigate();
  let Icons = data.image
  return (
    <div className="hvr-pulse-shrink cursor-pointer max-w-[320px] min-w-full h-[120px] bg-[#fff] !shadow-xl rounded-[6px] mb-[60px] flex flex-col py-[4px] px-[16px]"
    onClick={() => {
      navigate(`/main/courses/lessons/`+data.id);
    }}
    >
      <div className="flex justify-start mt-[-35px]">
        {/* <img src={data.image} alt="lesson"/> */}
        {/* <Icons className="h-5 w-5 rotate-45"/> */}
        <div className="flex items-center justify-center h-[55px] min-w-[55px] 
              -rotate-45 bg-gradient-to-r from-transparent to-blue-900/20 text-[#113087] text-center rounded-lg me-3">
           <Icons className="h-6 w-6 rotate-45"/>
         </div>
      </div>
      <div className="flex justify-start mt-2 " title={data.name}><p className="line-clamp-1">{data.name}</p></div>
      <div className="flex justify-start text-[#95a2ba]">
        {data.lesson_cnt} хичээл
      </div>
    </div>
  );
}
