import React, { useState ,useRef,useEffect} from 'react';
import "./style.scss"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
function DropdownMenu({data}) {
  const [sortValue, setSortValue] = useState('Шинээр нэмэгдсэн');
  const [activeToggle, setActiveToggle] = useState(false);
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
               setActiveToggle(false);
          }
        };
      
        useEffect(() => {
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
        }, []);
  return (
    <div className="css-dropdown" onClick={()=>setActiveToggle(!activeToggle)} ref={dropdownRef}>
                      <div className="content-title">
                         <div className="content-title-left">
                              {sortValue}
                         </div>
                         <div className="content-title-right">
                             <KeyboardArrowDownIcon/>
                         </div>
                      </div>
                    {activeToggle &&  <div className='css-dropdown-content'>
                         {data.map((value)=>(
                              <div className={`css-dropdown-content-item ${sortValue === value.name ? " is-active-dropdown-item":"" }`} onClick={()=> setSortValue(value.name)}  key={value.id}>{value.name}</div>
                            )
                        )}
                     </div>  }
      </div>
  );
}
export default DropdownMenu;