import React from 'react'
import LineChart from './LineChart';
import BarChart from './BarChart';
import DoughnutChart from './DoughnutChart';
import RadarChart from './RadarChart';
export default function Chart({chart_data}) {
  return (
          <div className="flex justify-center w-full h-full grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-12">
                    <BarChart chart_data={chart_data.chart_1}/>
                    <LineChart chart_data={chart_data.chart_2}/>
                    <RadarChart chart_data={chart_data.chart_3} type={1}/>
                    {/* <RadarChart chart_data={chart_data.chart_4} type={2}/> */}
                    <div className='text-center text-[13px]'>
                      Суралцаж буй багш нар (Байршлаар топ 5)
                      <DoughnutChart chart_data={chart_data.chart_4}/>
                    </div>
                    
         </div>
  )
}
