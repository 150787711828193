'use client'
import React, { useEffect, useState } from 'react'
import api from '../../../services/api';

export default function Report1() {
     const [reportData , setReportData] = useState([]);
     useEffect(()=>{
          let reqData2 = {ifunc:'report_1', ip:"",  params:{"id":1}}
               api.post("home/certificates",reqData2).then((res) => {
               //   console.log('homepage_chart',res.data);
                    if(res.data.issuccess == 1 ){
                         setReportData(res.data.messages);
                    }
               });
     },[])
  return (
     <>
          <div className='py-3 font-bold text-xl w-1/2 text-center mx-auto '>
             Багшийн мэргэжлийн тасралтгүй хөгжлийн bagsh.edu.mn-ээр сургалтад хамрагдаж буй багш нарын мэдээлэл
          </div>
          <div className="overflow-x-auto mt-4">
               <table className="w-full border border-gray-300">
               <thead>
               <tr className="bg-gray-100">
                    <th className="border px-4 py-2">№</th>
                    <th colSpan={2} rowSpan={2} className="border px-4 py-2">Ангилал</th>
                    <th colSpan="3" className="border px-4 py-2">ЕБС-ийн багш нарын тоо</th>
                    <th colSpan="3" className="border px-4 py-2">СӨБ-ийн багш нарын тоо</th>
                    <th colSpan="2" className="border px-4 py-2">Үндэсний цөөнх</th>
               </tr>
               <tr className="bg-gray-50">
                    <th className="border px-4 py-2"></th>
                    <th className="border px-4 py-2">Нийт тоо</th>
                    <th className="border px-4 py-2">Эр</th>
                    <th className="border px-4 py-2">Эм</th>
                    <th className="border px-4 py-2">Нийт тоо</th>
                    <th className="border px-4 py-2">Эр</th>
                    <th className="border px-4 py-2">Эм</th>
                    <th className="border px-4 py-2">Казах</th>
                    <th className="border px-4 py-2">Тува (Цаатан)</th>
               </tr>
               </thead>
               <tbody>
               {
                    reportData && reportData.length > 0 ? reportData.map((data,i)=>(

                         <tr key={i}>
                             
                              {
                              i == 0 &&  <td rowSpan={reportData.length} className="border px-4 py-2 text-center">{i+1}</td>
                              }
                              {
                              i == 0 && <td rowSpan={reportData.length} className="border px-4 py-2 text-center font-bold">{data.name}</td>
                              }
                              <td className="border px-4 py-2 text-left font-bold">{data.initcap}</td>
                              <td className="border px-4 py-2 text-center">{data.cnt}</td>
                              <td className="border px-4 py-2 text-center">{data.cnt2}</td>
                              <td className="border px-4 py-2 text-center">{data.cnt3}</td>
                              <td className="border px-4 py-2 text-center">{data.cnt4}</td>
                              <td className="border px-4 py-2 text-center">{data.cnt5}</td>
                              <td className="border px-4 py-2 text-center">{data.cnt6}</td>
                              <td className="border px-4 py-2 text-center">{data.cnt7}</td>
                              <td className="border px-4 py-2 text-center">{data.cnt8}</td>
                         </tr>
                    )) :<></>
               }

               {/* <tr>
                    <td className="border px-4 py-2">2</td>
                    <td className="border px-4 py-2 font-bold">Сургалтын нэр</td>
                    <td colSpan="8" className="border px-4 py-2 font-bold">Улсын хэмжээнд</td>
               </tr>
               <tr>
                    <td></td>
                    <td className="border px-4 py-2">Аймгуудын нэр</td>
                    <td colSpan="8" className="border px-4 py-2"></td>
               </tr>
               <tr>
                    <td></td>
                    <td className="border px-4 py-2">УБ, Дүүргүүдийн нэр</td>
                    <td colSpan="8" className="border px-4 py-2"></td>
               </tr>

               <tr className="bg-gray-200 font-bold">
                    <td colSpan="2" className="border px-4 py-2 text-center">Нийт</td>
                    <td colSpan="8" className="border px-4 py-2"></td>
               </tr> */}
               </tbody>
               </table>
          </div>
     </>
  )
}
