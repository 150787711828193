// src/components/Map.js
import React, { useEffect, useRef ,useState} from 'react';
import * as d3 from 'd3';
import { useMediaQuery } from "react-responsive";
import {ReactComponent as MySVG} from '../assets/images/mn-02.svg';
function Map({map_data}) {
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);
  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });
useEffect(()=>{  
  // Define your data (replace with your actual data)
  let  countryData1 = [
    { id:'country1',name:'Дорнод',value:0},
    { id:'country2',name:'Баян-өлгий',value:9},
    { id:'country3',name:'Ховд',value:0},
    { id:'country4',name:'Сүхбаатар',value:20},
    { id:'country5',name:'Дорноговь',value:0},
    { id:'country6',name:'Говь-Алтай',value:0},
    { id:'country7',name:'Баянхонгор	',value:6},
    { id:'country8',name:'Өмнөговь',value:15},
    { id:'country9',name:'Хөвсгөл',value:16},
    { id:'country10',name:'Булган',value:0},
    { id:'country11',name:'Увс',value:0},
    { id:'country12',name:'Сэлэнгэ',value:0},
    { id:'country13',name:'Завхан',value:0},
    { id:'country14',name:'Хэнтий',value:0},
    { id:'country15',name:'Дархан-Уул',value:0},
    { id:'country16',name:'Төв',value:4},
    { id:'country17',name:'Архангай',value:0},
    { id:'country18',name:'Орхон',value:0},
    { id:'country19',name:'Дундговь',value:0},
    { id:'country20',name:'Өвөрхангай',value:0},
    { id:'country21',name:'Говьсүмбэр',value:0},
    { id:'country22',name:'Улаанбаатар',value:29}
  ];
// console.log('map_data',map_data);
  if(map_data && map_data.chart_5){
    countryData1 = map_data.chart_5;
    // console.log('countryData1',countryData1);
  
    var countryData = {};
    function kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    }
    for (var i = 0; i < countryData1.length; i++) {
      countryData[countryData1[i].id] = countryData1[i].value;
    }
    const colorScale = d3.scaleSequential(d3.interpolateBlues)
    .domain([0, d3.max(Object.values(countryData))]).range(["#e5e6f1", "#262d89" , "#0000FF"]);
    
      const svg = d3.select(svgRef.current);
      const tooltip = d3.select(tooltipRef.current);

      tooltip
      .style('position', 'absolute')
      .style('background-color', 'white')
      .style('border', '1px solid #ddd')
      .style('padding-left', '10px')
      .style('padding-right', '10px')
      .style('padding-top', '5px')
      .style('padding-bottom', '5px')
      .style('opacity', 0)
      .style('border-radius', '5px');
      // Function to render numerical data in front of each country
      const renderCountryData = () => {
        svg.selectAll('.country-label')
          .data(countryData1)
          .enter()
          .append('text')
          .attr('class', 'country-label fill-[#000]')
          .attr('x', (d) => getXCoordinateForCountry(d))
          .attr('y', (d) => getYCoordinateForCountry(d))
          .text((d) => getTextConfig(d))
          .style('font-size', '12px') // Example: Set font size to 14 pixels
          .style('font-weight', 'bold').on('mouseover', (event, d) => {
            // console.log('event',event);
            let [x, y] = d3.pointer(event);
            if(isMobile){
                if(d.id == 'country1'||d.id == 'country4'||d.id == 'country14'||d.id == 'country21'||d.id == 'country5'||d.id == 'country22'||d.id == 'country15'){
                      x-=200;
                }
            }
            tooltip
              .style('left', `${x-100}px`)
              .style('top', `${y-100}px`)
              .style('opacity', 1)
              .html(`<strong>${d.name} : ${d.value} </strong>`);
          })
          .on('mouseout', () => {
            tooltip.style('opacity', 0);
          }); 
      
      };

      const getTextConfig = (d) => {
            return kFormatter( d.value)
      };

      // Function to calculate X coordinate based on the country's path ID
      const getXCoordinateForCountry = (country) => {
        const countryPath = svg.select(`#${country.id}`);
        if (!countryPath.empty()) {
          const bbox = countryPath.node().getBBox();
          if(country.id == 'country16')return bbox.x + bbox.width/4;
          // You can adjust the X coordinate as needed, e.g., center it horizontally
          return bbox.x + bbox.width/2.5;
        }
        return 0;
      };

      // Function to calculate Y coordinate based on the country's path ID
      const getYCoordinateForCountry = (country) => {
        const countryPath = svg.select(`#${country.id}`);
        if (!countryPath.empty()) {
          const bbox = countryPath.node().getBBox();
          if(country.id == 'country21')return bbox.y ;
          if(country.id == 'country18')return bbox.y ;
          if(country.id == 'country12')return bbox.y + 15 ;
          if(country.id == 'country15')return bbox.y + 18 ;
          if(country.id == 'country22')return bbox.y + 15;
          // You can adjust the Y coordinate as needed, e.g., position it above the country
          return bbox.y + 50; // Adjust the value for vertical offset
        }
        return 0;
      };

      // Render numerical data
      renderCountryData();

      svg.selectAll('path').style('fill', function (d) {
        const regionId = d3.select(this).attr('id');
        // const population = countryData[regionId];
        // Define your color scale logic based on the data
        for (let i = 0; i < countryData1.length; i++) {
        if(countryData1[i].id == regionId){
          const population = countryData1[i].value;
          return colorScale(population);
        }
          
        }
      });

   
}
});
  return (
      <>
        {  map_data && map_data.chart_5 && (
              <div className='relative  w-[480px] sm:w-[600px] lg:w-[800px] xl:w-[900px] h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px]'>
                  <MySVG ref={svgRef} />
                  <div ref={tooltipRef}></div>
              </div>      
            )  
        }
      </>
  );
}

export default Map;
