import React from 'react'
import lessonImages from '../../assets/images/online_teaching.png'
import peopleImg from '../../assets/images/peopleIcon.png'
import priceImg from '../../assets/images/icons8-price-24.png'
import { moneyFormat } from "../../utils/helper";
export default function LessonCard2({lesson}) {
  return (
    <div className='rounded shadow max-w-[320px] w-full flex flex-col items-start'>
          <img src={lesson.img_url ? lesson.img_url :lessonImages} alt="lesson image" className='rounded-t w-full aspect-[2/1] object-cover' />
         <div className='px-3 py-4 space-y-2'>
            <div className="font-semibold  text-[14px] leading-[19px] text-left line-clamp-2 " title={lesson.name}>{lesson.name}</div>
            <div className="font-normal  leading-[15px] text-justify text-slate-400 text-[13px] mt-1 line-clamp-5" title={lesson.aguulga}>{lesson.aguulga}</div>
            {
                 lesson.teacher_descr != null && lesson.teacher_descr != '' ? (
                    <div className='flex items-center text-[13px] leading-[15px] font-semibold'>
                              <img src={peopleImg} alt="" className='ml-[2px]'/>      
                              <div className='ml-3 line-clamp-3' title={lesson.teacher_descr}>
                                  {lesson.teacher_descr}
                              </div>       
                    </div>
                  ) : (
                    <div></div>
                  )
            }
           <div className='flex items-center text-[13px] leading-[15px] font-semibold'>
               <img src={priceImg} alt=""/>
               <div className="ml-2 ">{moneyFormat(lesson.price)}</div>
           </div>
         </div>
    </div>
  )
}
