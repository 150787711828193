import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Close } from "@mui/icons-material";
import SVG from 'react-inlinesvg';
import imgLogin from "../../../assets/images/img_login.jpg";
import logoMn from "../../../assets/images/mn-logo.svg";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import ReplayIcon from '@mui/icons-material/Replay';
import { ToastContainer, toast } from 'material-react-toastify';
import api from "../../../services/api";
import Select from '@mui/material/Select';
import Spinner from "../../../components/base/Spinner/Spinner";
import {
  Box,Grid,Paper ,Button,Container,IconButton,FormControl,
  Dialog,TextField,Stack,Typography,InputAdornment,MenuItem,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
// import Loader from "../base/Loader/Loader";
Modal.setAppElement('#root');

function SignUpModal({ modalIsOpen, closeModal ,showLoginModal}) {
      const today =  new Date().getTime();
      const [userData, setUserData] = useState({
              entyear: null,
              name: "",
              fname: "",
              email: "",
              password: "",
              text: "",
          });
      const [selectedAimag, setSelectedAimag] = useState('');
      const [selectedSum, setSelectedSum] = useState('');
      const [selectedOrg, setSelectedOrg] = useState('');
      const [selectedOrgType, setSelectedOrgType] = useState('');
      const [selectedLevel, setSelectedLevel] = useState('');
      const [zipList, setZipList] = useState([]);
      const [aimagList, setAimagList] = useState([]);
      const [sumList, setSumList] = useState([]);
      const [orgList, setOrgList] = useState([]);
      const [orgTypeList, setOrgTypeList] = useState([]);
      const [showPassword, setShowPassword] = useState(false);
      const [captcha, setCaptcha] = useState(null);
      const [captchaDate, setCaptchaDate] = useState(null);
      const [errorEmail, setErrorEmail] = useState(false);
      const [pageNumber , setPageNumber] = useState(1);
      const [errorConfirmKey , setErrorConfirmKey] = useState(1);
      const [errorCaptcha, setErrorCaptcha] = useState(false);
      const [validEmail, setValidEmail] = useState(false);  
      const [isStartEmail, setIsStartEmail] = useState(false);  
      const [errorKey, setErrorKey] = useState(false);  
      const [pending ,setPending] = useState(false);
      const [validKey ,setValidKey] = useState("");
    useEffect(() => {
      refreshCaptcha();
      getZipcodes();
      getOrgList();
      getOrgTypes();
    }, []);
    useEffect(()=>{
        let sumLists=[];
        for (let i = 0; i <zipList.length; i++) {
          var zipid =zipList[i].zipid.toString()
          var isSum = zipid.substr(zipid.length - 3)
          var sumZip =zipid.substr(0, 2)
          if (selectedAimag) {
            var ProvinceZip = selectedAimag.toString().slice(0, 2);
            if (isSum != '000') {
              if (sumZip == ProvinceZip) {
                sumLists.push(zipList[i])
              }
            }
          }
        }
       setSumList(sumLists)
      },[selectedAimag]);

      const callEmailConfirm = () =>{
        setPending(true);
          api.post("user/forgetpass",{
            ifunc:'signup_emailconfirm',
            ip:"",
            params: userData,
            createAt:captchaDate,
            text : userData.text
        }).then((res) => {
            if(res.data.issuccess == 1){
              setPageNumber(2);
              toast.success('Таны мэйл хаягт баталгаажуулах код илгээлээ');
              // axios.defaults.headers.common['Authorization'] = res.data.accessToken;
              api.interceptors.request.use(function (config) {
                    config.headers.Authorization =  res.data.accessToken;
                    return config;
                });
            }else{
              toast.error('Бүртгэлтэй мэйл хаяг байна')
            }
            setPending(false);
        }).catch(
          (error)=>{
              let { status } = error.response;
              // console.log('err',error.response);
              if(status == 401){
                 toast.error(error.response.data.error)
              }
              setPending(false);
          }
        );
      }  
    
      const callKeyValid = () =>{
        setPending(true);
        api.post("user/emailconfirm",{
          ifunc:'signup_is_valid_key',
          ip:"",
          params:{
              "email": userData.email,
              "key": validKey
          }
      }).then((res) => {
          if(res.data.success == 1){
            userSignup();
          }else{
            toast.error('Баталгаажуулах код буруу байна')
          }
          setPending(false);
      }).catch(
        (error)=>{
            // console.log('err',error.response);
            if(error.response.data.message){
              toast.error(error)
            }else if (error.response.data.error){
              toast.error(error.response.data.error)
            }
            setPending(false);
        }
      );
    }  
    const handleChangeAimag = (event) => {
       // console.log('event Aimag',event);
      setSelectedAimag(event.target.value);
     
    };
    const handleChangeSum = (event) => {
     
      setSelectedSum(event.target.value);
  };
    const handleChangeLevel = (event) => {
     // console.log('event Level',event);
      setSelectedLevel(event.target.value);
    };
    const handleChangeOrg = (event) => {
      // console.log('event Org',event);
      setSelectedOrg(event.target.value);
    };
    const handleChangeOrgType = (event) => {
     // console.log('event OrgType',event);
      setSelectedOrgType(event.target.value);
    };
    const handleChange = e => {
      if(isStartEmail){
          if(e.currentTarget.id == 'email'){
            e.currentTarget.value == '' ? setErrorEmail(true) : setErrorEmail(false);
            !validateEmail(e.currentTarget.value)  ? setValidEmail(true) : setValidEmail(false);
        }else if(e.currentTarget.id == 'text'){
          e.currentTarget.value == '' ? setErrorCaptcha(true) : setErrorCaptcha(false);
        }
      }
      setUserData({...userData, [e.currentTarget.id]: e.currentTarget.value });
    };
    const refreshCaptcha = () => {
      setCaptchaDate(today)  
    api.get(`/captcha/create?date=`+today).then((res) => {
      if(res.status===200){
        setCaptcha(res.data);
      }});
    };
    const changeKey  = e =>{
            e.currentTarget.value == '' ? setErrorKey(true) : setErrorKey(false);
      setValidKey(e.currentTarget.value);
    }
    const getZipcodes = () => {
      api.get(`/trefZipcodes/find`).then((res) => {
        // console.log('zipcode res' ,res);
        if(res.status===200 && res.data.success){
          setZipList(res.data.message);
          let aimagLists =[];
          for (let i = 0; i < res.data.message.length; i++) {
            var zipid = res.data.message[i].zipid.toString()
            var provinceId = zipid.substr(zipid.length - 3)
            if (provinceId == '000') {
              aimagLists.push(res.data.message[i])
            }
          }
          setAimagList(aimagLists);
          // console.log('zipcode res' ,aimagList);
        }});
      };
    const getOrgList = () => {
        api.get(`/oreforgs/find`).then((res) => {
          // console.log('oreforgs res' ,res);
          if(res.status===200 && res.data.success){
            setOrgList(res.data.message);
            // console.log('orgList res' ,orgList);
          }});
        };
    const getOrgTypes = () => {
          api.get(`/oreforgtypes/find`).then((res) => {
            // console.log('oreforgtypes res' ,res);
            if(res.status===200 && res.data.success){
              setOrgTypeList(res.data.message);
              // console.log('orgTypeList res' ,orgTypeList);
            }});
          };
    const levelList = [
        { id: 4, label: 'Улс' },
        { id: 3, label: 'Аймаг' },
        { id: 2, label: 'Сум' },
        { id: 1, label: 'Сургууль' }
      ];

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
    const confirmEmailSubmit = () => {
      userData.email == '' ? setErrorEmail(true) : setErrorEmail(false);
      userData.text == '' ? setErrorCaptcha(true) : setErrorCaptcha(false);
      if( userData.email != ''){
        !validateEmail(userData.email) ? setValidEmail(true) : setValidEmail(false);
      }
      setIsStartEmail(true);
      if(userData.email == '' || !validateEmail(userData.email) ||  userData.text == '') return;
      callEmailConfirm(); 
    }

    const confirmKeySubmit = () => {
      userData.validKey == '' ? setErrorKey(true) : setErrorKey(false);
      if(userData.validKey == '') return;
      callKeyValid(); 
    }
    const backPage = () => {
        setPageNumber(1);
    }
    const userSignup =()=>{
          const zip_id =  selectedSum ? selectedSum : selectedAimag ? selectedAimag : null;
          const ent_year =  userData.entyear ? userData.entyear : null;
          const selected_org =  selectedOrg ? selectedOrg : null;
          const selected_level =  selectedLevel? selectedLevel : null;
          const send_data = {   
                            ...userData,  
                            zipid : zip_id, 
                            statusid : 3, 
                            orgid: selected_org,
                            level:selected_level, 
                            createAt : captchaDate,
                            entyear : ent_year
                        }
          // console.log('send_data',send_data);
        
        setPending(true);
          api.post( "/teacher/register", send_data, {
            withCredentials: true,
          }).then(
            (res)=>{
               if(res.data.message != "e-mail required"){
                toast.success(`Амжилттай бүртгэгдлээ`);
                setTimeout(() => {
                  closeModal(1);
                  setPageNumber(1);
                }, 500);
                
                setUserData({
                  entyear: null,
                  name: "",
                  fname: "",
                  email: "",
                  password: "",
                  text: "",
                })
                setSelectedAimag('')
                setSelectedSum('')
                setSelectedOrg('')
                setSelectedOrgType('')
                setSelectedLevel('')
                setTimeout(() => {
                  setPending(false);
                }, 2000);
              }else {
                toast.warning(`Өмнө нь үүссэн хэрэглэгч байна`);
              }
            }
          ).catch(function (error) {
            setPending(false);
            error&& error.response&&  error.response.data && error.response.data.error &&   toast.error(error.response.data.error);
            error&& error.response&&  error.response.data && error.response.data.message && toast.error(error.response.data.message);
          })
    }
    const emailHelperText = errorEmail ? "Мэйлээ оруулна уу" : validEmail ? 'Мэйлээ зөв оруулна уу' : '';
    const captchaHelperText = errorCaptcha ? "Зурган дээрх нийлбэрийг оруулна уу" : '';
    const validateEmail = (email) => {
      return email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
    };
  return (
    <>
      {/* {modalIsOpen && <Loader />} */}
      <Dialog maxWidth="lg" open={modalIsOpen}>
          <ToastContainer position="top-center" autoClose={4000}/>
          {pending && <Spinner></Spinner>} 
              <Box sx={{p:2,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                    <IconButton sx={{ position: "absolute", right: 12,   top: 8, color: { xs: (theme) => theme.palette.grey[500], md: "#000" }, }}
                             onClick={()=>{closeModal(0);setPageNumber(1)}}>
                         <Close />
                    </IconButton>
                    <Box sx={{ display: "flex", alignItems: "center",pb:2}}>
                        <img width={60} height={60} src={logoMn} alt="logoMn"/>
                        <p sx={{ fontSize: "16px", fontWeight: "bold", ml: 2 }} className="text-[16px] font-bold ml-3">
                              БАГШИЙН ХӨГЖИЛ
                        </p>
                    </Box>
                    {
                      pageNumber == 1 &&
                      <Box sx={{p:1,width:{xs:340,sm:500,md:600}}}>
                      {/* sx={{ width: { xs:300, sm: 500 , md :500 } }} */}
                        <Grid container  rowSpacing={3} columnSpacing={2} justifyContent="center" alignItems="center">
                              <Grid item xs={12} sm={6} md={6} >
                                 <TextField label="Овог" size="small" value={userData.fname} fullWidth  id="fname" onChange={handleChange} 
                                               InputProps={{classes: { input: {fontSize:10}, },}}/>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} >
                                <TextField label="Нэр" onChange={handleChange}  value={userData.name} id="name"  size="small" fullWidth />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} >
                                    <TextField label="Мэйл" onChange={handleChange} value={userData.email}  error={errorEmail || validEmail} id="email" size="small" helperText={emailHelperText} fullWidth/>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} >
                                 <TextField label="Багшилж эхэлсэн он" onChange={handleChange}   id="entyear"  size="small" fullWidth/>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} >
                                <Box sx={{width:{ sm :"100%",md :"100%",bgcolor:"black"}}}>
                                  <FormControl variant="outlined" fullWidth size="small">
                                      <InputLabel htmlFor="outlined-adornment-password">
                                        Нууц үг
                                      </InputLabel>
                                      <OutlinedInput
                                        label="Нууц үг"
                                        type={showPassword ? "text" : "password"}
                                        onChange={handleChange} 
                                        value={userData.password}
                                        id="password"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                            // onMouseDown={handleMouseDownPassword}
                                              edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                          </InputAdornment>
                                           }/>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Box >
                                    <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Байгууллагын төрөл</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedOrgType}
                                      label="Байгууллагын төрөл"
                                      onChange={handleChangeOrgType}>
                                    {orgTypeList && orgTypeList.map((orgType,index)=><MenuItem key={index} value={orgType.id}>{orgType.name}</MenuItem>)}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                  <Box >
                                      <FormControl fullWidth size="small">
                                      <InputLabel id="demo-simple-select-label">Хот/аймаг</InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedAimag}
                                        label="Хот/аймаг"
                                        onChange={handleChangeAimag}
                                      >
                                        {aimagList && aimagList.map((aimag,index)=><MenuItem key={index} value={aimag.zipid}>{aimag.name}</MenuItem>)}
                                      </Select>
                                    </FormControl>
                                  </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Box >
                                    <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Сум/дүүрэг</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedSum}
                                      label="Сум/дүүрэг"
                                      onChange={handleChangeSum}
                                    >
                                      {sumList && sumList.map((sum,index)=><MenuItem key={index} value={sum.zipid}>{sum.name}</MenuItem>)}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Box >
                                    <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Ажиллаж буй сургууль</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedOrg}
                                      label="Ажиллаж буй сургууль"
                                      onChange={handleChangeOrg}
                                    >
                                      {orgList && orgList.map((org,index)=><MenuItem key={index} value={org.id}>{org.name}</MenuItem>)}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} >
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Түвшин сонгох</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={selectedLevel}
                                          label="Түвшин сонгох"
                                          onChange={handleChangeLevel}>
                                            {levelList && levelList.map((sum,index)=><MenuItem key={index} value={sum.id}>{sum.label}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                              </Grid>
                        </Grid>
                        <Grid container  rowSpacing={3} columnSpacing={2} justifyContent="center" alignItems="center" sx={{pt:2}}>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField label="Нийлбэрийг оруулна уу" value={userData.text}  id="text"  size="small" onChange={handleChange}  error={errorCaptcha} helperText={captchaHelperText} fullWidth/>
                           </Grid>    
                          <Grid item xs={6} sm={6} md={6} className="flex ">
                                  <SVG src={captcha} />
                                  <IconButton aria-label="refresh captcha" onClick={refreshCaptcha} edge="end">
                                      <ReplayIcon /> 
                                  </IconButton>
                          </Grid>
                        </Grid>
                        <Box sx={{mt:4 }}>
                              <Button variant="contained" size="medium" onClick={confirmEmailSubmit} fullWidth >
                                Бүртгүүлэх
                              </Button>
                        </Box>  
                      </Box>
                    }
                    {
                      pageNumber == 2 && 
                      <div className="flex flex-col items-start w-[340px]"> 
                        <h5 className="my-2">Мэйл хаяг баталгаажуулах</h5>
                        <div className="text-[14px] mb-3 leading-[15px]">  
                            Таны <span className="font-bold"> { userData.email } </span>  хаягт илгээсэн баталгаажуулах кодыг оруулна уу.
                          </div>            
                            <TextField label="Баталгаажуулах код" fullWidth onChange={changeKey} error={errorKey} helperText={errorKey ? 'Баталгаажуулах код оруулна уу' : ''}/>
                          <button className="w-full h-[50px] rounded bg-primary text-white mb-4 mt-[5rem]" onClick={confirmKeySubmit}>
                                    Баталгаажуулах
                          </button>  
                          <button className="w-full h-[50px] rounded text-primary border" onClick={backPage}>
                                    Буцах
                          </button>              
                      </div>
                    }
              </Box>
      </Dialog>
    </>
  );
}
export default SignUpModal;
