import React from "react";
import moment from "moment";
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { useNavigate } from "react-router-dom";
export default function LatestNews({data}) {
  let navigate = useNavigate();
          function handleClick() {
                    // setNewsId(data.id);
                    // console.log("bna "+data.id);
                    navigate(`../news/${data.id}`, { replace: true });
                    window.location.reload();
                  }
return (
          <li className="hover:bg-gray-100 rounded-md cursor-pointer p-2  block  hover:text-[#0056b3]" onClick={handleClick}> 
                    <h3 className="font-medium line-clamp-2 text-xs !text-[#333]">  {JSON.parse(data.news_txt).title} </h3>
                    <div className="flex items-center my-auto text-xs space-x-1.5">
                              <div> {moment(data.inserttime).format("MM-DD-YYYY")}</div> 
                              <div className="pb-1"> . </div> 
                              <TextsmsOutlinedIcon className="!text-[15px]"></TextsmsOutlinedIcon> 
                              <div> {Math.round(Math.random()*10)}</div>
                    </div> 
          </li>
)
}