import React from 'react'
import { Line } from 'react-chartjs-2';
import {
     Chart as ChartJS,
     CategoryScale,
     LinearScale,
     PointElement,
     LineElement,
     Title,
     Tooltip,
     Legend,
     registerables
   } from 'chart.js';
   ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement,Title,Tooltip,Legend,...registerables);
export default function LineChart({chart_data}) {
          const lineChartOption = {
                    responsive: true,
                    plugins: {
                              legend: {
                              position: 'top',
                              },
                              title: {
                              display: true,
                              text: 'Сүүлийн 1 сарын ашиглалт',
                              },
                    },
                    };
          let labels = ['1 сар', '2 сар', '3 сар', '4 сар', '5 сар', '6 сар', '7 сар'];
          let data1 = [1,2,3,4,5,6,7];
          if (chart_data){
                         // console.log('chart_data',chart_data);
                         labels  = [];
                          data1 = [];
                         chart_data.forEach(element => {
                              labels.push(element.dat);
                              data1.push(element.is_esis_login);
                         });
                         // console.log('labels',labels);          
                    }          
          let lineChartData = {
          labels,
          datasets: [
                    {
                         label: 'Багш',
                         data: data1,
                         borderColor: 'rgb(71, 80 ,204)',
                         backgroundColor: 'rgba(71, 80, 204, 0.5)',
                    }
          ],
          };
  return (
          <div className="">
                    <Line options={lineChartOption} data={lineChartData} />
          </div>
  )
}
