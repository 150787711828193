import React, { useState ,useEffect} from 'react'
import HeaderIntro from "../../layout/HeaderIntro/Header";
import {BsCheckCircle} from "react-icons/bs";
import onlineteaching from '../../assets/images/online_teaching.png';
import Footer from '../../layout/Footer/Footer';
import appstore from '../../assets/images/appstore.png';
import { Link } from 'react-router-dom';
import imageP from '../../assets/images/imageP.png';
import {BiTachometer,BiLogoApple,BiLogoPlayStore} from "react-icons/bi";
import {GoClock} from "react-icons/go"
import {LuSearch} from "react-icons/lu"
import {FaRegComment} from "react-icons/fa"
import * as Icon from 'react-feather'
const accordionData = [ {
          id:1,
          title:
            "Багш платформд хэрхэн нэвтрэх вэ ?",
          text: "Багш нар esis.edu.mn платформд нэвтэрдэг багшийн код ба нууц үгээ ашиглан “Багш платформ”-д нэвтрэнэ",
        },
        {
          id:2,
          title: "Багш платформын хичээлүүд төлбөртэй юу?",
          text: "Хичээлүүд төлбөртэй бөгөөд Qpay, SocialPay болон Багш картаар тооцоо хийн худалдан авах боломжтой",
        },
        {
          id:3,
          title: "Сургалтанд хамрагдаад сертификат авах уу?",
          text: "Багш нар хичээлийг амжилттай үзэж судлан, шаардлагатай түвшинг давсан тохиолдолд Батламж авах эрхтэй болно",
        },
        {
          id:4,
          title: "Багш платформоор үзэж судалсан хичээлүүд ESIS-д бүртгэгдэх үү?",
          text: "Багш нар хичээлийг амжилттай үзэж судлан батламж авсан бол Боловсролын салбарын мэдээллийн систем буюу ESIS-д автомат бүртгэгдэнэ",
        },
        {
          id:5,
          title: "Сургалтын байгууллагууд хамтарч ажиллах боломжтой юу?",
          text: "Сургалтын байгууллагууд Багш платформд хичээлээ байршуулан хамтран ажиллах боломжтой",
        },
        {
          id:6,
          title: "Бусад LMS платформ дээр боловсруулсан контентоо ашиглаж болох уу?",
          text: "Багш платформ нь SCORM болон LTI стандартаар бусад LMS платформоос контент хүлээн авах боломжтой",
        },
      ]
const forumData = [
        {
            image:imageP,
            title:"Techwind Announcements",
            name:"Calvin Carlo",
            desc:"This forum is for our announcements. Only our staff can create new topics."
        },
        {
            image:imageP,
            title:"Web Designing and Developing",
            name:"Alia Reddy",
            desc:"This forum is for our announcements. Only our staff can create new topics."
        },
        {
            image:imageP,
            title:" Hosting and providers",
            name:"Cristino Murphy",
            desc:"This forum is for our announcements. Only our staff can create new topics."
        },
        {
            image:imageP,
            title:"SEO starter guide",
            name:"Jennifer Ballance",
            desc:"This forum is for our announcements. Only our staff can create new topics."
        },
        {
            image:imageP,
            title:"Troubleshooting and managing issues",
            name:"Toni Kovar",
            desc:"This forum is for our announcements. Only our staff can create new topics."
        },
        {
            image:imageP,
            title:"Backup and restore",
            name:"Tara Arrington",
            desc:"This forum is for our announcements. Only our staff can create new topics."
        },
        {
            image:imageP,
            title:"Errors and how to fix them",
            name:"Jennifer Ballance",
            desc:"This forum is for our announcements. Only our staff can create new topics."
        },
        {
            image:imageP,
            title:"Template features & Servicess",
            name:"Tara Arrington",
            desc:"This forum is for our announcements. Only our staff can create new topics."
        },
        {
            image:imageP,
            title:"Techwind includes the ability to create a better of sites by using the multisite feature.",
            name:"Tara Arrington",
            desc:"This forum is for our announcements. Only our staff can create new topics."
        },
    ]
export default function Cooperation(){
          const [activeIndex, setActiveIndex] = useState(1);
          const toggleAccordion = (index) => {
                    if (activeIndex === index) {
                        setActiveIndex(0);
                    } else {
                        setActiveIndex(index);
                    }
                }
       return (
          <>
           <HeaderIntro/>
           <div className='mx-auto'>
                   <section id="section-intro" className="relative md:py-24 py-16 overflow-hidden">
                        <div className="container relative">
                            <div  className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                                <div className="lg:col-span-5">
                                <img src={onlineteaching} alt="" />
                                </div>
                                <div className="lg:col-span-7">
                                <div className="lg:ms-10">
                                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Мэргэжлийн хөгжлийн бүлэг</h3>
                                    <p className="text-slate-400 max-w-xl">Бүх шатны боловсролын сургалтын байгууллагын багш зорилго, санаачилгаар нэгдэн сонирхлын бүлэг байгуулж, хамтран ажиллаж болно. Багш платформ нь мэргэжлийн бүлгийг сургууль, орон нутаг, үндэсний түвшинд үүсгэж туршлага мэдлэгээ солилцох боломжийг олгоно.</p>

                                    <ul className="list-none text-slate-400 mt-4 ">
                                    <li className="mb-1 flex items-center"><BsCheckCircle className="text-primary2 text-base me-2" /> Багш мэргэжлийн бүлгийн үйл ажиллагаанд хамрагдах</li>
                                    <li className="mb-1 flex items-center"><BsCheckCircle className="text-primary2 text-base me-2" /> Ментор багштай хамтран ажиллах, дагалдан суралцах</li>
                                    <li className="mb-1 flex items-center"><BsCheckCircle className="text-primary2 text-base me-2" /> Хамтран ном сурах бичиг, гарын авлага бичих бүтээлч үйл ажиллагаа</li>
                                    <li className="mb-1 flex items-center"><BsCheckCircle className="text-primary2 text-base me-2" /> Сургууль хоорондын мэргэжлийн хөгжлийн бүлгийн үйл ажиллагаанд хамрагдах</li>
                                    </ul>

                                    {/* <div className="mt-4">
                                    <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-primary2 hover:text-indigo-900 after:bg-primary2 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                                    </div> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                      {/* <section className="relative md:py-24 py-16">
                            <div className="container relative">
                                <div className="grid grid-cols-1">
                                    <div className="relative -mt-12 rounded-md shadow dark:shadow-gray-800 overflow-hidden bg-white dark:bg-slate-900">
                                        <div className="grid md:grid-cols-12 grid-cols-1 items-center">
                                            <div className="md:col-span-6 bg-slate-50 h-full flex justify-center items-center">
                                                <div className={`w-3/4 h-[250px] bg-slate-50 bg-[url(${onlineteaching})] bg-no-repeat bg-top bg-cover jarallax`} data-jarallax data-speed="0.5"></div>
                                            </div>
                                            <div className="md:col-span-6">
                                                <div className="p-6">
                                                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Join Our Forum</h3>

                                                    <p className="text-slate-400 max-w-xl">Talk about anything that's on your mind and see what others think. As a guest to our forum you are only able to view posts. When you register with the Forumix forum you can join in with topics, start new topics and generally be a part of the first level of our community.</p>

                                                    <div className="mt-6">
                                                        <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded me-2 mt-2">Register Now!</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container relative md:mt-24 mt-16">
                                <div className="grid lg:grid-cols-12 grid-cols-1 gap-[30px]">
                                    <div className="lg:col-span-8">
                                        <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 rounded-md border border-gray-100 dark:border-slate-800">
                                            <table className="w-full text-start">
                                                <thead className="text-lg border-b border-gray-100 dark:border-slate-800">
                                                    <tr>
                                                        <th className="text-start py-6 px-4 font-semibold min-w-[300px]">Forum</th>
                                                        <th className="text-center py-6 px-4 font-semibold min-w-[40px]">Topics</th>
                                                        <th className="text-center py-6 px-4 font-semibold min-w-[40px]">Comments</th>
                                                        <th className="text-end py-6 px-4 font-semibold min-w-[220px]">Posted</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {forumData.map((item,index)=>{
                                                        return(
                                                            <tr className="border-b border-gray-100 dark:border-slate-800" key={index}>
                                                            <th className="p-4 text-start">
                                                                <div className="flex">
                                                                <FaRegComment className="text-indigo-600 text-2xl"/>
                
                                                                    <div className="ms-2">
                                                                        <Link to="/forums-topic" className="hover:text-indigo-600 text-lg">{item.title}</Link>
                                                                        <p className="text-slate-400 font-normal">{item.desc}</p>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <td className="text-center p-4">5</td>
                                                            <td className="text-center p-4">10</td>
                                                            <td className="p-4">
                                                                <div className="flex items-center">
                                                                    <img src={item.image} className="h-10 rounded-full shadow dark:shadow-slate-800" alt="" />
                
                                                                    <div className="ms-2">
                                                                        <Link to="#" className="hover:text-indigo-600 font-semibold">{item.name}</Link>
                                                                        <p className="text-slate-400 text-sm font-normal inline-flex items-center"><GoClock/> May 2022</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-4 md:col-span-6">
                                        <div className="sticky top-20">
                                            <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">Sign In</h5>
                                            <form className="text-start mt-8">
                                                <div className="grid grid-cols-1">
                                                    <div className="mb-4">
                                                        <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label>
                                                        <input id="LoginEmail" type="email" className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="name@example.com" />
                                                    </div>

                                                    <div className="mb-4">
                                                        <label className="font-semibold" htmlFor="LoginPassword">Password:</label>
                                                        <input id="LoginPassword" type="password" className="form-input mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Password:" />
                                                    </div>

                                                    <div className="flex justify-between mb-4">
                                                        <div className="flex items-center mb-0">
                                                            <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 me-2" type="checkbox" value="" id="RememberMe" />
                                                            <label className="form-checkbox-label text-slate-400" htmlFor="RememberMe">Remember me</label>
                                                        </div>
                                                        <p className="text-slate-400 mb-0"><Link to="/auth-re-password" className="text-slate-400">Forgot password ?</Link></p>
                                                    </div>

                                                    <div className="mb-4">
                                                        <input type="submit" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full" value="Login / Sign in" />
                                                    </div>

                                                    <div className="text-center">
                                                        <span className="text-slate-400 me-2">Don't have an account ?</span> <Link to="/auth-signup" className="text-black dark:text-white font-bold inline-block">Sign Up</Link>
                                                    </div>
                                                </div>
                                            </form>

                                            <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Recent Reply</h5>
                                            <div className="flex items-center mt-8">
                                                <img src={onlineteaching} className="h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                                                <div className="ms-3">
                                                    <Link className="font-semibold hover:text-indigo-600">Consultant Business</Link>
                                                    <p className="text-sm text-slate-400">1st May 2022</p>
                                                </div>
                                            </div>

                                            <div className="flex items-center mt-4">
                                                <img src={onlineteaching} className="h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                                                <div className="ms-3">
                                                    <Link className="font-semibold hover:text-indigo-600">Grow Your Business</Link>
                                                    <p className="text-sm text-slate-400">1st May 2022</p>
                                                </div>
                                            </div>

                                            <div className="flex items-center mt-4">
                                                <img src={onlineteaching} className="h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                                                <div className="ms-3">
                                                    <Link className="font-semibold hover:text-indigo-600">Look On The Glorious Balance</Link>
                                                    <p className="text-sm text-slate-400">1st May 2022</p>
                                                </div>
                                            </div>

                                            <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Social sites</h5>
                                            <ul className="list-none text-center mt-8 space-x-1">
                                                <li className="inline"><Link to='#' className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Icon.Facebook className="h-4 w-4"></Icon.Facebook></Link></li>
                                                <li className="inline"><Link to="#" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Icon.Instagram className="h-4 w-4"></Icon.Instagram></Link></li>
                                                <li className="inline"><Link to='#' className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Icon.Twitter className="h-4 w-4"></Icon.Twitter></Link></li>
                                                <li className="inline"><Link to='#' className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Icon.Linkedin className="h-4 w-4"></Icon.Linkedin></Link></li>
                                                <li className="inline"><Link to='#' className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Icon.GitHub className="h-4 w-4"></Icon.GitHub></Link></li>
                                                <li className="inline"><Link to='#' className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Icon.Youtube className="h-4 w-4"></Icon.Youtube></Link></li>
                                                <li className="inline"><Link to='#' className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Icon.Gitlab className="h-4 w-4"></Icon.Gitlab></Link></li>
                                            </ul>

                                            <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Tagscloud</h5>
                                            <ul className="list-none text-center mt-8 space-x-1">
                                                <li className="inline-block m-2"><Link to='#' className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Business</Link></li>
                                                <li className="inline-block m-2"><Link to='#' className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Finance</Link></li>
                                                <li className="inline-block m-2"><Link to='#' className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Marketing</Link></li>
                                                <li className="inline-block m-2"><Link to='#' className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Fashion</Link></li>
                                                <li className="inline-block m-2"><Link to='#' className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Bride</Link></li>
                                                <li className="inline-block m-2"><Link to='#' className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Lifestyle</Link></li>
                                                <li className="inline-block m-2"><Link to='#' className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Travel</Link></li>
                                                <li className="inline-block m-2"><Link to='#'className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Beauty</Link></li>
                                                <li className="inline-block m-2"><Link to='#' className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Video</Link></li>
                                                <li className="inline-block m-2"><Link to='#' className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Audio</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </section> */}
                    <section className="container">
                              <div className="grid grid-cols-1 pb-8 text-center">
                              <h3 className="mt-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Түгээмэл асуултууд</h3>
                              </div>

                              <div id="accordion-collapse" data-accordion="collapse" className="grid md:grid-cols-2 grid-cols-1 md:gap-[30px]">
                              <div>
                              {accordionData.slice(0,3).map((item, index) => (
                                        <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                                  <button type="button" onClick={() => toggleAccordion(item.id)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === item.id ? 'bg-gray-50 dark:bg-slate-800 text-blue-900' : ''}`} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                  <span>{item.title}</span>
                                                  <svg data-accordion-icon className={`${activeIndex === item.id ? "rotate-180" : "rotate-270" } w-4 h-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                  </svg>
                                                  </button>
                                        </h2>
                                        {activeIndex === item.id && (
                                                  <div>
                                                  <div className="p-5">
                                                  <p className="text-slate-400 dark:text-gray-400">{item.text}</p>
                                                  </div>
                                                  </div>
                                        )}
                                        </div>

                              ))}
                              </div>

                              <div>
                              {accordionData.slice(3,6).map((item, index) => (
                                        <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                                  <button type="button" onClick={() => toggleAccordion(item.id)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === item.id ? 'bg-gray-50 dark:bg-slate-800 text-blue-900' : ''}`} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                  <span>{item.title}</span>
                                                  <svg data-accordion-icon className={`${activeIndex === item.id ? "rotate-180" : "rotate-270" } w-4 h-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                  </svg>
                                                  </button>
                                        </h2>
                                        {activeIndex === item.id && (
                                                  <div>
                                                  <div className="p-5">
                                                  <p className="text-slate-400 dark:text-gray-400">{item.text}</p>
                                                  </div>
                                                  </div>
                                        )}
                                        </div>

                              ))}
                              </div>

                              </div>
                    </section>
                    <section className="container relative md:my-24 my-16">
                              <div className="grid md:grid-cols-12 grid-cols-1 items-center">
                                        <div className="lg:col-span-5 md:col-span-6 p-8 rounded-md">
                                          <img src={appstore} className="mx-auto d-block" alt="" />
                                        </div>

                                        <div className="lg:col-span-7 md:col-span-6">
                                                  <span className="bg-indigo-600/5 text-primary2 text-xs font-bold px-2.5 py-0.5 rounded h-5">Мобайл апп</span>
                                                  <h4 className="md:text-3xl text-2xl lg:leading-normal leading-normal font-medium my-4">АПП ТАТАХ</h4>
                                                  <p className="text-slate-400 max-w-xl mb-0">Та мобайл апп татаж ашигласнаар хичээлүүдээ гар утсандаа татан авч, интернэт сүлжээгүй оффлайн горимд ч суралцах боломжтой</p>
                                                  <div className="my-5 w-full flex items-center justify-start space-x-2">
                                                            <Link  className="flex justify-center items-center space-x-1 bg-primary2 rounded-md h-[44px] w-[145px] hover:bg-indigo-900 text-white shadow-lg transition-all duration-300" to="https://apps.apple.com/us/app/%D0%B1%D0%B0%D0%B3%D1%88-%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC/id1623960946"><BiLogoApple/><span className="text-xs font-semibold">App Store</span></Link>

                                                            <Link className="flex justify-center items-center space-x-1 border border-primary2 rounded-md h-[43px] w-[145px] hover:bg-indigo-900 text-primary2 hover:text-white shadow-lg transition-all duration-300" to="https://play.google.com/store/apps/details?id=mn.starsoft.itpdapp"><BiLogoPlayStore/><span className="text-xs font-semibold">Google Play</span></Link>
                                                  </div>
                                        </div>
                              </div>
                    </section>     
           </div>
           <Footer navtype={2}/>
         </>
      )
}