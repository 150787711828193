import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import CourseInfoList from "./CourseInfoList/CourseInfoList";
import CourseInfoAbout from "./CourseInfoAbout/CourseInfoAbout";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function CourseInfoTabs({ data }) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        sx={{
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: "10px",
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "700px",
        }}
      >
        <Button
          {...a11yProps(0)}
          onClick={() => handleChange(0)}
          fullWidth
          variant={value === 0 ? "contained" : "text"}
        >
          Сургалтын мэдээлэл
        </Button>
        <Button
          {...a11yProps(1)}
          onClick={() => handleChange(1)}
          fullWidth
          variant={value === 1 ? "contained" : "text"}
        >
          Хөтөлбөр
        </Button>
        {/* <Button
          {...a11yProps(2)}
          onClick={() => handleChange(2)}
          fullWidth
          variant={value === 2 ? "contained" : "text"}
        >
          Дүгнэлт
        </Button> */}
      </Typography>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <CourseInfoAbout data={data} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <CourseInfoList data={data} />
        </TabPanel>
        {/* <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel> */}
      </SwipeableViews>
    </Box>
  );
}
