import React, { useState ,useEffect} from 'react'
import { NavLink } from 'react-router-dom';
import logo from "../../assets/images/mn-logo.svg";
import { BiNotepad } from "react-icons/bi";
import { FaRegHandshake } from "react-icons/fa";
import { AiOutlineSound, AiOutlineVideoCameraAdd } from "react-icons/ai";
import {AiOutlineClose} from "react-icons/ai";
 import {RxHamburgerMenu} from "react-icons/rx";
export default function HeaderIntro(){
      const [isSideBar, setIsSideBar] = useState(false);

      const linkStyle = "text-left h-[40px] no-underline text-slate-500  text-[16px] font-bold hover:rounded-md hover:bg-slate-100 hover:transition-all hover:duration-300 px-3 py-1 cursor-pointer"
      const headerStyleNav = 'flex items-center justify-center space-x-1 text-2xl cursor-pointer px-3 py-1 rounded-lg hover:bg-[#16166e99] hover:text-[#ffd11a]'
      const textStyleNav = 'text-sm'
          return (
        <div className="fixed left-0 top-0 h-[100px] w-full z-[100] bg-primary2 flex items-center justify-center">
            <div className="flex items-center justify-between  w-full text-white container">
                <div className="flex justify-center items-center space-x-3 ">
                    <img src={logo} alt="logo" className="w-[60px]"/>
                    <h3 className="text-[20px]  text-white">Багшийн хөгжил</h3>
                </div>
                <div className="hidden items-center justify-center space-x-3 lg:flex">
                    <NavLink to="/" className={headerStyleNav}>
                        <BiNotepad className="my-2" />
                        <h5 className={textStyleNav}>Танилцуулга</h5>
                    </NavLink>
                    <NavLink to="/main/home" className={headerStyleNav}>
                        <AiOutlineVideoCameraAdd className="my-2" />
                        <h5 className={textStyleNav}>Сургалтууд</h5>
                    </NavLink>
                    <NavLink to="/cooperation" className={({ isActive }) => (isActive ? headerStyleNav+" text-[#ffd002]" : headerStyleNav)}>
                    <FaRegHandshake className="my-2" />
                    <h5 className={textStyleNav}>Хамтын ажиллагаа</h5>
                    </NavLink>
                    <NavLink to="/annoucement" className={({ isActive }) => (isActive ? headerStyleNav+" text-[#ffd002]" : headerStyleNav)}>
                    <AiOutlineSound className="my-2" />
                    <h5 className={textStyleNav}>
                        Зар мэдээ 
                    </h5>
                    </NavLink>
                </div>
                <div className="block lg:hidden">
                        <RxHamburgerMenu className="cursor-pointer text-white hover:text-slate-300 text-xl font-bold" onClick={()=>setIsSideBar(true)}/>
                        {isSideBar&&<div className="fixed bg-[#0000009b] inset-0" onClick={()=>setIsSideBar(false)}>  </div>}
                        <div className={`fixed right-0 z-[101]  top-0 bottom-0 w-[280px] bg-white transition-all ease-in-out duration-400 flex flex-col p-6 overflow-y-auto px-[40px] py-[16px] text-left ${isSideBar?'mr-0':'-mr-[280px]'} `}>
                            <div className="flex justify-end mr-[-16px] cursor-pointer" onClick={()=>setIsSideBar(false)}>
                                <AiOutlineClose size={24} className="text-slate-400" />
                            </div>
                            <div className="text-[16px] text-[#15387c] font-bold pb-[16px]">
                                <div className="flex">
                                    <img width={60} height={60} src={logo} alt="logo"/>
                                    <div className="ml-[12px]"></div>
                                    <span className="text-primary flex flex-col justify-end">
                                        Боловсролын ерөнхий газар
                                    </span>
                                </div>
                                <div className="dropdown-divider"></div>
                            </div>
                            <div className="flex flex-col justify-center text-dark">
                                <NavLink  to="/" className={linkStyle} onClick={()=>{setIsSideBar(false);}}>
                                Танилцуулга
                                </NavLink>
                                <NavLink to="/main/home" className={linkStyle} onClick={()=>{setIsSideBar(false)}}>
                                Сургалтууд
                                </NavLink>
                                <NavLink to="/cooperation" className={({ isActive }) => (isActive ? linkStyle+" rounded-md bg-slate-100" : linkStyle)} onClick={()=>setIsSideBar(false)}>
                                Хамтын ажиллагаа
                                </NavLink>
                                <NavLink to="/annoucement" className={({ isActive }) => (isActive ? linkStyle+" rounded-md bg-slate-100" : linkStyle)} onClick={()=>setIsSideBar(false)}>
                                Зар мэдээ 
                                </NavLink>
                            </div>
                        </div>
                </div>
            </div>
        </div>
          )
}