import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import profile from "../../assets/images/profile.png";
import lessonBg from "../../assets/images/lessonBg.png";
import CourseInfoTabs from "./CourseInfoTabs/CourseInfoTabs";
import HomeSharpIcon from "@mui/icons-material/HomeSharp";
import PersonIcon from '@mui/icons-material/Person';
import SegmentIcon from '@mui/icons-material/Segment';
import Login from "../Home/Login";
import {FaPlay} from "react-icons/fa";
export default function CourseDetail() {
  const { id } = useParams();
  const { state } = useLocation();
  const { data } = state;
  const [open, setOpen] = React.useState(false);
  const closeFunc = () => {
    setOpen(false);
  };
  const openFunc = () => {
    setOpen(true);
  };
  const isPlay = () => {
    let video = document.getElementById("intro_video");
    let btn = document.getElementById("play-btn");
    video.play();
    btn.style.visibility = "hidden";
    video.controls = true;
  };
  return (
    <Box py={5} sx={{ backgroundColor: "#f9f9f9" }}>
      <Login handleClose={closeFunc} open={open} toLink={data.id} lessontype={'lesson'} />
      <div className="container">
        <Grid container spacing={3}>
          <Grid item lg={8} xs={12}>
            <div className="text-[24px]  font-bold mb-4 text-justify">
               {data.name}
            </div>
            <Grid container sx={{ display: "flex" }}>
              <Grid item lg={4} xs={12} sx={{ display: "flex" }}>
                <img src={profile} className="shadow rounded-full w-[50px] h-[50px]"  alt="profile"/>
                <Box
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    ml: 2,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: 700 }}
                    color="text.secondary"
                  >
                    Багш:
                  </Typography>
                  <Typography sx={{ fontWeight: 700 }} variant="body">
                  {data.teach_name}
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                lg={5}
                xs={12}
                sx={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 700 }}
                  color="text.secondary"
                >
                  Сүүлийн шинэчлэлт:
                </Typography>
                <Typography sx={{ fontWeight: 700 }} variant="body">
                  {moment(data.inserttime).format("ll")}
                </Typography>
              </Grid>
              <Grid
                item
                lg={3}
                xs={12}
                sx={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 700 }}
                  color="text.secondary"
                >
                  Үнэлгээ:
                </Typography>
                <Box display={"flex"}>
                  <Rating name="read-only" value={4} precision={0.5} readOnly />
                  <Typography variant="body" ml={1}>
                    {Math.round(Math.random() * 4) + 1}
                    <Typography color="text.secondary" variant="overline">
                   
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box mt={4}>
              <img
                width={"100%"}
                style={{borderRadius: "10px",height: "290px",objectFit: "cover",background:"white"}}
                alt="img_url"
                src={data.img_url ? data.img_url : lessonBg}
              />
            </Box>
            <div>
            </div>
            <Box mt={4}>
              <CourseInfoTabs data={data} />
            </Box>
          </Grid>
          <Grid item lg={4} xs={12} >
            <Card
              sx={{
                position: "sticky",
                top:"120px",
                background: "white",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px ",
              }}
            >
              <CardContent>
                <Box sx={{ position: "relative", mb: 3 ,height:"200px",background:"#ddd",borderRadius:"10px"}}>
                  <div
                    className="w-[50px] h-[50px] z-[500] rounded-full absolute block top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] shadow-lg bg-primary border-4 border-white flex justify-center items-center cursor-pointer"
                    id="play-btn"
                    sx={{cursor:"pointer"}}
                    onClick={isPlay}
                  >
                     <FaPlay className="text-white"/>
                  </div>
                  <video
                    id="intro_video"
                    poster={data.img_url ? data.img_url : lessonBg}
                    className="w-full rounded-[8px] object-cover h-full"
                  >
                    <source
                      src={data.intro_url ? data.intro_url : ""}
                      type="video/mp4"
                    />
                    <source src="movie.ogg" type="video/ogg" />
                  </video>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6">{data.dis_price}₮</Typography>
                  
                </Box>
                <Box
                  mt={3}
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PersonIcon color="primary" />
                  <Typography variant="subtitle2" sx={{ mx: 1 }}>
                    Багш :
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {data.teach_name}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  mt={2}
                  mb={1}
                  sx={{
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <SegmentIcon color="primary" />
                  <Typography variant="subtitle2" sx={{ mx: 1 }}>
                    Бүлэг :{" "}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                      {
                        data.cat_name && data.cat_name != null ? <span>
                          {data.cat_name}
                        </span>
                        : <span>
                         Бүлэг сонгоогүй
                      </span>
                      }
                  </Typography>
                </Box>
                <Divider />
                {/* <Box
                  mt={2}
                  mb={1}
                  sx={{
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <HomeSharpIcon color="primary" />
                  <Typography variant="subtitle2" sx={{ mx: 1 }}>
                    Нийт хичээл :{" "}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    Анхан
                  </Typography>
                </Box>
                <Divider />
                <Box
                  mt={2}
                  mb={1}
                  sx={{
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  <HomeSharpIcon color="primary" />
                  <Typography variant="subtitle2" sx={{ mx: 1 }}>
                    Нийт хугацаа :{" "}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {data.total_time ? data.total_time : 0}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  mt={2}
                  mb={1}
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <HomeSharpIcon color="primary" />
                  <Typography variant="subtitle2" sx={{ mx: 1 }}>
                    Сүүлийн шинэчлэлт :
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {moment(data.inserttime).format("L")}
                  </Typography>
                </Box>
                <Divider /> */}
              </CardContent>
              <CardActions sx={{ mb: 3 ,px:3}}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={openFunc}
                  fullWidth
                >
                  Хичээл үзэх
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}
