import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {  Divider, Grid, IconButton, MenuItem } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import LockIcon from "@mui/icons-material/Lock";
import Login from "../../../Home/Login";
import { useEffect } from "react";
import api from "../../../../services/api";
import { useState } from "react";
export default function CourseInfoList({ data }) {
  const [module,setModule] = useState(null);
  useEffect(() => {
    // console.log('data',data);
    api.post(`/lessonModules/filterguest/litems`,{lessonid:data.id}).then(
      (res)=>{
        // console.log('res',res);
        setModule(res.data.message)
      }
    )
  }, [])
  
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const closeFunc = () => {
    setOpen(false);
  };
  const openFunc = () => {
    setOpen(true);
  };
  return (
    <Grid container>
      <Login handleClose={closeFunc} open={open} toLink={data.id} lessontype={'lesson'}/>
      <Grid item lg={12} xs={12}>
        <Box
          sx={{ pb: 3, fontWeight: "700" ,color:"#1a3675"}}
        >
          Хөтөлбөр
        </Box>
      </Grid>
      <Grid item lg={12} xs={12}>
        {
          module && module.length>0 && 
          module.map((item,index)=>
          <Accordion sx={{ mb: 2 }} key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ py: 1 }}
            >
                <Box  sx={{display:"flex"}}><FileCopyIcon sx={{width:"22px",marginRight:"5px"}}/>  <span>{item.name}</span></Box>
            </AccordionSummary>
            <Box sx={{ width: "100%" ,paddingLeft:"20px"}}>
              <AccordionDetails sx={{ pt: 0 }}>
                { 
                    item.o_lesson_module_items && item.o_lesson_module_items.length>0 && item.o_lesson_module_items.map((item,indexC)=> (
                            <MenuItem onClick={openFunc} key={indexC}>
                                    <Box
                                      sx={{
                                        width: "70%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box display={"flex"}>
                                          <LockIcon sx={{width:"20px"}}/>
                                        <Box ml={2} sx={{width:"100%",md:{maxWidth:"400px"},maxWidth:"600px",overflow: "hidden",whiteSpace: "nowrap"}}>{item.title}</Box>
                                      </Box>
                                      <Box display={"flex"} alignItems="center">
                                      </Box>
                                    </Box>
                              </MenuItem>)
                    )
                }
                
              </AccordionDetails>
            </Box>
        </Accordion>)
        }
      </Grid>
    </Grid>
  );
}
