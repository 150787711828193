import React from 'react'
import '../../../utils/animate_main.scss';
export default function Spinner() {
  return (
    <div className='absolute inset-0 z-10 flex justify-center items-center bg-[#ffffff8a]'>
          <div className='rounded-full border-solid border-4 w-[50px] h-[50px] border-x-primary border-y-[#ffffff00] spinner-animate bg-[#00000000]'>
          </div>
    </div>
  )
}
