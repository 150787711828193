import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import "./utils/index.scss";
// import "./utils/_bootstrap.scss";
import "./assets/css/tailwind.css";
import "moment/locale/mn";
const main = document.getElementById('root');
const root = ReactDOM.createRoot(main);
root.render(
    <App />
);
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   main
// ); //react 17 дээр ажиллана

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
