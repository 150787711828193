import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement,Title,Tooltip,Legend,...registerables);
export default function DoughnutChart({chart_data}) {
  let labels = ['Thing 1', 'Thing 2', 'Thing 3', 'Thing 4', 'Thing 5'];
  let data1 = [1,2,3,4,5];
  if (chart_data){
                //  console.log('chart_data',chart_data);
                 labels  = [];
                  data1 = [];
                 chart_data.forEach(element => {
                      labels.push(element.country_name);
                      data1.push(element.user_cnt);
                 });
                //  console.log('labels',labels);          
            }          
 let data = {
          labels: labels,
          datasets: [
            {
              label: 'Суралцаж буй багш нар (Байршлаар топ 5)',
              data: data1,
              backgroundColor: [
                'rgba(22, 86 ,146, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(138, 143 ,148, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(95 ,139, 80, 0.2)',
              ],
              borderColor: [
                'rgba(22, 86 ,146, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(138 ,143 ,148, 0.5)',
                'rgba(75, 192, 192, 1)',
                'rgba(95 ,139, 80, 1)',
              ],
              borderWidth: 1,
            },
          ],
        };
  return (
          <div>
                <Doughnut data={data} />
          </div>
  )
}
