import React, { useEffect,  useState } from "react";
import peopleImg from '../../assets/images/peopleIcon.png'
import _ from "lodash";
import { moneyFormat } from "../../utils/helper";
import api from "../../services/api";
import moment from "moment";
import { useParams ,useLocation ,useNavigate} from "react-router-dom";
import LessonCard2 from "../../components/LessonCard/LessonCard2";
import InstructionSubGridCard from "../../components/InstructionCard/instructionSubGridCard";
import hourglassIcon from "../../assets/images/timer.png";
import days from "../../assets/images/icons8-day-24.png";
import certImg from "../../assets/images/icons8-certificate-24.png";
import priceImg from '../../assets/images/icons8-price-24.png'
import Login from "../Home/Login";
function CertificatesDetail() {
  let navigate = useNavigate();
  const paramId = useParams()
  const [lessons, setLessons] = useState([]);
  const [formState, setFormState] = useState({...formState, lessonCatId: paramId.id});
  const [certData, setCertData] = useState({});
  useEffect(() => {
    api.post(`/home/certificates`,{ ifunc : "certLessons" , ip : "" , params : {  "certid": paramId.id }})
      .then(({ data }) => {
        if (data.issuccess && data.messages && data.messages.length > 0) {
          setCertData(data.messages[0]);
          if(data.messages[0].lessons && data.messages[0].lessons.length > 0)
            {
              setLessons(data.messages[0].lessons);
            }
        } else {
          setLessons([]);
        }
      })
      .catch((err) => {
        setLessons([]);
      });
  }, []);
  const [open, setOpen] = useState(false);
  const [toLink, setTolink] = useState(false);
  const closeFunc = () => {
    setOpen(false);
  };
  const openFunc = ()=> {
    // console.log('certData.id',paramId.id);
      setTolink(paramId.id)
      setOpen(true);
  };
  var cardList =  [
    {
      "icon" : hourglassIcon , 
      "cnt" :  moment(certData.start_dat).format("YYYY-MM-DD") , 
      "name" : "Эхлэх огноо"
    },
    {
      "icon" : hourglassIcon , 
      "cnt" :  moment(certData.end_dat).format("YYYY-MM-DD") , 
      "name" : "Дуусах огноо"
    },
    {
      "icon" : days , 
      "cnt" :  certData.days , 
      "name" : "Үргэлжлэх өдөр"
    },
    {
      "icon" : certImg , 
      "cnt" :  "Олгоно" , 
      "name" : "Батламж"
    },
    {
      "icon" : priceImg , 
      "cnt" :  moneyFormat(certData.price) , 
      "name" : "Сургалтын үнэ"
    }
  ];
  return (
    <>
       <Login handleClose={closeFunc} open={open} toLink={toLink} lessontype={'category'} />
      <div className="container py-8 flex flex-col space-y-4"  >
         <div className="text-[26px] font-semibold">
            {certData.name}
         </div>     
         <div className="text-justify text-[18px] ">
                   {certData.descr}
         </div>
         <div className="flex flex-wrap gap-3">
             {
             cardList.map((data,index)=>
                (
                  <div className="w-[140px] shadow rounded bg-white p-3" key= {index} >
                    <InstructionSubGridCard paramsData = {data} key= {index} />
                  </div>
                )
              )
            }
          </div>
         <div className="flex flex-wrap space-y-4 md:space-y-0 items-center justify-between">
             <div className="flex justify-start items-center bg-[#f3f3f3] px-[20px] py-[10px] rounded text-[18px] w-full md:w-7/12">
                Сургалтанд үзэх хичээлүүд
                <span className="text-[#9e9e9e] text-[16px] font-medium flex items-center before:w-[2px] before:h-[14px] before:content-[''] before:mx-[8px] before:mt-[2px] before:inline-block before:bg-[#9e9e9e]">{lessons.length} хичээл</span>
              </div>
               <button className="w-full md:w-4/12 px-8 py-[9px] rounded bg-[#113087] text-white text-center  text-[16px] hover:bg-primary" onClick={openFunc}>
                    Сургалтанд нэгдэх
               </button>
         </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center gap-y-6" >
              {
                lessons && lessons.length > 0 ? lessons.map((data,index)=>
                   (
                    <LessonCard2 lesson = {data}  key={index}/>
                   )
                ) :
                (
                  <div className="rounded bg-white p-8">
                      Хичээл нэмэгдээгүй байна 
                  </div>
                )
              }
          </div>
      </div>
    </>
  );
}

export default CertificatesDetail;
