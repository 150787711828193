import {
  Divider,
  FormControlLabel,
  Typography,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Drawer,
  MenuItem,
  Radio,
  RadioGroup,
  InputBase ,

} from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "lodash";
import { moneyFormat } from "../../utils/helper";
import Slider from "react-slick";
import LessonCard from "../../components/LessonCard/LessonCard";
import {
  ArrowBackIos,
  ArrowForwardIos,
  CloseOutlined,
  Search,
} from "@mui/icons-material";
import { FaFilter } from "react-icons/fa";
import imgSvg from "../../assets/images/empty.svg";
import api from "../../services/api";
import { Outlet } from "react-router-dom";
import DropdownMenu from "../../components/DropdownMenu/DropdownMenu";
import Pagination from "../../components/Pagination/Pagination";
import { useParams ,useLocation ,useNavigate} from "react-router-dom";
const refLevels = [
  {
    id: 10,
    name: "10 дахь жил",
  },
  {
    id: 1,
    name: "1 дэх жил",
  },
  {
    id: 5,
    name: "5 дахь жил",
  },
];

const refStudyType = [
  {
    id: 2,
    name: "Сонголтот",
  },
  {
    id: 3,
    name: "Захиалгат",
  },
  {
    id: 4,
    name: "Төрөлжсөн",
  },
  {
    id: 1,
    name: "Үндсэн сургалт",
  },
];

const refPlanType = [
  {
    id: 1,
    name: "Зайн сургалтын агуулга",
    levelid: 1,
    study_typeid: 1,
  },
  {
    id: 3,
    name: "Орос хэлний багш",
    levelid: 5,
    study_typeid: 1,
  },
  {
    id: 5,
    name: "Мэдээллийн технологийн багш",
    levelid: 5,
    study_typeid: 1,
  },
];
const sortlist = [
  {
    id: 1,
    name: "Шинээр нэмэгдсэн",
  },
  {
    id: 2,
    name: "Хамгийн их үнэлгээ авсан",
  },
  {
    id: 3,
    name: "Хамгийн их үзэлттэй",
  },
  {
    id: 4,
    name: "Хайлттай хамаарал өндөртэй",
  },
];
function CoursesSearch() {
  let navigate = useNavigate();
  const [filterOpen, setFilterOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [newCourses, setNewCourses] = useState([]);
  const [formState, setFormState] = useState({});
  const [lessonCategory, setLessonCategory] = useState([]);
  const [filterShow, setFilterShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCourse, setTotalCourse] = useState([]);
  const [limit, setLimit] = useState(10);
  let totalPage = Math.ceil(totalCourse.length/limit);
  let array = [];
  useEffect(() => {
    api
      .get(`/lesson/findguest`)
      .then(({ data }) => {
        if (data.success && data.message && data.message.length) {
          // data.message.map(
          //   (e)=>console.log('setNewCourses',e.img_url ? e.img_url :'')
          // )
          setNewCourses(data.message);
        } else {
          setNewCourses([]);
        }
      })
      .catch((err) => {
        setNewCourses([]);
      });
      getLessonCategory();
  }, []);

  useEffect(() => {
        setCurrentPage(1)
        searchCourse();
  }, [formState]);
  const callBackPagination = (value) => {
    // console.log('currentPage',currentPage);
      if(totalCourse.length>limit){
        array =[];
        let counter;
        if(value === "prev"){
          if(currentPage !== 1){
              setCurrentPage(currentPage-1)
              counter = currentPage-1
              window.scrollTo(0, 0);
          }else{
            counter = 1
          }
        }else if (value === "next"){
          if(currentPage !== totalPage){
            setCurrentPage(currentPage+1)
            counter = currentPage+1
            window.scrollTo(0, 0);
        }else{
          counter = currentPage
        }
        }else{
          setCurrentPage(value)
          counter = value;
          window.scrollTo(0, 0);
        }
        // console.log('counter',counter);
        for (let i = (counter - 1)* limit; i < (counter*limit); i++) {
          if(totalCourse[i]){
            array.push(totalCourse[i]);
          }

        }
        setCourses(array);
      }
    }
  const searchCourse = () => {
    api.post(`/lesson/searchguest`, {
        ...formState,
      }).then(({ data }) => {
        array =[];
        if (data.lessons && data.lessons.length) {
          setTotalCourse(data.lessons)
         if(data.lessons.length> limit){
          for (let i = 0; i < limit; i++) {
            array.push(data.lessons[i]);
          }
         }else{
          array = data.lessons;
         }
          setCourses(array);
        } else {
          setCourses([]);
        }
      })
      .catch((err) => {
        setCourses([]);
      });
  };
  const getLessonCategory = () => {
    api.post("home/certificates",{
      ifunc:'songoltotCertificates',
      ip:"",
      params:{
          "id":1
      }
  }).then((res) => {
    // console.log('songoltotCertificates',res);
      setLessonCategory(res.data.messages);  
      
  });
};
  const styleBtnFilter = {
    border: "1px solid #1a3675",
    background: "#ebf4ff",
    height: 56,
    width: 56,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    cursor:'pointer',
    mr: 1,
  };
  const styleBtnFilterXs =  {...styleBtnFilter, display: {xs: "flex", md: "none",}}
  const styleBtnFilterMd =  {...styleBtnFilter, display: {xs: "none", md: "flex",}}
  return (
    <>
      <div className="container py-12"  >
        <div className="flex mb-4 md:space-x-8 flex-wrap md:flex-nowrap">
          <div className="w-full  md:w-3/12">
            <Box sx={{ display: "flex" }}>
               <Box sx={styleBtnFilterMd} onClick={()=>(setFilterShow(!filterShow))}>
                        <FaFilter />
                      </Box>
              <Box sx={styleBtnFilterXs} onClick={() => setFilterOpen(true)} className='mb-3'>
                <FaFilter />
              </Box>
               <DropdownMenu data ={sortlist}/>
            </Box>
          </div>
          <div className="w-full  sm:w-9/12" >
            <Box sx={{ display: "flex" }}>
              <FormControl variant="outlined" fullWidth hiddenLabel={true}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Хайх
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  // value={values.password}
                  // onChange={handleChange('password')}
                  // hidden

                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      setFormState({
                        ...formState,
                        name: e.target.value,
                      });
                      // searchCourse(e.target.value);
                    }
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        // onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="start"
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Хайх"
                  placeholder="Хичээлийн нэр"
                />
              </FormControl>
            </Box>
          </div>
        </div>
        <div className={filterShow ?"flex md:space-x-8 flex-wrap md:flex-nowrap":"flex flex-wrap md:flex-nowrap"}>
          <div className={filterShow ?"w-3/12 hidden md:inline":"hidden"}>
            <Divider sx={{borderColor: "#000", mt: 1.5,}}/>
            <Accordion>
              <AccordionSummary

                expandIcon={<ExpandMoreIcon  />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h7" style={{fontWeight:'bold'}}>
                Сургалтын түвшин 
                </Typography>
              </AccordionSummary>
              <Box sx={{ width: "100%" }}>
                <AccordionDetails sx={{ pt: 0 }}>
                <RadioGroup
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      levelid: e.target.value,
                    });
                  }}
                >
                  {refLevels.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      value={item.id}
                      control={<Radio />}
                      label={item.name}
                    />
                  ))}
                  <FormControlLabel value={""} control={<Radio />} label={"Бүгд"} />
                </RadioGroup>
                </AccordionDetails>
              </Box>
            </Accordion>
            <Divider sx={{borderColor: "#000",mt: 1.5,}}/>
            <Accordion >
              <AccordionSummary
              
                expandIcon={<ExpandMoreIcon  />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h7" style={{fontWeight:'bold'}}>
                Сургалтын төрөл 
                </Typography>
              </AccordionSummary>
              <Box sx={{ width: "100%" }}>
                <AccordionDetails sx={{ pt: 0 }}>
                <RadioGroup
                  sx={{ mt: 2 }}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      studytype: e.target.value,
                    });
                  }}
                >
                  {refStudyType.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      value={item.id}
                      control={<Radio />}
                      label={item.name}
                    />
                  ))}
                  <FormControlLabel value={""} control={<Radio />} label={"Бүгд"} />
                </RadioGroup>
                </AccordionDetails>
              </Box>
            </Accordion>
            <Divider sx={{borderColor: "#000",mt: 1.5,}}/>
            <Accordion >
              <AccordionSummary
              
                expandIcon={<ExpandMoreIcon  />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h7" style={{fontWeight:'bold'}}>
                Хичээлийн ангилал
                </Typography>
              </AccordionSummary>
              <Box sx={{ width: "100%" }}>
                <AccordionDetails sx={{ pt: 0 }}>
                <RadioGroup
                  sx={{ mt: 2 }}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      lessonCatId: e.target.value,
                    });
                  }}
                >
                  {lessonCategory.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      value={item.id}
                      control={<Radio />}
                      label={item.name}
                    />
                  ))}
                  <FormControlLabel value={""} control={<Radio />} label={"Бүгд"} />
                </RadioGroup>
                </AccordionDetails>
              </Box>
            </Accordion>
          </div>
          <div className={filterShow ?"w-full lg:w-9/12":"w-full lg:w-full"} >
            <div>
              {courses && courses.length > 0 ? (
                courses.map((item,index) => (
                  <div  key={index} style={{cursor:'pointer'}}  onClick={() => { navigate(`/main/courses/detail/${item.id}`, {state: {  data: item,},  }); }}>
                  <Box style={{ display: "flex" }}>
                      <Box
                        sx={{
                          backgroundImage: `url(${item.img_url})`,
                          backgroundColor: "#ddd",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          height: {
                            xs: 66,
                            md: 130,
                          },
                          width: {
                            xs: 66,
                            md: 200,
                          },
                        }}
                      ></Box>
                      <Box sx={{ ml: 3, flex: 1 }}>
                        <Box>
                          <Typography style={{fontWeight:"bold",fontSize: 18}}>{item.name}</Typography>
                          <Typography sx={{ mt: 1 ,fontSize: 16}}>
                            {_.truncate(item.aguulga, {
                              length: 100,
                            })}
                          </Typography>
                          <Typography
                            color="text.secondary"
                            sx={{ mt: 1 ,fontSize: 16 }}
                          >
                            {item.teacher_descr
                              ? item.teacher_descr
                              : "Багш: Др. Б.Батбаяр, Проф С.Мөнхзаяа"}
                          </Typography>
                          <Typography   sx={{
                              fontSize: 16,
                                fontWeight:'bold'
                            }} color="text.secondary">
                            {` ${item.credit ? item.credit : "-"} кредит`}
                          </Typography>
                          <Typography
                            sx={{
                              display: {
                                xs: "inline",
                                md: "none",
                              },
                              fontSize: 18,
                                fontWeight:'bold'
                            }}
                          >
                            {moneyFormat(item.price)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          ml: 2,
                          textAlign: "end",
                          display: {
                            xs: "none",
                            md: "inline",
                          }
                        }}
                      >
                        <Typography style={{ fontSize: 18,
                          fontWeight:'bold'}}>
                          {moneyFormat(item.price)}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ my: 1.5 , borderColor:'#9c9595' }} />
                  </div>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={imgSvg} alt="imgsvg" width="25%"></img>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Мэдээлэл олдсонгүй
                  </Typography>
                </Box>
              )}
            </div>
            {
               courses && courses.length>0 && (
                  <Box sx={{display:'flex', justifyContent:"left",marginTop: '30px'}}>
                    <Pagination callBackFunc={callBackPagination} totalPage={totalPage} currentPage={currentPage}/>
                </Box>
               )
            }
            {/* {newCourses && newCourses.length > 0 && (
              <div>
                <Box sx={{ display: "flex", mt: 3, mb: 2 }}>
                  <Typography variant="h5" sx={{ my: 1, flex: 1 }}>
                    Шинээр нэмэгдсэн хичээлүүд
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <IconButton onClick={() => slickRef.current.slickPrev()}>
                      <ArrowBackIos />
                    </IconButton>
                    <IconButton onClick={() => slickRef.current.slickNext()}>
                      <ArrowForwardIos />
                    </IconButton>
                  </Box>
                </Box>
               <div className="row">
               {newCourses.slice(0,10).map((item) => (
                    <div className="col-xs-12 col-md-6 col-lg-4" key={item.id}>
                      <LessonCard key={item.id} data={item} banner="NEW" />
                    </div>
                  ))}
               </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <Drawer
        anchor="left"
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={() => setFilterOpen(false)}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Divider
            sx={{
              borderColor: "#000",
              mb: 1.5,
            }}
          />
          <Typography variant="h6">Сургалтын түвшин</Typography>
          <RadioGroup
            sx={{ mt: 2 }}
            onChange={(e) => {
              setFormState({
                ...formState,
                levelid: e.target.value,
              });
            }}
          >
            {refLevels.map((item) => (
              <FormControlLabel
                key={item.id}
                value={item.id}
                control={<Radio />}
                label={item.name}
              />
            ))}
            <FormControlLabel value={""} control={<Radio />} label={"Бүгд"} />
          </RadioGroup>

          <Divider
            sx={{
              borderColor: "#000",
              my: 1.5,
            }}
          />
          <Typography variant="h6">Сургалтын төрөл</Typography>
          <RadioGroup
            sx={{ mt: 2 }}
            onChange={(e) => {
              setFormState({
                ...formState,
                studytype: e.target.value,
              });
            }}
          >
            {refStudyType.map((item) => (
              <FormControlLabel
                key={item.id}
                value={item.id}
                control={<Radio />}
                label={item.name}
              />
            ))}
            <FormControlLabel value={""} control={<Radio />} label={"Бүгд"} />
          </RadioGroup>

          <Divider
            sx={{
              borderColor: "#000",
              my: 1.5,
            }}
          />
          <Typography variant="h6">Хичээлийн ангилал </Typography>
          <RadioGroup
            sx={{ mt: 2 }}
            onChange={(e) => {
              setFormState({
                ...formState,
                lessonCatId: e.target.value,
              });
            }}
          >
            {lessonCategory.map((item) => (
              <FormControlLabel
                key={item.id}
                value={item.id}
                control={<Radio />}
                label={item.name}
              />
            ))}
            <FormControlLabel value={""} control={<Radio />} label={"Бүгд"} />
          </RadioGroup>
        </Box>
      </Drawer>
    </>
  );
}

export default CoursesSearch;
