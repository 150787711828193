import React from 'react'
import { Bar } from 'react-chartjs-2';
import {
     Chart as ChartJS,
     CategoryScale,
     LinearScale,
     PointElement,
     LineElement,
     Title,
     Tooltip,
     Legend,
     registerables 
   } from 'chart.js';
   ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement,Title,Tooltip,Legend,...registerables);
export default function BarChart({chart_data}) {
          const option = {
                    responsive: true,
                    plugins: {
                              legend: {
                              position: 'top',
                              },
                              title: {
                              display: true,
                              text: 'ESIS эрхээр нэвтэрсэн багш нар',
                              },
                    },
                    };
                            
         
          let labels = ['1 сар', '2 сар', '3 сар', '4 сар', '5 сар', '6 сар', '7 сар'];
          let data1,data2 = [1,2,3,4,5,6,7];
          if (chart_data){
               // console.log('chart_data',chart_data);
               labels  = [];
                data1 = [];
                data2 = [];
               chart_data.forEach(element => {
                    labels.push(element.dat);
                    data1.push(element.is_esis_login);
                    data2.push(element.is_mail_login);
               });
               // console.log('labels',labels);          
          }
          let data = {
          labels,
          datasets: [
                    {
                         label: 'ESIS эрхээр',
                         data: data1,
                         borderColor: 'rgb(22, 86 ,146)',
                         backgroundColor: 'rgba(22, 86 ,146, 0.5)',
                    },
                    {
                         label: 'Мэйл хаягаар',
                         data: data2,
                         borderColor: 'rgb(71, 80 ,204)',
                         backgroundColor: 'rgba(71, 80 ,204, 0.5)',
                    },
          ],
          };
  return (
          <div className="">
                    <Bar options={option} data={data} />
          </div>
  )
}
