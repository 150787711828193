import {
          Divider,
          FormControlLabel,
          Typography,
          Box,
          IconButton,
          Drawer,
          Radio,
          RadioGroup,
} from "@mui/material";
import React, { useEffect,  useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import _ from "lodash";
import { moneyFormat } from "../../../utils/helper";
import {
CloseOutlined,
} from "@mui/icons-material";
import { FaFilter } from "react-icons/fa";
import imgSvg from "../../../assets/images/empty.svg";
import api from "../../../services/api";
import DropdownMenu from "../../../components/DropdownMenu/DropdownMenu";
import { useParams ,useLocation ,useNavigate} from "react-router-dom";
import TabList from "../../../components/TabList/TabList";
        const refLevels = [
          {
            id: 10,
            name: "10 дахь жил",
          },
          {
            id: 1,
            name: "1 дэх жил",
          },
          {
            id: 5,
            name: "5 дахь жил",
          },
        ];
        const refStudyType = [
          {
            id: 2,
            name: "Сонголтот",
          },
          {
            id: 3,
            name: "Захиалгат",
          },
          {
            id: 4,
            name: "Төрөлжсөн",
          },
          {
            id: 1,
            name: "Үндсэн сургалт",
          },
        ];
        const refPlanType = [
          {
            id: 1,
            name: "Зайн сургалтын агуулга",
            levelid: 1,
            study_typeid: 1,
          },
          {
            id: 3,
            name: "Орос хэлний багш",
            levelid: 5,
            study_typeid: 1,
          },
          {
            id: 5,
            name: "Мэдээллийн технологийн багш",
            levelid: 5,
            study_typeid: 1,
          },
        ];
        const sortlist = [
          {
            id: 1,
            name: "Шинээр нэмэгдсэн",
          },
          {
            id: 2,
            name: "Хамгийн их үнэлгээ авсан",
          },
          {
            id: 3,
            name: "Хамгийн их үзэлттэй",
          },
          {
            id: 4,
            name: "Хайлттай хамаарал өндөртэй",
          },
        ];
        function CoursesLesson() {
          let navigate = useNavigate();
          const paramId = useParams()
          const location = useLocation(); // Get location object
          // const state = location.state || {}; // Ensure state exists
          // const { dataCategory } = state;
          const postData = {catid : paramId.id}
          const [filterOpen, setFilterOpen] = useState(false);
          const [filterShow, setFilterShow] = useState(true);
          const [courses, setCourses] = useState([]);
          const [newCourses, setNewCourses] = useState([]);
          const [formState, setFormState] = useState({...formState, lessonCatId: paramId.id});
          useEffect(() => {
            api.post(`/lesson/categories`,postData)
              .then(({ data }) => {
                if (data.success && data.message && data.message.length) {
                  // const groups = [];
                  // const groupSize = 4;
                  // for (let i = 0; i < data.message.length; i += groupSize) {
                  //                     const group = data.message.slice(i, i + groupSize);
                  //                     groups.push(group);
                  //            }
                  setNewCourses(data.message);
                } else {
                  setNewCourses([]);
                }
              })
              .catch((err) => {
                setNewCourses([]);
              });
          }, []);
        
          useEffect(() => {
             searchCourse();
          }, [formState]);
          
          
          const searchCourse = () => {
            // console.log("form state: ", formState);
            api
              .post(`/lesson/searchguest`, {
                // name: name,
                ...formState,
              })
              .then(({ data }) => {
                if (data.lessons && data.lessons.length) {
                //  console.log("search res: ", data.lessons);
                  setCourses(data.lessons);
                } else {
                  setCourses([]);
                }
              })
              .catch((err) => {
                setCourses([]);
              });
          };
        
          const styleBtnFilter = {
            border: "1px solid #1a3675",
            background: "#ebf4ff",
            height: 56,
            width: 56,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            cursor:'pointer',
            mr: 1,
          };
          const styleLessonHeader ={
                     display:'flex',
                     justifyContent:'left',
                     alignItems:'center',
                     backgroundColor:'#f3f3f3',
                     padding: '15px',
                     borderRadius:'7px',
                     fontSize: '18px'

          }
          const callBackTab = (index) => {
                    // console.log("child element ",index);
                   
                  };
          const styleBtnFilterXs =  {...styleBtnFilter, display: {xs: "flex", md: "none",}}
          const styleBtnFilterMd =  {...styleBtnFilter, display: {xs: "none", md: "flex",}}
          const sideBarStyle ={ fontSize: '18px', fontWeight:'bold'  }
          return (
            <>
              <div className="container py-5"  >
               {newCourses && newCourses.length>0 &&
                 <div>
                  {/* {dataCategory && dataCategory.name &&
                    <Typography variant="h5">
                              {dataCategory.name}  · хичээлүүд
                    </Typography>
                  } */}
                   {/* <div sx={{fontSize:'18px' , color:'#12204b'}} className='mt-1'>
                          <div style={{display:'flex' ,alignItems:'flex-end'}}>
                               <PeopleAltIcon></PeopleAltIcon>    <span style={{ lineHeight:'24px',marginLeft:'8px'}}>54 суралцагч</span>
                          </div>
                   </div> */}
                   <TabList groups ={newCourses} callbackchild={callBackTab}/>
                  </div>     
               }
               
                <div className="flex flex-wrap md:flex-nowrap md:space-x-8 mb-4">
                  <div className="w-full md:w-3/12">
                    <Box sx={{ display: "flex" }}>
                      <Box sx={styleBtnFilterMd} onClick={()=>(setFilterShow(!filterShow))}>
                        <FaFilter />
                      </Box>
                      <Box sx={styleBtnFilterXs} onClick={()=>(setFilterOpen(true))} className='mb-3'>
                        <FaFilter />
                      </Box>
                       <DropdownMenu data ={sortlist}/>
                    </Box>
                  </div>
        
                  <div className="w-full md:w-9/12" >
                    <Box sx={styleLessonHeader}>
                      <span className="text-[#9e9e9e] text-[16px] font-medium flex items-center before:w-[2px] before:h-[14px] before:content-[''] before:mx-[8px] before:mt-[2px] before:inline-block before:bg-[#9e9e9e]">{courses.length} хичээл</span>
                    </Box>
                  </div>
                </div>
                <div className={filterShow ?"flex md:space-x-8 flex-wrap md:flex-nowrap":"flex flex-wrap md:flex-nowrap"}>
                  <div className={filterShow ?"w-3/12 hidden md:inline":"hidden"}>
                        <Divider sx={{borderColor: "#000", mt: 1.5,}}/>
                        <Accordion>
                          <AccordionSummary
                
                            expandIcon={<ExpandMoreIcon  />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h7" style={{fontWeight:'bold'}}>
                            Сургалтын түвшин 
                            </Typography>
                          </AccordionSummary>
                          <Box sx={{ width: "100%" }}>
                            <AccordionDetails sx={{ pt: 0 }}>
                            <RadioGroup
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  levelid: e.target.value,
                                });
                              }}
                            >
                              {refLevels.map((item) => (
                                <FormControlLabel
                                  key={item.id}
                                  value={item.id}
                                  control={<Radio />}
                                  label={item.name}
                                />
                              ))}
                              <FormControlLabel value={""} control={<Radio />} label={"Бүгд"} />
                            </RadioGroup>
                            </AccordionDetails>
                          </Box>
                        </Accordion>
                        <Divider sx={{borderColor: "#000",mt: 1.5,}}/>
                        <Accordion >
                          <AccordionSummary
                          
                            expandIcon={<ExpandMoreIcon  />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h7" style={{fontWeight:'bold'}}>
                            Сургалтын төрөл 
                            </Typography>
                          </AccordionSummary>
                          <Box sx={{ width: "100%" }}>
                            <AccordionDetails sx={{ pt: 0 }}>
                            <RadioGroup
                              sx={{ mt: 2 }}
                              onChange={(e) => {
                                setFormState({
                                  ...formState,
                                  studytype: e.target.value,
                                });
                              }}
                            >
                              {refStudyType.map((item) => (
                                <FormControlLabel
                                  key={item.id}
                                  value={item.id}
                                  control={<Radio />}
                                  label={item.name}
                                />
                              ))}
                              <FormControlLabel value={""} control={<Radio />} label={"Бүгд"} />
                            </RadioGroup>
                            </AccordionDetails>
                          </Box>
                        </Accordion>
                  </div>
                  <div className={filterShow ?"w-full lg:w-9/12":"w-full lg:w-full"} style={{cursor:'pointer'}}>
                      <div>
                        {
                        courses && courses.length > 0 ? (
                          courses.map((item,index) => (
                            <div  key={index}>
                               <Box style={{ display: "flex" }} 
                                    onClick={() => { navigate(`/main/courses/detail/${item.id}`, {state: {  data: item,},  }); }}>
                                      <Box
                                        sx={{
                                          backgroundImage: `url(${item.img_url})`,
                                          backgroundColor: "#ddd",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundRepeat: "no-repeat",
                                          height: {
                                            xs: 66,
                                            md: 130,
                                          },
                                          width: {
                                            xs: 66,
                                            md: 200,
                                          },
                                        }}>
                                      </Box>
                                      <Box sx={{ ml: 3, flex: 1 }}>
                                        <Box>
                                          <Typography style={{fontWeight:"bold",fontSize: 18}}>{item.name}</Typography>
                                          <Typography sx={{ mt: 1 ,fontSize: 16}}>
                                            {_.truncate(item.aguulga, {
                                              length: 100,
                                            })}
                                          </Typography>
                                          <Typography
                                            color="text.secondary"
                                            sx={{ mt: 1 ,fontSize: 16 }}
                                          >
                                            {item.teacher_descr
                                              ? item.teacher_descr
                                              : "Багш: Др. Б.Батбаяр, Проф С.Мөнхзаяа"}
                                          </Typography>
                                          <Typography   sx={{
                                              fontSize: 16,
                                                fontWeight:'bold'
                                            }} color="text.secondary">
                                            {` ${item.credit ? item.credit : "-"} кредит`}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              display: {
                                                xs: "inline",
                                                md: "none",
                                              },
                                              fontSize: 18,
                                                fontWeight:'bold'
                                            }}
                                          >
                                            {moneyFormat(item.price)}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          ml: 2,
                                          textAlign: "end",
                                          display: {
                                            xs: "none",
                                            md: "inline",
                                          }
                                        }}
                                      >
                                        <Typography style={{ fontSize: 18,
                                          fontWeight:'bold'}}>
                                          {moneyFormat(item.price)}
                                        </Typography>
                                      </Box>
                               </Box>
                               <Divider sx={{ my: 1.5 , borderColor:'#9c9595' }} />
                            </div>
                          ))
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img src={imgSvg} alt="imgsvg" width="25%"></img>
                            <Typography variant="h6" sx={{ mt: 2 }}>
                              Мэдээлэл олдсонгүй
                            </Typography>
                          </Box>
                        )
                        }
                      </div>
                  </div>
                </div>
              </div>
              <Drawer
                anchor="left"
                open={filterOpen}
                onClose={() => setFilterOpen(false)}
              >
                <Box sx={{ p: 5 }}>
                  <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
                    <IconButton onClick={() => setFilterOpen(false)}>
                      <CloseOutlined />
                    </IconButton>
                  </Box>
                  <Divider
                    sx={{
                      borderColor: "#000",
                      mb: 1.5,
                    }}
                  />
                  <Typography sx={sideBarStyle}>Сургалтын түвшин</Typography>
                  <RadioGroup
                    sx={{ mt: 2}}
                    onChange={(e) => {
                      setFormState({
                        ...formState,
                        levelid: e.target.value,
                      });
                    }}
                  >
                    {refLevels.map((item) => (
                      <FormControlLabel
                        key={item.id}
                        value={item.id}
                        control={<Radio />}
                        label={item.name}
                        style={{fontSize: '15px'}}
                      />
                    ))}
                    <FormControlLabel value={""} control={<Radio />} label={"Бүгд"} />
                  </RadioGroup>
        
                  <Divider
                    sx={{
                      borderColor: "#000",
                      my: 1.5,
                    }}
                  />
                  <Typography sx={sideBarStyle}>Сургалтын төрөл</Typography>
                  <RadioGroup
                    sx={{ mt: 2 }}
                    onChange={(e) => {
                      setFormState({
                        ...formState,
                        studytype: e.target.value,
                      });
                    }}
                  >
                    {refStudyType.map((item) => (
                      <FormControlLabel
                        key={item.id}
                        value={item.id}
                        control={<Radio />}
                        label={item.name}
                      />
                    ))}
                    <FormControlLabel value={""} control={<Radio />} label={"Бүгд"} />
                  </RadioGroup>
        
                  <Divider
                    sx={{
                      borderColor: "#000",
                      my: 1.5,
                    }}
                  />
                </Box>
              </Drawer>
            </>
          );
        }
        
        export default CoursesLesson;
        