import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { GiHamburgerMenu } from "react-icons/gi";
import SideBar from "../SideBar/SideBar";
import {  NavLink  } from "react-router-dom";
import logo from "../../assets/images/mn-logo.svg";
import Login from "../../pages/Home/Login";
import SignUpModal from "../../pages/Home/SignUp";
import {
  TextField,
  Popover,
  Paper,
} from "@mui/material";

export default function Header() {
  const [isSideBar, setIsSideBar] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  // const openLoginModal = () => setIsLoginOpen(true);
  const closeLoginModal = () => setIsLoginOpen(false);
  const closeSignUpModal = (e) => {
    // console.log('close modal event ' ,e);
    setIsSignUpModalOpen(false)
     e == 1 && setIsLoginOpen(true);
  };
  const openSignUpModal = () => setIsSignUpModalOpen(true);
  const openLoginModal = () => {
    setIsLoginOpen(!isLoginOpen);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const headerStyle = 'h-full flex justify-center items-center p-[4px] cursor-pointer text-[#95a2ba] text-[12px] uppercase relative mr-[16px] hover:text-[#ffd002] before:flex before:justify-start before:w-[0%] before:bottom-[25px] before:h-[3px] before:rounded-[16px] before:absolute before:bg-[#ffd002] before:content-[""] before:transition-all before:duration-[400ms] l hover:before:w-full no-underline'
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className="sticky top-0 z-[1000] shadow-lg bg-[#113087] opacity-[.97]">
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper sx={{ p: 2 }}>
          <TextField label="Хичээл хайх..." />
        </Paper>
      </Popover>
      {!isMobile ? (
        <div className="container m-auto w-full h-[90px] text-sm font-semibold sticky flex items-center">
          <div className="font-bold flex justify-start items-center">
            <img width={60} height={60} src={logo} alt="logo"/>
            <NavLink  to="/main/home"><span className="text-[16px] text-white ml-[8px] whitespace-nowrap uppercase"> Багшийн хөгжил</span></NavLink>
          </div>
          <div className="flex  justify-between w-full h-full ml-[70px]">
            <div className="text-[#95a2ba] h-full flex  justify-around">
              <NavLink  className={headerStyle} to="/">
                ЭХЛЭЛ
              </NavLink>
              <NavLink   className={({ isActive }) => (isActive ? headerStyle+" text-[#ffd002]" : headerStyle)}    to="/main/courses/search">
              ХИЧЭЭЛҮҮД
              </NavLink>
              {/* <div className={headerStyle}>ХӨТӨЛБӨРҮҮД</div> */}
              {/* <div className="header-menu-item nav-link-ltr">ҮНИЙН САНАЛ</div>
              <div className="header-menu-item nav-link-ltr">БАГШ</div> */}
            </div>

            <div className="pr-[24px] w-[30%] flex items-end pt-4 pb-4">
              {/* <IconButton component={Link} to="/main/courses/search">
                <AiOutlineSearch color="#fff" />
              </IconButton> */}

              {/* <div className="header-btn-divider me-3"></div> */}
              <button
                className="text-white mr-5 leading-[24px] h-[41px]  active:ring-1 active:ring-violet-100 active:outline-none rounded px-2"
                onClick={openLoginModal}>
                НЭВТРЭХ
              </button>
              <button onClick={openSignUpModal} className="bg-white rounded text-blue-800 px-2 leading-[24px] h-[41px]  hover:bg-[#d9d9d9] border-[#cccccc] transition ease-in-out delay-100">
                БҮРТГҮҮЛЭХ
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="container px-[40px] mx-auto w-full h-[90px] text-sm font-semibold sticky flex items-center justify-between">
          <SideBar isSideBar={isSideBar} close={() => setIsSideBar(false) } signupModalShow={openSignUpModal} />
          <div className="text-white">
            <GiHamburgerMenu size={24} onClick={() => setIsSideBar(true)} />
          </div>
          <div>
            <button  onClick={openLoginModal} className="bg-white rounded px-2 leading-[24px] h-[41px] hover:bg-[#d9d9d9] border-[#cccccc] transition ease-in-out delay-100 text-primary">НЭВТРЭХ</button>
          </div>
        </div>
      )}
       <SignUpModal closeModal={closeSignUpModal} modalIsOpen={isSignUpModalOpen} showLoginModal={isLoginOpen} />  
      <Login handleClose={closeLoginModal} open={isLoginOpen} toLink={''} lessontype={''}/>
    </div>
  );
}
