import { IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SVG from 'react-inlinesvg';
import ReplayIcon from '@mui/icons-material/Replay';
import { ToastContainer, toast } from 'material-react-toastify';
import api from "../../services/api";
import Spinner from "../base/Spinner/Spinner";
export default function ForgetPassword({closeForgetPass , captcha ,refreshCaptcha ,createdAt}){
  const [userData, setUserData] = useState({ createAt: null , email: "",  captchaText: "", key:""});
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorCaptcha, setErrorCaptcha] = useState(false);
  const [validEmail, setValidEmail] = useState(false);  
  const [isStartEmail, setIsStartEmail] = useState(false);  
  const [pageNumber, setPageNumber] = useState(1);  
  const [errorKey, setErrorKey] = useState(false);  
  const [animateClassName ,setAnimateClassName] = useState('right-to-left-animate');
  const [pending ,setPending] = useState(false);
  
    useEffect(() => {
      setUserData({...userData, createAt: createdAt });
    }, [createdAt])

    const callEmailConfirm = () =>{
      setPending(true);
        api.post("user/forgetpass",{
          ifunc:'forgetpass',
          ip:"",
          params:{
              "email": userData.email
          },
          createAt:userData.createAt,
          text : userData.captchaText
      }).then((res) => {
          if(res.data.issuccess == 1){
            setPageNumber(2);
            setUserData({...userData, captchaText: "" });
            refreshCaptcha();
            setAnimateClassName('left-to-right-animate');
            toast.success('Таны мэйл хаягт баталгаажуулах код илгээлээ');
            // axios.defaults.headers.common['Authorization'] = res.data.accessToken;
            api.interceptors.request.use(function (config) {
                  config.headers.Authorization =  res.data.accessToken;
                  return config;
              });
          }else{
            toast.error('Мэйл хаяг олдсонгүй')
          }
          setPending(false);
      }).catch(
        (error)=>{
            let { status } = error.response;
            // console.log('err',error.response);
            if(status == 401){
               toast.error(error.response.data.error)
            }
            setPending(false);
        }
      );
    }

    const callEmailKeyValid = () =>{
      setPending(true);
      api.post("user/emailconfirm",{
        ifunc:'emailconfirm',
        ip:"",
        params:{
            "email": userData.email,
            "key": userData.key
        }
    }).then((res) => {
        if(res.data.issuccess == 1){
          toast.success('Таны мэйл хаягт нэвтрэх нууц үг илгээлээ');
          closeForgetPass()
        }else{
          toast.error('Баталгаажуулах код буруу байна')
        }
        setPending(false);
    }).catch(
      (error)=>{
          // console.log('err',error.response);
          if(error.response.data.message){
            toast.error('Токен буруу байна')
          }else if (error.response.data.error){
            toast.error(error.response.data.error)
          }
          setPending(false);
      }
    );
  }
  const handleChange = e => {
     if(isStartEmail){
        if(e.currentTarget.id == 'email'){
            e.currentTarget.value == '' ? setErrorEmail(true) : setErrorEmail(false);
            !validateEmail(e.currentTarget.value)  ? setValidEmail(true) : setValidEmail(false);
        }else if(e.currentTarget.id == 'captchaText'){
             e.currentTarget.value == '' ? setErrorCaptcha(true) : setErrorCaptcha(false);
        }
     }
     if(e.currentTarget.id == 'key'){
          e.currentTarget.value == '' ? setErrorKey(true) : setErrorKey(false);
      }
    setUserData({...userData, [e.currentTarget.id]: e.currentTarget.value });
  };
  const submitContinious = () => {
      userData.email == '' ? setErrorEmail(true) : setErrorEmail(false);
      !validateEmail(userData.email) ? setValidEmail(true) : setValidEmail(false);
      userData.captchaText == '' ? setErrorCaptcha(true) : setErrorCaptcha(false);
      setIsStartEmail(true);
      if(userData.email == '' || !validateEmail(userData.email) ||  userData.captchaText == '') return;
      callEmailConfirm();
  };

  const confirmEmailKey = () => {
    userData.key == '' ? setErrorKey(true) : setErrorKey(false);
    
    if(userData.key == '') return;
    callEmailKeyValid();
};
  const backHandle = ()=>{
    if(pageNumber == 1){
      
      setAnimateClassName('right-to-left-animate');
      closeForgetPass();
     }else{
      setPageNumber(1) ;
     }
  }
  const emailHelperText = errorEmail ? "Мэйлээ оруулна уу" : validEmail ? 'Мэйлээ зөв оруулна уу' : '';
  const captchaHelperText = errorCaptcha ? "Зурган дээрх нийлбэрийг оруулна уу" : '';
  const validateEmail = (email) => {
    return email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
  };
      return (
          <> 
              <ToastContainer position="top-center"/>
              {
                pageNumber == 1 && 
                <div className={"flex flex-col items-start w-[340px] "+animateClassName}>
                    {pending && <Spinner></Spinner>} 
                    <h5 className="my-3">Нууц үг сэргээх</h5>
                    <div className="text-[14px] mb-2">Та бүртгэлтэй мэйл хаяг оруулна уу</div>             
                    <div className="mb-[1rem] w-full">
                    <TextField label="Мэйл" fullWidth id="email" onChange={handleChange} value = {userData.email} error={errorEmail || validEmail} helperText={emailHelperText}/>
                    </div>
                    <div className="flex justify-start items-center w-full mb-[1rem]">
                      <SVG src={captcha} className="mr-2" />
                      <IconButton aria-label="refresh captcha"  edge="end" onClick={refreshCaptcha}>
                              <ReplayIcon />
                      </IconButton>
                    </div>
                    <div className="mb-[2rem] w-full">
                        <TextField label="Нийлбэрийг оруулна уу" fullWidth id="captchaText" value={userData.captchaText} onChange={handleChange} error={errorCaptcha} helperText={captchaHelperText}/>
                    </div>
                       <button className="w-full h-[50px] rounded bg-primary text-white mb-4" onClick={submitContinious}>
                       Үргэлжлүүлэх
                      </button> 
                    <button className="w-full h-[50px] rounded text-primary border" onClick={backHandle}>
                              Буцах
                    </button>              
                </div>
               
              }
              {
                pageNumber == 2 &&
                <div className="flex flex-col items-start w-[340px] right-to-left-animate"> 
                    <h5 className="my-2">Нууц үг сэргээх</h5>
                    <div className="text-[14px] mb-3 leading-[15px]">  
                        Таны <span className="font-bold"> { userData.email } </span>  хаягт илгээсэн баталгаажуулах кодыг оруулна уу.
                    </div>            
                        <TextField label="Баталгаажуулах код" fullWidth id="key" onChange={handleChange} value = {userData.key} error={errorKey} helperText={errorKey ? 'Баталгаажуулах код оруулна уу' : ''}/>
                    {/* <div className="flex justify-start items-center w-full mb-[1rem]">
                      <SVG src={captcha} className="mr-2" />
                      <IconButton aria-label="refresh captcha"  edge="end" onClick={refreshCaptcha}>
                              <ReplayIcon />
                      </IconButton>
                    </div>
                    <div className="mb-[2rem] w-full">
                        <TextField label="Нийлбэрийг оруулна уу" fullWidth id="captchaText" value={userData.captchaText} onChange={handleChange} error={errorCaptcha} helperText={captchaHelperText}/>
                    </div> */}
                      <button className="w-full h-[50px] rounded bg-primary text-white mb-4 mt-[5rem]" onClick={confirmEmailKey}>
                                Баталгаажуулах
                      </button>  
                    <button className="w-full h-[50px] rounded text-primary border" onClick={backHandle}>
                              Буцах
                    </button>              
                </div>
              }
          </>
      )    
}