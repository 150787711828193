import React, { useState ,useEffect} from 'react'
import HeaderIntro from "../../../layout/HeaderIntro/Header";
import {BsCheckCircle} from "react-icons/bs";
import slidepic from '../../../assets/images/slide02.jpg';
import imageP from '../../../assets/images/imageP.png';
import Footer from '../../../layout/Footer/Footer';
import { Link ,useParams} from 'react-router-dom';
import SideBar from '../../../components/Annoucement/Sidebar';
import imgzar2 from '../../../assets/images/zar2109.jpeg';
import imgzar1 from '../../../assets/images/zar2077.jpeg';

const blogData = [
        {
            id:1,
            image:imgzar2,
            title:'Багийн төлөөлөлтэй уулзалт зохион байгууллаа.',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'Advisory',
            date:'2023 оны 9 сарын 22',
            name:"Админ",
            client: imageP
        },
        {
            id:2,
            image:imgzar1,
            title:'203 баг бүртгүүлжээ...',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'Travelling',
            date:'2023 оны 9 сарын 13',
            name:"Админ",
            client: imageP
        },
        {
            id:3,
            image:imgzar1,
            title:'СУРГАЛТЫН МОДУЛЬ БОЛОВСРУУЛАХ багийг урьж байна...',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'Advisory',
            date:'2023 оны 9 сарын 13',
            name:"Админ",
            client: imageP
        }
    ]      
export default function Details(){
         
          const params = useParams();
    const id = params.id
    const data = blogData.find((blog)=>blog.id === parseInt(id))
       return (
          <>
           <HeaderIntro/>
           <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="p-6 rounded-md shadow dark:shadow-gray-800">

                                <img src={data.image ? data.image : imgzar2} className="rounded-md" alt="" />

                                <h2 className='mt-6 font-bold text-2xl'>
                                     {data.title}
                                </h2>

                                <div className="mt-6">
                                    <p className="text-slate-400">Модуль сургалтын бүртгэл 2023.09.13-20 өдрийн хооронд үргэлжилж нийт 653 баг бүртгүүллээ. Уулзалтад онлайнаар болон танхимд нийт 566 баг оролцоо. Уулзалтаар дараах мэдээлэл, чиглүүлэг хийлээ.</p>
                                    <p className="text-slate-400 italic border-x-4 border-blue-800 rounded-ss-xl rounded-ee-xl mt-3 p-3"> <li>Багшийн мэргэжлийн хөгжил</li>
                                    <li>Багшийн мэргэжлийн хөгжлийг дэмжих платформд суурилсан модуль сургалт”-ын зохион байгуулалт</li>
                                    <li>“Багшийн мэргэжлийн хөгжлийг дэмжих платформд суурилсан модуль сургалт”-д тавих шаардлага</li>
                                    <li>Цахим платформд суурилсан сургалтын агуулга, арга зүй</li></p>
                                    <p className="text-slate-400 mt-3">Багууд 2023.09.22-ны өдрөөс эхлэн 30 хоногийн хугацаанд сонгосон сэдвээр модуль сургалтын агуулгаа боловруулахаар боллоо.</p>
                                </div>
                            </div>

                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 mt-8">
                                <h5 className="text-lg font-semibold">Сэтгэгдлүүд:</h5>

                                {/* <div className="mt-8">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <img src={imageP} className="h-11 w-11 rounded-full shadow" alt="" />

                                            <div className="ms-3 flex-1">
                                                <Link  className="text-lg font-semibold hover:text-blue-800 transition-all duration-500 ease-in-out">Calvin Carlo</Link>
                                                <p className="text-sm text-slate-400">6th May 2022 at 01:25 pm</p>
                                            </div>
                                        </div>

                                        <Link  className="text-slate-400 hover:text-blue-800 transition-all duration-500 ease-in-out ms-5"><i className="mdi mdi-reply"></i> Reply</Link>
                                    </div>
                                    <div className="p-4 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-6">
                                        <p className="text-slate-400 italic">" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                                    </div>
                                </div>

                                <div className="mt-8">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <img src={imageP} className="h-11 w-11 rounded-full shadow" alt="" />

                                            <div className="ms-3 flex-1">
                                                <Link  className="text-lg font-semibold hover:text-blue-800 transition-all duration-500 ease-in-out">Calvin Carlo</Link>
                                                <p className="text-sm text-slate-400">6th May 2022 at 01:25 pm</p>
                                            </div>
                                        </div>

                                        <Link  className="text-slate-400 hover:text-blue-800 transition-all duration-500 ease-in-out ms-5"><i className="mdi mdi-reply"></i> Reply</Link>
                                    </div>
                                    <div className="p-4 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-6">
                                        <p className="text-slate-400 italic">" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                                    </div>
                                </div> */}
                            </div>

                            {/* <BlogComment /> */}
                        </div>

                        <div className="lg:col-span-4 md:col-span-6">
                            <SideBar client={data.client} name={data.name}/>
                        </div>
                    </div>
                </div>

                {/* <div className="container relative md:mt-24 mt-16">
                    <div className="md:flex justify-center">
                        <div className="lg:w-2/3 text-center">
                            <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold mb-6">Subscribe our weekly subscription</h3>

                            <p className="text-slate-400 max-w-xl mx-auto">Add some text to explain benefits of subscripton on your services. We'll send you the best of our blog just once a weekly.</p>

                            <div className="mt-8">
                                <div className="text-center subcribe-form">
                                    <form className="relative mx-auto max-w-xl">
                                        <input type="email" id="subemail" name="name" className="pt-4 pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white/70 dark:bg-slate-900/70 border border-gray-100 dark:border-gray-700" placeholder="Enter your email id.." />
                                        <button type="submit" className="py-2 px-5 inline-block font-semibold tracking-wide align-middle transition duration-500 ease-in-out text-base text-center absolute top-[2px] end-[3px] h-[46px] bg-indigo-600 hover:bg-indigo-700 border border-blue-800 hover:border-indigo-700 text-white rounded-full">Subcribe Now</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
           <Footer navtype={2}/>
         </>
      )
}