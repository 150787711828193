import React from 'react'
import { returnPaginationRange } from '../../utils/appUtils'

const Pagination = ({callBackFunc,totalPage,currentPage}) => {
          let array  = returnPaginationRange(totalPage,currentPage,1);
  return (
       <div>
          <nav aria-label="Page navigation">
                <ul className="cursor-pointer flex justify-start text-primary text-[18px] font-bold ">
                   <li className="px-3 py-1 rounded-l-lg border border-[#e9ecef] hover:bg-[#e9ecef]" onClick={()=>callBackFunc('prev')}> Өмнөх</li>
                    { 
                         array.map((item,index)=>(
                                         <li className={`${currentPage===item?"px-3 py-1 bg-primary text-white":"px-3 py-1  border border-[#e9ecef] hover:bg-[#e9ecef]"}`} key={index} onClick={()=>callBackFunc(item)}>
                                              <div className="page-link">{item}</div>
                                         </li>
                               ))
                    }
                    <li className="px-3 py-1 rounded-r-lg border border-[#e9ecef] hover:bg-[#e9ecef]" onClick={()=>callBackFunc('next')}>Дараагийн</li>
                </ul>
          </nav>
       </div>
  )
}

export default Pagination