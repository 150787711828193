export const data=
    {
        "id": "215",
        "insertby": "2039",
        "insertfname": null,
        "insertname": "мөнхсарнай",
        "inserttime": "2022-01-25T06:58:22.048Z",
        "ovogid": "467",
        "news_txt": "{\"title\":\"дүрс ашиглан бүтээл хийх\",\"text\":\"дүрс ашиглан бүтээл хийх хүмүүс холбогдоорой\"}",
        "iswhat": 0,
        "mediaid": "1172",
        "mediaurl": "https://lmsbak.bagsh.edu.mn/api/ttimeLine/postFiles/1637676304308.jpg",
        "albumid": null,
        "typeid": 1,
        "scopeid": 2,
        "mileid": null,
        "reqid": null,
        "is_encoded": 0,
        "keyver": 1,
        "likecnt": "0",
        "dislikecnt": "0",
        "viewcnt": "0",
        "is_shared": 0,
        "src_tid": null,
        "shared_cnt": 0,
        "has_youtube": 0,
        "has_soundcloud": 0,
        "has_slideshare": 0,
        "has_instagram": 0,
        "has_linkprev": 0,
        "is_closed": 0,
        "orgid": null,
        "catid": null,
        "forumid": null,
        "updatetime": null,
        "userid": "2039",
        "latitude": null,
        "longitude": null,
        "unifaceid": null,
        "commentcnt": "0",
        "media_cnt": 0,
        "poll_cnt": 0,
        "tag_cnt": 0,
        "poll_id": null,
        "lanid": 0,
        "lessonid": null,
        "t_medium": {  //—----------------------- энэ нь мэдээ зурагтай болхыг харуулна -------------------------------- 
            "id": "1172",
            "insertby": "2039",
            "insertfname": null,
            "insertname": "мөнхсарнай",
            "inserttime": "2022-01-25T06:58:22.030Z",
            "albumid": null,
            "medianame": "index.jpg",
            "mediaurl": "https://lmsbak.bagsh.edu.mn/api/ttimeLine/postFiles/1643093902027.jpg",
            "mediadescr": null,
            "isvideo": 0,
            "where_zipcode": null,
            "scopeid": 2,
            "sizekb": 9,
            "ovogid": "467",
            "hqdone": 0,
            "is_encoded": 0,
            "keyver": 1,
            "likecnt": "0",
            "dislikecnt": "0",
            "width": 0,
            "height": 0,
            "is_panoramic": 0,
            "latitude": null,
            "longitude": null,
            "viewcnt": "0",
            "cammake": null,
            "cammodel": null,
            "gpsdir": null,
            "gpsalt": null,
            "is_for_page": 0,
            "comment_cnt": 0,
            "newsid": null,
            "typeid": 0,
            "is_ready": 2,
            "module_id": null,
            "typeid2": "0"
        },
        "t_album": null
    }
    
