import React ,{useState}from "react";
import InstructionCard from "../../../components/InstructionCard/InstructionCard";
import Login from "../../Home/Login";
import moment from "moment";
export default function Instructions({courseList}) {
  return (
    <>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center gap-y-6">
      {courseList && courseList.length > 0 ?courseList.map((data,index)=>
        (
          <InstructionCard course={data} key={index}/>
        )
      ): <div> Үр дүн олдсонгүй</div>
     }
    </div>
    </>
  );
}
