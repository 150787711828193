
import "./style.scss";
import { BiNotepad } from "react-icons/bi";
import { FaRegHandshake } from "react-icons/fa";
import { AiOutlineSound, AiOutlineVideoCameraAdd } from "react-icons/ai";
import React, { useState ,useEffect} from 'react'
import { Link } from 'react-router-dom';
import api from "../../services/api";
import Chart from "../../components/Intro/Chart";
import bg_course from "../../assets/images/course_image.png";
import home from '../../assets/images/profile.png';
import shape_image from '../../assets/images/profile.png';
import classic03 from '../../assets/images/profile.png';
import classic02 from '../../assets/images/profile.png';
import image from '../../assets/images/profile.png';
import image1 from '../../assets/images/profile.png';
import image2 from '../../assets/images/profile.png';
import image3 from '../../assets/images/profile.png';
import image4 from '../../assets/images/profile.png';
import image5 from '../../assets/images/profile.png';

import course1 from '../../assets/images/c3.jpg';
import course2 from '../../assets/images/c2.jpg';
import course3 from '../../assets/images/c3a.jpg';
import course4 from '../../assets/images/c4.jpg';

// import course4 from '../../assets/images/course/c4.jpg';
import course5 from '../../assets/images/course/c5.jpg';
import course6 from '../../assets/images/course/c6.jpg';
import course7 from '../../assets/images/course/c7.jpg';
import course8 from '../../assets/images/course/c8.jpg';
import course9 from '../../assets/images/course/c9.jpg';
import course10 from '../../assets/images/course/c10.jpg';
import client1 from '../../assets/images/userm.png';
import client2 from '../../assets/images/userf.png';
import client3 from '../../assets/images/client/03.jpg';
import client4 from '../../assets/images/client/04.jpg';
import client5 from '../../assets/images/client/05.jpg';
import client6 from '../../assets/images/client/06.jpg';
import client7 from '../../assets/images/client/07.jpg';
import client8 from '../../assets/images/client/08.jpg';
import appstore from '../../assets/images/appstore.png';
import mapsMongolia from '../../assets/images/mn-02.svg';

import onlineteaching from '../../assets/images/online_teaching.png';
import * as Icon from 'react-feather';
import {MdKeyboardArrowRight,MdAirplay, MdApi} from "react-icons/md";
import {FaArrowRight,FaRegComments,FaQuoteLeft,FaVideo} from "react-icons/fa";
import {BsCheckCircle} from "react-icons/bs";
import {LiaSwatchbookSolid} from "react-icons/lia";
import {GoClock} from "react-icons/go";
import {PiBookOpenText} from "react-icons/pi";
import {AiOutlineEye,AiFillStar,AiOutlineClose} from "react-icons/ai";
import {BiTachometer,BiLogoApple,BiLogoPlayStore} from "react-icons/bi";
import {LuUserCheck} from "react-icons/lu";
 import {HiOutlineCog,HiOutlinePlay} from "react-icons/hi";
 import {RxHamburgerMenu} from "react-icons/rx";
import TinySlider from 'tiny-slider-react';
import video from "../../assets/video/background-video.mp4";
import svgLogo1 from "../../assets/images/surgalt-logo-1.svg";
// import logo from "../../assets/images/bolowsroliin-erunhii-gazar.svg";
import logo from "../../assets/images/mn-logo.svg";
import CountUp from 'react-countup';
import Footer from '../../layout/Footer/Footer'
import Map from "../../components/Map";
import Report1 from "../../components/Intro/report/Report1";
// import DynamicSVGMap from "../../components/svgmap";
const settings = {
    container: '.tiny-three-item',
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
        992: {
            items: 3
        },

        767: {
            items: 2
        },

        320: {
            items: 1
        },
    },
}

export default function Intro() {
    
    const [activeIndex, setActiveIndex] = useState(1);
    const [activeNavbar, setActiveNavbar] = useState(false);
    const [classes, setclasses] = useState();
    const [isSideBar, setIsSideBar] = useState(false);
    const [isOpenTab, setisOpen] = useState(0);
    const [statData, setStatData] = useState({
                                                "organization": 132,
                                                "total_teachers": 52989,
                                                "lesson_cnt": 100
                                        });
   const [mainCertificatesData, setMainCertificatesData] = useState([]);                                    
   const [statChart, setStatChart] = useState({});                                    
    useEffect(() => {
        window.addEventListener("scroll", windowScroll, {
            passive: true
        });
        return () => {
            window.removeEventListener("scroll", windowScroll);
        };
    }, [classes]);
   useEffect(() => {
    let requestData = {ifunc:'homepage_statistic_2', ip:"",  params:{"id":1}}
    api.post("home/certificates",requestData).then((res) => {
    //   console.log('homepage_statistic',res.data);
        if(res.data.issuccess == 1 ){
          setStatData(res.data.messages);
        }
    });

    let reqData2 = {ifunc:'homepage_chart', ip:"",  params:{"id":1}}
    api.post("home/certificates",reqData2).then((res) => {
    //   console.log('homepage_chart',res.data);
        if(res.data.issuccess == 1 ){
            setStatChart(res.data.messages);
        }
    });
    api.post("home/certificates",{
        ifunc:'mainCertificates',
        ip:"",
        params:{
            "id":1
        }
    }).then((res) => {
        if(res.data.messages&&res.data.messages.length>0){
          setMainCertificatesData(res.data.messages);
        }
    });
   
   }, [])
   
    const handleTabClick = (index) => {
        setisOpen(index);
    };
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    function windowScroll() {
        // console.log('document.documentElement.scrollTop',document.documentElement.scrollTop);
        const navbar  = document.getElementById('navbar');
        if(document.documentElement.scrollTop > 50){
            setActiveNavbar(true)
        }else{
            setActiveNavbar(false)
        }
        if(document.documentElement.scrollTop > (window.innerHeight*70)/100){
            setclasses(true)
        }else{
            setclasses(false)
        }
        // if(document.documentElement.scrollTop > window.innerHeight - 100){
        //     if(navbar !== null){
        //         navbar.classList.add('bg-primary')
        //     }
        // }else{
        //     if(navbar !== null){
        //     navbar.classList.remove('bg-primary')
        //     }
        // }
    }
    
    const submitIntro = ()=>{
        const element = document.getElementById('section-intro');
        if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
        }
        setActiveNavbar(true)
    }
    const dataMap =
    [
      { country: "cn", value: 1389618778 }, // china
      { country: "in", value: 1311559204 }, // india
    ];
  
    const keyfeatureData = [
        {
            icon :Icon.Cloud,
            title:'Онлайн сургалт'
        },
        {
            icon :Icon.DownloadCloud,
            title:'Оффлайн сургалт'
        },
        {
            icon :Icon.Loader,
            title:'Холимог сургалт'
        },
        {
            icon :Icon.Monitor,
            title:'LMS платформ'
        },
        {
            icon :Icon.Camera,
            title:'Meeting платформ'
        },
        {
            icon :Icon.Users,
            title:'Сошиал платформ'
        },
        {
            icon :Icon.Globe,
            title:'Үүлэн платформ'
        },
        {
            icon :Icon.Box,
            title:'Микросервис архитектур'
        },
        {
            icon :Icon.Settings,
            title:'Kubernetes'
        },
        {
            icon :Icon.ArrowRight,
            title:'SCORM контент'
        },
        {
            icon :Icon.Play,
            title:'H5P контент'
        },
        {
            icon :Icon.PlayCircle,
            title:'LTI контент'
        },
        {
            icon :Icon.Tool,
            title:'Слайд бүтээгч багаж'
        },
        {
            icon :Icon.BookOpen,
            title:'Цахим хичээл бүтээгч'
        },
        {
            icon :Icon.Smartphone,
            title:'Мобайл апп'
        }
    ]
    // Багш нарын сэтгэгдлээс
    const services = [
        {
            description: '" Маш үнэ цэнэтэй сургалтуудыг нэг дороос аваад дээрээс нь хэрэгтэй подкаст, блог ч уншчих нь зүгээр л Premium хэрэглэгч"',
            image: client2,
            name: 'Т.Туяа',
            role: 'Багш'
        },
        {
            description: `" Энэ их үнэ цэнтэй сургалтуудыг нэг төлөөд бүгдийг нь суралцах эрхтэй болсондоо маш их баяртай байна.хэрэгтэй подкаст, блог ч уншчих нь зүгээр л Premium хэрэглэгч "`,
            image: client1,
            name: 'Н.Төгөлдөр',
            role: 'Багш'
        },
        {
            description: '"  Өмнө нь олон эх үүсвэрээс суралцдаг байсан бол одоо мэргэжлийн багш нараас нэг дороос суралцах боломжтой болсон "',
            image: client1,
            name: 'Б.Бат-эрдэнэ',
            role: 'Багш'
        }
    ]
    const accordionData = [ {
        id:1,
        title:
          "Багш платформд хэрхэн нэвтрэх вэ ?",
        text: "Багш нар esis.edu.mn платформд нэвтэрдэг багшийн код ба нууц үгээ ашиглан “Багш платформ”-д нэвтрэнэ",
      },
      {
        id:2,
        title: "Багш платформын хичээлүүд төлбөртэй юу?",
        text: "Хичээлүүд төлбөртэй бөгөөд Багш картаар тооцоо хийн худалдан авах боломжтой",
      },
      {
        id:3,
        title: "Сургалтанд хамрагдаад сертификат авах уу?",
        text: "Багш нар хичээлийг амжилттай үзэж судлан, шаардлагатай түвшинг давсан тохиолдолд Батламж авах эрхтэй болно",
      },
      {
        id:4,
        title: "Багш платформоор үзэж судалсан хичээлүүд ESIS-д бүртгэгдэх үү?",
        text: "Багш нар хичээлийг амжилттай үзэж судлан батламж авсан бол Боловсролын салбарын мэдээллийн систем буюу ESIS-д автомат бүртгэгдэнэ",
      },
      {
        id:5,
        title: "Сургалтын байгууллагууд хамтарч ажиллах боломжтой юу?",
        text: "Сургалтын байгууллагууд Багш платформд хичээлээ байршуулан хамтран ажиллах боломжтой",
      },
      {
        id:6,
        title: "Бусад LMS платформ дээр боловсруулсан контентоо ашиглаж болох уу?",
        text: "Багш платформ нь SCORM болон LTI стандартаар бусад LMS платформоос контент хүлээн авах боломжтой",
      },
    ]
    const courseListing = [
        {
            id:1,
            image: course4,
            image2: client2,
            name: 'Т.Туяа',
            role: 'Багш',
            field: '10 жил',
            desc: 'Шинжлэх ухаанч арга барил эзэмшүүлэх'
        },
        {
            id:2,
            image: course3,
            image2: client1,
            name: 'Н.Төгөлдөр',
            role: 'Багш',
            field: '1 жил',
            desc: 'Бага боловсролын 5 дахь жилийн багш'
        },
        {
            id:3,
            image: course2,
            image2: client1,
            name: 'Б.Бат-эрдэнэ',
            role: 'Багш',
            field: '5 жил',
            desc: 'Бага боловсролын 10 дахь жилийн багш'
        }
    ] 
    const videoData = [
            {
                title: 'Суралцагч нэвтрэх',
                description: "Суралцагч хэрхэн платформд нэвтрэх, хичээлийн материалуудыг үзэх, хичээлийн агуулга хооронд шилжих зэрэг энгийн үйлдлүүдийг танилцуулав",
                video: "https://www.youtube.com/embed/u-_G7fl8TyA"
            },
            {
                title: 'Суралцагч даалгавар илгээх',
                description: "Багш платформд суралцаж буй суралцагч даалгавар хэрхэн илгээх, илгээсэн даалгавар нь багшид хэрхэн харагдахыг танилцуулсан хичээл",
                video: "https://www.youtube.com/embed/ZBmR9g-rzyo"
            },
            {
                title: 'Багш платформоос хичээл хайх, суралцах',
                description: "Багш платформоос хэрхэн хичээл хайх, төлбөр төлж хичээлээ авах, хэрэв дансны үлдэгдэл хүрэлцэхгүй бол дансаа хэрхэн цэнэглэх тухай үзүүлнэ",
                video: "https://www.youtube.com/embed/U6sY5gfeaco"
            },
            {
                title: 'Багш платформын сургалтанд бүртгүүлэх',
                description: "Идэвхтэй явагдаж буй болон бүртгэл нь эхэлсэн сургалтуудад хэрхэн хамрагдах тухай үзүүлэх болно",
                video: "https://www.youtube.com/embed/0HOvWH8oxik"
            }
        ]
    const linkStyle = "text-left h-[40px] no-underline text-slate-500  text-[16px] font-bold hover:rounded-md hover:bg-slate-100 hover:transition-all hover:duration-300 px-3 py-1 cursor-pointer"
    const headerStyle = 'rounded-lg w-[265px] h-auto sm:h-[150px] py-2 sm:py-6 px-4 m-0 sm:m-6 text-xl sm:text-3xl text-white no-underline hover:text-[#ffd11a] hover:bg-[#16166e99] hover:cursor-pointer hover:shadow hover:backdrop-blur-sm'
    const headerStyleNav = 'flex items-center justify-center space-x-1 text-2xl cursor-pointer px-3 py-1 rounded-lg hover:bg-[#16166e99] hover:text-[#ffd11a]'
    const textStyle = 'text-sm sm:text-xl'
    const textStyleNav = 'text-sm'
    const listStyle = "absolute block animate-animatetailwind list-none bottom-[-150px] bg-[#ffffff33]"
    const style ={height: `20px ${'!important'}`  }
  return (
    <>
      <div className="w-full relative h-screen">
        <video className="absolute inset-0 !min-h-full  hidden xl:block object-cover h-screen" autoPlay muted loop id="myVideo">
          <source src={video} type="video/mp4" />
        </video>
        <div className="absolute inset-0 bg-[#16166ee6] w-full z-2">
          <ul className="absolute top-0 left-0 w-full h-full overflow-hidden nthchild">
            <li className={ listStyle + " w-[80px] h-[80px]  left-[25%]" }></li>
            <li className={listStyle+" w-[20px] h-[20px]  left-[10%]" } ></li>
            <li className={listStyle+" w-[20px] h-[20px]  left-[70%]" } ></li>
            <li className={listStyle+" w-[60px] h-[60px]  left-[40%]" }></li>
            <li className={listStyle+" w-[20px] h-[20px]  left-[65%]" }></li>
            <li className={listStyle+" w-[110px] h-[110px]  left-[70%]" }></li>
            <li className={listStyle+" w-[150px] h-[150px]  left-[35%]" }></li>
            <li className={listStyle+" w-[25px] h-[25px]  left-[50%]" }></li>
            <li className={listStyle+" w-[15px] h-[15px]  left-[20%]" }></li>
            <li className={listStyle+" w-[150px] h-[150px]  left-[85%]" }></li>
          </ul>
        </div>
        <div id="navbar" className={`${classes?"fixed left-0 top-0 h-[100px] w-full z-[100] bg-primary2 flex items-center justify-center":""}`}>
            <div className={`${classes?"flex items-center justify-between  w-full text-white container":"absolute top-[10%] md:top-[40%]  right-0 left-0 text-[#ffd11a] z-3"}`}>
                <div className={`${classes?"flex justify-center items-center space-x-3 ":"flex justify-center mb-4"}`}>
                    <img src={logo} alt="logo" className={`${classes?"w-[60px]":"h-[40px] sm:h-auto w-[80px]"}`}/>
                    <h3 className={`${classes?"text-[20px]  text-white":"align-text-top sm:mt-[30px] sm:ml-[10px] uppercase"}`}>Багшийн хөгжил</h3>
                </div>
                <div className={`${classes?"hidden items-center justify-center space-x-3 lg:flex":"grid grid-cols-2 md:grid-cols-4 md:w-10/12 lg:w-9/12 mx-auto"}`}>
                    <Link to="#" onClick={submitIntro} className={activeNavbar?classes?headerStyleNav+' !text-[#ffd11a]':headerStyle+' !text-[#ffd11a]':classes?headerStyleNav:headerStyle}>
                        <BiNotepad className="my-2" />
                        <h5 className={`${classes?textStyleNav:textStyle}`}>Танилцуулга</h5>
                    </Link>
                    <Link to="/main/home" className={classes?headerStyleNav:headerStyle}>
                        <AiOutlineVideoCameraAdd className="my-2" />
                        <h5 className={`${classes?textStyleNav:textStyle}`}>Сургалтууд</h5>
                    </Link>
                    <Link to="/cooperation" className={classes?headerStyleNav:headerStyle}>
                    <FaRegHandshake className="my-2" />
                    <h5 className={`${classes?textStyleNav:textStyle}`}>Хамтын ажиллагаа</h5>
                    </Link>
                    <Link to="/annoucement" className={classes?headerStyleNav:headerStyle}>
                    <AiOutlineSound className="my-2" />
                    <h5 className={`${classes?textStyleNav:textStyle}`}>
                        Зар мэдээ 
                    </h5>
                    </Link>
                </div>
                <div className={`${classes?"block lg:hidden":"hidden"}`}>
                        <RxHamburgerMenu className="cursor-pointer text-white hover:text-slate-300 text-xl font-bold" onClick={()=>setIsSideBar(true)}/>
                        {isSideBar&&<div className="fixed bg-[#0000009b] inset-0" onClick={()=>setIsSideBar(false)}>  </div>}
                        <div className={`fixed right-0 z-[101]  top-0 bottom-0 w-[280px] bg-white transition-all ease-in-out duration-400 flex flex-col p-6 overflow-y-auto px-[40px] py-[16px] text-left ${isSideBar?'mr-0':'-mr-[280px]'} `}>
                            <div className="flex justify-end mr-[-16px] cursor-pointer" onClick={()=>setIsSideBar(false)}>
                                <AiOutlineClose size={24} className="text-slate-400" />
                            </div>
                            <div className="text-[16px] text-[#15387c] font-bold pb-[16px]">
                                <div className="flex">
                                    <img width={60} height={60} src={logo} alt="logo"/>
                                    <div className="ml-[12px]"></div>
                                    <span className="text-primary flex flex-col justify-end">
                                        Боловсролын ерөнхий газар
                                    </span>
                                </div>
                                <div className="dropdown-divider"></div>
                            </div>
                            <div className="flex flex-col justify-center text-dark">
                                <Link className={linkStyle+" rounded-md bg-slate-100"} onClick={()=>{setIsSideBar(false);submitIntro();}} to="/">
                                Танилцуулга
                                </Link>
                                <Link to="/main/home" className={linkStyle} onClick={()=>{setIsSideBar(false)}}>
                                Сургалтууд
                                </Link>
                                <Link to="/cooperation" className={linkStyle} onClick={()=>setIsSideBar(false)}>
                                Хамтын ажиллагаа
                                </Link>
                                <Link to="/annoucement" className={linkStyle} onClick={()=>setIsSideBar(false)}>
                                Зар мэдээ 
                                </Link>
                            </div>
                        </div>
                </div>
            </div>
        </div>
      </div>
      <div className='mx-auto relative'>
            <section id="section-intro" className="relative md:py-24 py-16 overflow-hidden">
                <div className="container relative">
                     <div  className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                        <div className="lg:col-span-5">
                        <img src={onlineteaching} alt="" />
                        </div>
                        <div className="lg:col-span-7">
                        <div className="lg:ms-10">
                            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">БАГШ платформ гэж юу вэ?</h3>
                            <p className="text-slate-400 max-w-xl">"Багш платформ" нь багшийн мэдлэг, арга зүй, ур чадвар, боловсролоо дээшлүүлэх тасралтгүй үйл ажиллагааг дэмжих зорилготой сургалтын платформ юм. </p>

                            <ul className="list-none text-slate-400 mt-4 ">
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-primary2 text-base me-2 w-[25px]" /> Багшийн мэргэжлийг дээшлүүлэх</li>
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-primary2 text-base me-2 w-[25px]" /> Багшийг давтан бэлтгэх</li>
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-primary2 text-base me-2 w-[25px]" /> Ажлын байран дээрээ тасралтгүй хөгжих үйл явцыг дэмжих</li>
                            <li className="mb-1 flex items-center"><BsCheckCircle className="text-primary2 text-base me-2 w-[25px]" /> Үндэсний, орон нутгийн, байгууллагын, сонирхлын бүлгийн түвшинд дэмжих </li>
                            </ul>

                            {/* <div className="mt-4">
                            <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-primary2 hover:text-indigo-900 after:bg-primary2 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                            </div> */}
                        </div>
                        </div>
                    </div>
                </div>
            </section>
              {/* <section id="section-intro" className="relative md:py-24 py-16 overflow-hidden">
                    <div className="container relative">
                        <div className="grid grid-cols-1 pb-8 text-center">
                            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">БАГШ платформ гэж юу вэ?</h3>
                            <p className="text-slate-400 max-w-xl mx-auto">Багш нь Боловсрол, шинжлэх ухааны сайдын 2021 оны “Цахим сурах бичиг, ерөнхий боловсролын сургалтад ашиглах сургалтын нээлттэй материалд тавигдах шаардлага батлах тухай” А/193 тушаал , “Ээлжит хичээлийн цахим контент сонгон шалгаруулах үнэлгээний аргачлал” болон арга зүйн зөвлөмжийн дагуу ээлжит хичээлийн контентыг бэлтгэнэ.</p>
                        </div>
                    </div>
              </section> */}
              <section className="relative bg-primary2 py-12">
                    <div className="container relative">
                        <div className="relative grid md:grid-cols-3 grid-cols-1 items-center gap-[30px]">
                            <div className="counter-box text-center">
                                <h1 className="text-4xl font-bold mb-4 text-white"><CountUp className="counter-value" start={0} end={statData.organization} />+</h1>
                                <h5 className="counter-head text-xs font-semibold text-white">Сургалтын байгууллага </h5>
                            </div>

                            <div className="counter-box text-center">
                                <h1 className="text-4xl font-bold mb-4 text-white"><CountUp className="counter-value" start={0} end={statData.total_teachers} />+</h1>
                                <h5 className="counter-head text-xs font-semibold text-white">Багш</h5>
                            </div>

                            <div className="counter-box text-center">
                                <h1 className="text-4xl font-bold mb-4 text-white"><CountUp className="counter-value" start={2} end={statData.lesson_cnt} />+</h1>
                                <h5 className="counter-head text-xs font-semibold text-white">Хичээл</h5>
                            </div>

                            {/* <div className="counter-box text-center">
                                <h1 className="text-4xl font-bold mb-4 text-white"><CountUp className="counter-value" start={0} end={29} />M</h1>
                                <h5 className="counter-head text-xs font-semibold text-white">MEALS DISTRIBUTED </h5>
                            </div> */}
                        </div>
                    </div>
              </section>
              <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Багш платформын боломжууд</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Багш платформ нь Moodle, edX, Canvas гэх мэт дэлхийд алдартай LMS платформуудтай ижил дүйхүйц, Монгол залуусын хөгжүүлсэн, үндэсний, сургалтын менежментийн цогц систем юм</p>
                    </div>

                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] mt-6">
                        {keyfeatureData.map((item, index) => {
                            let Icons = item.icon
                             return(
                            <div className="lg:col-span-4 md:col-span-6" key={index}>
                                <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                                    <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-blue-900/10 text-blue-900 text-center rounded-lg me-3">
                                        <Icons className="h-5 w-5 rotate-45"/>
                                    </div>
                                    <div className="flex-1">
                                        <h4 className="mb-0 text-lg font-medium">{item.title}</h4>
                                    </div>
                                </div>
                            </div>
                             )
                        })}
                    </div>
                </div>
            </section>
            <section className="container relative ">
                 <div className="grid grid-cols-1 text-center">
                        <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Платформын хэрэглэгчид</h3>
                    </div>
                <div className="flex justify-center w-full h-full">
                        <Map map_data={statChart}/>
                </div>
            </section>
            <section className="container relative mt-4 md:mt-0">
                  <div className="flex items-center justify-between pt-3 pb-4">
                       <h3 className="text-xl md:leading-normal leading-normal font-semibold">Тоон үзүүлэлтүүд</h3>
                        <Link to={'/report'} className="text-lg flex items-center justify-end space-x-2  text-primary hover:bg-gray-100 rounded-lg p-1 px-3 ">
                               <p className="font-bold">Бүх тайлан үзэх </p>
                               <Icon.ArrowRight className="text-sm text-primary w-5 h-5"/>
                        </Link>
                  </div>
                  <div className="w-full rounded-lg border p-4">

                    <Chart chart_data={statChart}/>
                  </div>
            </section>
            <section className="relative lg:py-20 py-16 overflow-hidden  mx-auto">
                     <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Сургалтууд</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Багшийн хөгжлийг дэмжих батлагдсан модуль сургалтууд</p>
                    </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center gap-x-1 gap-y-6  overflow-hidden py-2 container">
                                {mainCertificatesData && mainCertificatesData.length > 0 && mainCertificatesData.slice(0,3).map((data, index) => {
                                    return (
                                    <div className="w-[325px] group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden" key={index}>
                                        <div className="relative overflow-hidden">
                                            <img src={bg_course} className="group-hover:scale-110 duration-500 ease-in-out h-[120px] w-full object-cover" alt="" />
                                            <div className="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>

                                            <div className="absolute start-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                                                <div className="pb-4 ps-4 flex items-center">
                                                    <img src={client1} className="h-12 w-12 rounded-full shadow-md dark:shadow-gray-800 mx-auto bg-white" alt="" />
                                                    <div className="ms-3">
                                                        <Link className="font-semibold text-white block">
                                                            {/* {data.cert_name} */}
                                                        </Link>
                                                        <span className="text-white/70 text-sm">Багш</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="content p-4 relative">
                                            {/* <Link to={`/course-detail/${data.id}`} className="font-medium block text-primary2">{data.field}</Link> */}
                                            <Link to={`/main/certificates/detail/`+data.id} className="text-md font-medium hover:text-blue-900 duration-500 ease-in-out mt-2  line-clamp-2">{data.cert_name}</Link>
                                            <p className="text-slate-400 mt-2 mb-4 line-clamp-3 text-xs">{data.descr}</p>
                                            <ul className="pt-4 border-t border-gray-100 dark:border-gray-800 flex flex-col items-start justify-start list-none text-slate-400 text-sm space-y-3">
                                                <li className="flex items-center me-4">
                                                    <PiBookOpenText className="text-lg leading-none me-2 text-slate-900 dark:text-white"/>
                                                    <span>{data.planlessonscnt} хичээл</span>
                                                </li>

                                                <li className="flex items-center me-4">
                                                    <GoClock className="text-lg leading-none me-2 text-slate-900 dark:text-white"/>
                                                    <span>{data.days} үргэлжлэх өдөр</span>
                                                </li>

                                                <li className="flex items-center">
                                                    <AiOutlineEye className=" text-lg leading-none me-2 text-slate-900 dark:text-white"/>
                                                    <span>{data.total_std_count} бүртгүүлсэн</span>
                                                </li>
                                            </ul>

                                            <div className="absolute -top-5 end-6 z-1  duration-500 ease-in-out">
                                                <div className="flex justify-center items-center px-3 py-1 bg-white dark:bg-slate-900 rounded-lg shadow-lg dark:shadow-gray-800 text-blue-900 dark:text-white">
                                                    <span className="font-semibold">5 жил </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}
                </div>
              </section>
            <section className="relative">
                <div className="container relative" id="review">
                    <div className="grid grid-cols-1 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Багш нарын сэтгэгдэл</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Багш платформоор амжилттай суралцаж батламж авсан багш нарын сэтгэгдлээс...</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 mt-4">
                                {services.map((item, index) => (
                                    <div className="tiny-slide text-center" key={index}>
                                        <div className="cursor-e-resize">
                                            <div className="content relative shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                                                <FaQuoteLeft  className="text-[34px] text-blue-900 mx-auto pb-2"/>
                                                <p className="text-slate-400">{item.description}</p>
                                                <ul className="flex justify-center list-none mb-0 text-amber-400 mt-3 space-x-1">
                                                    <li className="inline"><AiFillStar/></li>
                                                    <li className="inline"><AiFillStar/></li>
                                                    <li className="inline"><AiFillStar/></li>
                                                    <li className="inline"><AiFillStar/></li>
                                                    <li className="inline"><AiFillStar/></li>
                                                </ul>
                                            </div>

                                            <div className="text-center mt-5">
                                                <img src={item.image} className="h-14 w-14 rounded-full shadow-md mx-auto" alt="" />
                                                <h6 className="mt-2 font-semibold">{item.name}</h6>
                                                <span className="text-slate-400 text-sm">{item.role}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container">
                   <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Видео хичээл</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Багш платформыг анхлан хэрэглэгчдэд зориулсан видео хичээлүүд</p>
                    </div>
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-4 md:col-span-5">
                            <div className="sticky top-20">
                                <ul className="flex flex-col text-center p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                                    <li role="presentation">
                                        <button className={`${ isOpenTab === 0 ?'text-white bg-primary2' : 'hover:text-indigo-900'} px-2 py-2 text-sm font-semibold rounded-md w-full  transition-all duration-500 ease-in-out`} id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true"
                                        onClick={() => handleTabClick(0)}
                                        >Суралцагч нэвтрэх <HiOutlinePlay className={`${ isOpenTab === 0 ?'text-white inline' : 'hover:text-indigo-900 inline'}`}/></button>
                                    </li>
                                    <li role="presentation">
                                        <button onClick={() => handleTabClick(1)} className={`${isOpenTab === 1 ?'text-white bg-primary2' : 'hover:text-indigo-900'} px-2 py-2 text-sm font-semibold rounded-md w-full mt-3  transition-all duration-500 ease-in-out`} id="dashboard-tab" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false">Даалгавар илгээх <HiOutlinePlay className={`${ isOpenTab === 1 ?'text-white inline ' : 'hover:text-indigo-900 inline'}`}/></button>
                                    </li>
                                    <li role="presentation">
                                        <button onClick={() => handleTabClick(2)} className={`${isOpenTab === 2 ?'text-white bg-primary2' : 'hover:text-indigo-900'} px-2 py-2 text-sm font-semibold rounded-md w-full mt-3  transition-all duration-500 ease-in-out`} id="settings-tab" data-tabs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false">Хичээл хайх, суралцах <HiOutlinePlay className={`${ isOpenTab === 2 ?'text-white inline ' : 'hover:text-indigo-900 inline'}`}/></button>
                                    </li>
                                    <li role="presentation">
                                        <button onClick={() => handleTabClick(3)} className={`${isOpenTab === 3 ?'text-white bg-primary2' : 'hover:text-indigo-900'} px-2 py-2 text-sm font-semibold rounded-md w-full mt-3  transition-all duration-500 ease-in-out`} id="contacts-tab" data-tabs-target="#contacts" type="button" role="tab" aria-controls="contacts" aria-selected="false">Сургалтанд бүртгүүлэх <HiOutlinePlay className={`${ isOpenTab === 3 ?'text-white inline ' : 'hover:text-indigo-900 inline'}`}/></button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="lg:col-span-8 md:col-span-7">
                            <div id="myTabContent" className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                {
                                        <div  id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        {/* <img src={item.image} className="shadow rounded-md" alt="" /> */}
                                        <iframe className="shadow rounded-md w-full h-[350px]"   src={videoData[isOpenTab].video}>
                                        </iframe>
                                        <div className="mt-6">
                                            <h5 className="text-lg font-semibold mb-4">{videoData[isOpenTab].title}</h5>
                                            <p className="text-slate-400 mb-2">{ videoData[isOpenTab].description}</p>
                                            {/* <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-primary2 hover:text-indigo-900 after:bg-primary2 duration-500">Дэлгэрэнгүй <FaArrowRight className="ms-2 text-[10px]"/></Link> */}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Түгээмэл асуултууд</h3>
                    </div>

                    <div id="accordion-collapse" data-accordion="collapse" className="grid md:grid-cols-2 grid-cols-1 md:gap-[30px]">
                        <div>
                            {accordionData.slice(0,3).map((item, index) => (
                                <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                    <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                        <button type="button" onClick={() => toggleAccordion(item.id)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === item.id ? 'bg-gray-50 dark:bg-slate-800 text-blue-900' : ''}`} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                            <span>{item.title}</span>
                                            <svg data-accordion-icon className={`${activeIndex === item.id ? "rotate-180" : "rotate-270" } w-4 h-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                            </svg>
                                        </button>
                                    </h2>
                                    {activeIndex === item.id && (
                                        <div>
                                            <div className="p-5">
                                                <p className="text-slate-400 dark:text-gray-400">{item.text}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            ))}
                        </div>

                        <div>
                            {accordionData.slice(3,6).map((item, index) => (
                                <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                    <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                        <button type="button" onClick={() => toggleAccordion(item.id)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === item.id ? 'bg-gray-50 dark:bg-slate-800 text-blue-900' : ''}`} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                            <span>{item.title}</span>
                                            <svg data-accordion-icon className={`${activeIndex === item.id ? "rotate-180" : "rotate-270" } w-4 h-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                            </svg>
                                        </button>
                                    </h2>
                                    {activeIndex === item.id && (
                                        <div>
                                            <div className="p-5">
                                                <p className="text-slate-400 dark:text-gray-400">{item.text}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            ))}
                        </div>

                    </div>
            </section>
            <section className="container relative md:my-24 my-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center">
                        <div className="lg:col-span-5 md:col-span-6 p-8 rounded-md">
                            <img src={appstore} className="mx-auto d-block" alt="" />
                        </div>

                        <div className="lg:col-span-7 md:col-span-6">
                            <span className="bg-indigo-600/5 text-primary2 text-xs font-bold px-2.5 py-0.5 rounded h-5">Мобайл апп</span>
                            <h4 className="md:text-3xl text-2xl lg:leading-normal leading-normal font-medium my-4">АПП ТАТАХ</h4>
                            <p className="text-slate-400 max-w-xl mb-0">Та мобайл апп татаж ашигласнаар хичээлүүдээ гар утсандаа татан авч, интернэт сүлжээгүй оффлайн горимд ч суралцах боломжтой</p>
                            <div className="my-5 w-full flex items-center justify-start space-x-2">
                                <Link  className="flex justify-center items-center space-x-1 bg-primary2 rounded-md h-[44px] w-[145px] hover:bg-indigo-900 text-white shadow-lg transition-all duration-300" to="https://apps.apple.com/us/app/%D0%B1%D0%B0%D0%B3%D1%88-%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC/id1623960946"><BiLogoApple/><span className="text-xs font-semibold">App Store</span></Link>

                                <Link className="flex justify-center items-center space-x-1 border border-primary2 rounded-md h-[43px] w-[145px] hover:bg-indigo-900 text-primary2 hover:text-white shadow-lg transition-all duration-300" to="https://play.google.com/store/apps/details?id=mn.starsoft.itpdapp"><BiLogoPlayStore/><span className="text-xs font-semibold">Google Play</span></Link>
                            </div>
                        </div>
                    </div>
            </section>
      </div>
      <Footer navtype={2}/>
    </>
  );
}
